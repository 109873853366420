import React from 'react'
import Header from '../../components/Header'
import AdminBanner from '../../components/AdminBanner'
import Footer from '../../components/Footer'
import { Col, Container, Row, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import AdminNav from '../../components/AdminNav';
import Loader from '../../components/Loader';
import { useCreateProductMutation, useDeleteProductMutation, useGetProductsQuery } from '../../slices/productsApiSlice';
import { toast } from 'react-toastify';
import Message from '../../components/Message';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";

const ServicesListScreen = () => {

  const { data: services, isLoading, error, refetch } = useGetProductsQuery();

  const [createProduct, { isLoading: loadingCreate }] = useCreateProductMutation();
  const [deleteProduct, { isLoading: loadingDelete }] = useDeleteProductMutation();

  const createServiceHandler = async () => {
   try {
      await createProduct();
      toast.success('Service successfully deleted');
      refetch();
   } catch (error) {
      toast.error(error?.data?.message || error.error);
   };
  }

  const deleteHandler = async (id) => {
    if (window.confirm('Are you sure you want to delete this service?')) {
      try {
        await deleteProduct(id);
        toast.success('Service successfully deleted');
        refetch();
      } catch (error) {
        toast.error(error?.data?.message || error.error);
      };
    }
  };

  return (

    <>

      <Header />
      <AdminBanner />

      <Container fluid>
        <Row className='justify-content-center my-5'>
          <Col lg="10">
            <div className="bg-transparent rounded border-primary p-4">
              <Row>
                <AdminNav />
                <Col lg='9' className='ps-4'>

                  <Row>
                    <Col>
                      <h2 className="text-dark">All Services</h2>
                    </Col>
                    <Col className='text-end'>
                      <Button className='btn btn-primary btn-sm' onClick={ createServiceHandler }>Add New Service</Button>
                    </Col>
                  </Row>

                  <hr />

                  {loadingCreate && <Loader />}
                  {loadingDelete && <Loader />}

                  { isLoading ? ( <Loader /> ) : error ? ( <Message variant='danger'>{ error?.data?.message || error.error }</Message> ) : (

                    <table>

                    <thead className='border-bottom text-dark'>
                        <tr>
                            <th scope='col' className="fs-sm fw-normal col-4">
                                Name
                            </th>
                            <th scope='col' className="fs-sm fw-normal col-2">
                                Category
                            </th>
                            <th scope='col' className="fs-sm fw-normal col-2">
                                Price
                            </th>
                            <th scope='col' className="fs-sm fw-normal col-2">
                                <FaArrowDown />
                            </th>
                            <th scope='col' className="fs-sm fw-normal col-2">
                                <FaArrowUp />
                            </th>
                            <th scope='col' className='fs-sm fw-normal col-1 pe-1'>
                                Edit
                            </th>
                            <th scope='col' className='fs-sm fw-normal col-1 pe-1'>
                            Delete
                            </th>
                        </tr>
                    </thead>

                    <tbody className='align-middle fs-xs'>

                      {services.map((service) => (

                          <tr key={service._id} className='border-bottom highlight'>
                              <td className="py-2 ps-1">
                                  <LinkContainer to={`/admin/service/${service._id}/info`} className='cursor-pointer'>
                                      <span className='hover-underline'>{service.name}</span>
                                  </LinkContainer>
                              </td>
                              <td className='text-capitalize'>
                                  {service.category}
                              </td>
                              <td>
                                  ${service.price}
                              </td>
                              <td>
                                  {service.download}
                              </td>
                              <td>
                                  {service.upload}
                              </td>
                              <td className='text-center text-primary'>
                                  <LinkContainer to={`/admin/service/${service._id}/edit`} className='cursor-pointer'>
                                      <FiEdit />
                                  </LinkContainer>
                              </td>
                              <td>
                                  <Button className='btn btn-sm bg-transparent text-dark text-center ps-3' onClick={() => deleteHandler(service._id)}>
                                      <MdDelete />
                                  </Button>
                              </td>
                          </tr>
                      ))}

                    </tbody>

                    </table>

                  )}

                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>

      <Footer />

    </>
  )
}

export default ServicesListScreen