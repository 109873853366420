import React from 'react'
import Header from '../components/Header.jsx'
import PlanMenu from '../components/PlanMenu.jsx'
import MainBanner from '../components/MainBanner.jsx'
import ServicesMenu from '../components/ServicesMenu.jsx'
import ConnectionSteps from '../components/ConnectionSteps.jsx'
import Footer from '../components/Footer.jsx'
import Meta from '../components/Meta.jsx'
import { Container } from 'react-bootstrap';

const HomeScreen = () => {
  return (
    <>  
        
        <Meta />
        <Header/>
        <Container fluid className='p-0 m-0'>
        
        <MainBanner />
        <ServicesMenu />
        
        <div className="container">
          <hr className='mt-5' />
        </div>

        <ConnectionSteps />
        <div className="container">
          <hr className='mt-3' />
        </div>
        <PlanMenu />
        <Footer />
        </Container>
        
    </>
  )
}

export default HomeScreen