import React from 'react'
import heroImg from '../assets/images/about-us.png'


const AboutBanner = () => {
  return (
    <>
    <section className="p-0 position-relative h-500 h-md-400 h-xl-500 bg-grad pattern-overlay-1 overflow-hidden">
        <div className="container d-flex h-100">
            <div className="row align-self-center w-100">
                <div className="col-sm-12 col-lg-6 mt-md-0 text-white position-relative text-end text-lg-start">
                    <h2 className="display-4 fw-bold">
                    About Us
                    </h2>
                    <p className="h5 mb-4 fw-normal d-sm-block lh-base mt-3">
                    At Excite, we specialise in delivering high-speed, dependable internet services that our customers can count on. Our focus is simple: providing broadband that just works, without the hassle. We offer high-speed and realiable broadband solutions to residential customers, with a focus on expert technical support.
                    </p>
                </div>
                <div className="col-sm-12 col-md-6 d-none d-lg-block p-0 position-absolute top-0 end-0 align-top align-text-top ">
                    <img className='w-100' src={heroImg} alt="" />
                </div>
            </div>
        </div>
        <div className="wave wave1"></div>
        <div className="wave wave2"></div>
        <div className="wave wave3"></div>
        <div className="wave wave4"></div>
    </section> 
    </>
  )
}

export default AboutBanner