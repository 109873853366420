import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Row, Col, Button, Container } from 'react-bootstrap'
import Header from '../components/Header'
import OrderBanner from '../components/OrderBanner.jsx'
import CheckoutSteps from '../components/CheckoutSteps.jsx'
import Footer from '../components/Footer'
import { addServiceAddress } from '../slices/cartSlice.js';
import { useNavigate } from 'react-router-dom'


const ShippingScreen = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const cart = useSelector((state) => state.cart);
    const { serviceAddress } = cart;

    const regionsList = ['Northland', 'Auckland', 'Waikato', 'Bay of Plenty', 'Gisborne', `Hawke's Bay`, 'Taranaki', 'Whanganui-Manawatu', 'Wellington', 'Marlborough', 'Nelson-Tasman', 'West Coast', 'Cantebury', 'Otago', 'Southland' ]

    const [address, setAddress] = useState(serviceAddress?.address || '');
    const [city, setCity] = useState(serviceAddress?.city || '');
    const [region, setRegion] = useState(serviceAddress?.region || '');
    const [postalCode, setPostalCode] = useState(serviceAddress?.postalCode || '');
    const [country, setCountry] = useState(serviceAddress?.country || 'New Zealand');

    const submitHandler = (e) => {
      e.preventDefault();
      dispatch(addServiceAddress({ address, city, region, postalCode, country }));
      navigate('/placeorder');
    }

  return (
    <>

    <Header />
    <OrderBanner/>
    
    <Container> 
      <Row className='justify-content-center my-5'> 

        <Col lg='6'>

        <CheckoutSteps step1 step2 step3 />

        <div className='bg-transparent rounded border-primary p-3'>

          <h3 className="text-dark text-center">Confirm your address</h3>

          <Form onSubmit={submitHandler}>
          <Row className='my-3 text-dark'>
              <Form.Group controlId='address'>
                  <Form.Label className='text-dark mb-0 ms-1'>Street Address:</Form.Label>
                  <Form.Control type="text" value={address} onChange={(e) => setAddress(e.target.value)}></Form.Control>
              </Form.Group>   
              <Form.Group controlId='city'>
                  <Form.Label className='text-dark mt-2 mb-0 ms-1'>Suburb/City:</Form.Label>
                  <Form.Control type="text" value={city} onChange={(e) => setCity(e.target.value)}></Form.Control>
              </Form.Group>
              <Row className='m-0 p-0'>
                  <Col>
                      <Form.Group controlId='postalCode'>
                          <Form.Label className='text-dark mt-2 mb-0 ms-1'>Postcode:</Form.Label>
                          <Form.Control type="text" value={postalCode} onChange={(e) => setPostalCode(e.target.value)}></Form.Control>
                      </Form.Group>
                  </Col>
                  <Col>
                      <Form.Group controlId='region'>
                          <Form.Label className='text-dark mt-2 mb-0 ms-1'>Region:</Form.Label>
                          <Form.Select className='form-control text-dark' as='select' value={region} onChange={(e) => setRegion(e.target.value)}>
                              {regionsList.map( (x, y) => <option key={y}>{x}</option>)}
                          </Form.Select>
                      </Form.Group>
                  </Col>
                  <Form.Group controlId='country'>
                          <Form.Label className='text-dark mt-2 mb-0 ms-1'>Country:</Form.Label>
                          <Form.Control type="text" value={country} onChange={(e) => setCountry(e.target.value)} disabled></Form.Control>
                  </Form.Group>
              </Row>
              
          </Row>

          <Container className='d-flex justify-content-center'>
            <Button type='submit' className='btn btn-grad btn-sm px-3'>
                Next 
            </Button>
          </Container>
          </Form>
          
        </div>

        

        </Col>

      </Row>
      
    </Container>

    <Footer />

    </>
  )
}

export default ShippingScreen