import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Button, Row, Col, ListGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Header from '../components/Header.jsx';
import OrderBanner from '../components/OrderBanner.jsx';
import CheckoutSteps from '../components/CheckoutSteps.jsx';
import Footer from '../components/Footer.jsx';
import Loader from '../components/Loader.jsx';
import { useCreateOrderMutation } from '../slices/ordersApiSlice.js';
import { clearCartItems  } from '../slices/cartSlice.js';
import { useSendNewOrderEmailMutation, useSendOrderConfirmationEmailMutation } from '../slices/mailApiSlice.js';

const PlaceOrderScreen = () => {

        const navigate = useNavigate();
        const dispatch = useDispatch();
        const cart = useSelector((state) => state.cart);
        const userInfo = useSelector((state) => state.auth.userInfo);

        const [createOrder, { isLoading }] = useCreateOrderMutation();
        const [sendOrderConfirmationEmail, { isLoading: confirmingOrderEmail }] = useSendOrderConfirmationEmailMutation();
        const [sendNewOrderEmail, { isLoading: submittingOrder }] = useSendNewOrderEmailMutation();

        useEffect(() => {
            if (!cart.serviceAddress.address) {
                navigate('/details');
            } else {
                return
            }
        }, [cart.serviceAddress.address, navigate]);
        
        const placeOrderHandler = async () => {
            try {
                const res = await createOrder({
                    orderItems: cart.cartItems,
                    orderServices: cart.cartServices,
                    orderHardwares: cart.cartHardwares,
                    serviceAddress: cart.serviceAddress,
                    itemsPrice: cart.itemsPrice,
                    taxPrice: cart.taxPrice,
                    shippingPrice: cart.shippingPrice,
                    totalPrice: cart.totalPrice,
                }).unwrap();

                dispatch(clearCartItems());

                const sendCustomerEmail = await sendOrderConfirmationEmail(res);
                if (sendCustomerEmail.error) {
                    toast.error(sendCustomerEmail.error);
                } else {
                    console.log('Order Confirmation Email Sent To Customer');
                }

                const sendAdminEmail = await sendNewOrderEmail(res);
                if (sendAdminEmail.error) {
                    toast.error(sendAdminEmail.error);
                } else {
                    console.log('Order Confirmation Email Sent To Excite')
                }

                navigate(`/order/${res._id}`);
            } catch (error) {
                toast.error(error);
            }
        };

  return (
    <>
        <Header />
        <OrderBanner/>

        <Container>
            <Row className='justify-content-center my-5'> 
                <Col lg='8'>
                    <CheckoutSteps step1 step2 step3 step4 />

                    <div className='bg-transparent rounded border-primary p-4'>

                        {confirmingOrderEmail && <Loader />}
                        {submittingOrder && <Loader />}

                        <h3 className="text-dark mb-4">Order Summary</h3>

                        <hr />

                        <Row className='mb-0'>
                            <Col className='col-12 col-sm-6'>
                                <h5 className='text-dark'>Contact Details</h5>
                                <p className='m-0'>{userInfo.firstName} {userInfo.lastName}</p>
                                <p className='m-0'>{userInfo.email}</p>
                                <p className='m-0'>{userInfo.phone}</p>
                            </Col>
                            <hr className='d-sm-none mt-3' />
                            <Col>
                                <h5 className='text-dark'>Service Address</h5>
                                <p className='m-0'>
                                {cart.serviceAddress.address} <br /> {cart.serviceAddress.city} {cart.serviceAddress.postalCode} <br /> {cart.serviceAddress.region}, {cart.serviceAddress.country} 
                                </p>
                        </Col>
                        </Row>

                        <hr />

                            



                        <h5 className="text-dark">Services</h5>

                        {cart.cartServices.length === 0 ? (
                                <p>No services</p>
                        ) : (
                            <ListGroup variant='flush' className='m-0 ps-3'>
                                { cart.cartServices.map((item, index) => (
                                    <ListGroup.Item key={index} className='py-0 border-0'>
                                        <Row>
                                            <Col className='p-0'>
                                                {item.name} (x{item.qty})
                                            </Col>
                                            <Col>
                                                ${item.price * item.qty}
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        ) }

                        <hr />

                        <h5 className="text-dark">Add-Ons</h5>

                        {cart.cartServices.length === 0 ? (
                                <p>No add-ons</p>
                        ) : (
                            <ListGroup variant='flush' className='m-0 ps-3'>
                                { cart.cartHardwares.map((item, index) => (
                                    <ListGroup.Item key={index} className='py-0 border-0'>
                                        <Row>

                                            <Col className='p-0'>
                                                {item.name} (x{item.qty})
                                            </Col>
                                            <Col>
                                                ${item.price * item.qty}
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        ) }

                        <hr />

                        <h5 className="text-dark">Shipping</h5>

                        {cart.shippingPrice === 0 ? (
                                <p>Not applicable</p>
                        ) : (
                            <ListGroup variant='flush' className='m-0 ps-3'>
                                    <ListGroup.Item className='py-0 border-0'>
                                        <Row>

                                            <Col className='p-0'>
                                                Standard Shipping (NZ-Wide)
                                            </Col>
                                            <Col>
                                                ${cart.shippingPrice}
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                            </ListGroup>
                        ) }

                        <hr />

                        <h5 className="text-dark">Subtotal</h5>

                        <ListGroup variant='flush' className='m-0 ps-3'>
                            <ListGroup.Item className='py-0 border-0'>
                                <Row>
                                    <Col className='p-0'>
                                        Item subtotal (inc. shipping)
                                    </Col>
                                    <Col>
                                        ${cart.totalPrice}
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item className='py-0 border-0'>
                                <Row>
                                    <Col className='p-0'>
                                        Includes GST of
                                    </Col>
                                    <Col>
                                        ${cart.taxPrice}
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item className='py-0 border-0'>
                                <Row>
                                    <Col className='p-0 fw-bold text-dark mt-2'>
                                        Total
                                    </Col>
                                    <Col className='fw-bold text-dark mt-2'>
                                        ${cart.totalPrice}
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        </ListGroup>

                        <hr />

                        <Button type='button' className='btn btn-grad' disabled={cart.cartItems.length === 0} onClick={placeOrderHandler}>
                            Place Order
                        </Button>
                        {isLoading && <Loader />}

                    </div>
                </Col>
            </Row>
        </Container>
        <Footer />
    </>
  )
}

export default PlaceOrderScreen