import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Header from '../components/Header.jsx'
import Footer from '../components/Footer.jsx';
import { Form } from 'react-bootstrap'
import OrderBanner from '../components/OrderBanner.jsx'
import { FaTrash } from "react-icons/fa";
import { addServicesToCart, removeServicesFromCart, addHardwaresToCart, removeHardwaresFromCart, clearServiceAddress } from "../slices/cartSlice.js";

const CartScreen = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const cart = useSelector((state) => state.cart);
    const { cartServices, cartHardwares, serviceAddress } = cart;

    const addServicesToCartHandler = async (product, qty) => {
        dispatch(addServicesToCart({...product, qty}))
    };

    const removeServicesFromCartHandler = async (id) => {
        dispatch(removeServicesFromCart(id));
    };

    const addHardwaresToCartHandler = async (product, qty) => {
        dispatch(addHardwaresToCart({...product, qty}))
    };

    const removeHardwaresFromCartHandler = async (id) => {
        dispatch(removeHardwaresFromCart(id));
    };

    const removeAddressHandler = async () => {
        dispatch(clearServiceAddress());
    }

    const checkoutHandler = () => {
        navigate('/login?redirect=/details');
    };

    console.log(serviceAddress);
    
  return (
    <>
    <Header />
    <OrderBanner/>

    <div className="container">
        <div className="row justify-content-center my-5">
            <div className="col-lg-8 bg-transparent p-3 me-2">
                <div className="p-3">
                    <h3 className="text-dark mb-4">Your Cart</h3>

                    { cartServices.length === 0 && cartHardwares.length === 0 ? (
                    
                        <div className="row">
                            <h6 className='text-dark fw-normal'>Your cart is empty </h6>
                        </div>
                    
                    ) : (
                    
                        <div className="container text-dark">
                        <div className="row">
                            <table className="table b-4">
                                <thead className='border-bottom'>
                                    <th scope='col' className='fs-sm fw-normal col-4'>Product</th>
                                    <th scope='col' className='fs-sm fw-normal col-2'>Price</th>
                                    <th scope='col' className='fs-sm fw-normal col-3'>Quantity</th>
                                    <th scope='col' className='fs-sm fw-normal col-2'>Total</th>
                                    <th scope='col' className='fs-sm fw-normal col-2 text-end'>
                                        Remove
                                    </th>
                                </thead>

                                {cartServices.map((item) => (

                                    <tbody className="align-middle" key={item._id}>
                                        <tr>
                                            <td className="py-3 ps-0">
                                                {item.name}
                                                <Link to={`/product/${item._id}/order`} className='ms-2 text-primary'>Edit</Link>   
                                            </td>

                                            <td className="h6 py-3">
                                                ${item.price}
                                            </td>
                                            <td className='py-3 pe-5'>
                                                <Form.Select className='text-dark' as='select'size="sm" value={item.qty} onChange={(e) => addServicesToCartHandler(item, Number(e.target.value))}>
                                                    {[...Array(item.countInStock).keys()].map((x) => (
                                                        <option key={x + 1} value={x + 1}>{x+1}</option>
                                                    ))}
                                                </Form.Select>
                                            </td>
                                            <td className="h6 py-3">
                                                ${item.price * item.qty}
                                            </td>
                                            <td className="text-end py-3 pe-3">
                                            <button className="btn-none h5 bg-transparent btn-trash" onClick={ () => removeServicesFromCartHandler(item._id) }>
                                                <FaTrash />
                                            </button>
                                        </td>
                                        </tr>
                                    </tbody>
                                ))}

                                {cartHardwares.map((item) => (

                                    <tbody className="align-middle" key={item._id}>
                                        <tr>
                                            <td className="py-3 ps-0">
                                               {item.name} 
                                               <Link to={`/orderhardware`} className='ms-2 text-primary'>Edit</Link>
                                            </td>

                                            <td className="h6 py-3">
                                                ${item.price}
                                            </td>
                                            <td className='py-3 pe-5'>
                                                <Form.Select className='text-dark' as='select' size="sm" value={item.qty} onChange={(e) => addHardwaresToCartHandler(item, Number(e.target.value))}>
                                                    {[...Array(item.countInStock).keys()].map((x) => (
                                                        <option key={x + 1} value={x + 1}>{x+1}</option>
                                                    ))}
                                                </Form.Select>
                                            </td>
                                            <td className="h6 py-3">
                                                ${item.price * item.qty}
                                            </td>
                                            <td className="text-end py-3 pe-3">
                                            <button className="btn-none h5 bg-transparent btn-trash" onClick={ () => removeHardwaresFromCartHandler(item._id) }>
                                                <FaTrash />
                                            </button>
                                        </td>
                                        </tr>
                                    </tbody>
                                ))}

    
                            </table>
                        </div>
                    </div>

                    ) }


                </div>
            </div>
            <div className="col-lg-3 bg-transparent rounded border-primary p-3 ms-2 me-2">
                    <div className="p-3">
                        <h3 className="text-dark">Order Summary</h3>
                        <hr />
                        <ul className="list-unstyled text-dark">
                            <li className="d-flex justify-content-between mb-2">
                                Subtotal &#40; 
                                { cartServices.reduce((acc, item) => acc + item.qty, 0) + cartHardwares.reduce((acc, item) => acc + item.qty, 0)}
                                &#41;
                                <span className="fw-medium">
                                    ${cart.itemsPrice}
                                </span>
                            </li>
                            <li className='mb-2'>

                            {serviceAddress.address && (
                                <p className="mt-2 fs-xs">Service Address: {serviceAddress.address} {serviceAddress.city} {serviceAddress.postalCode}<br />{serviceAddress.region} {serviceAddress.country} 
                            <Link onClick={removeAddressHandler} className='text-primary fs-xs ms-2'>Remove</Link> </p>
                            )}

                            {!serviceAddress.address &&  (
                                <p className='mt-2 fs-xs'>Service Address: Not set <br />
                                Please set your address on the next page.
                                </p>
                            )}
                             
                            </li>
                            <li className="d-flex justify-content-between mb-2">
                                Shipping
                                <span className="fw-medium">
                                    ${cart.shippingPrice}
                                </span>
                            </li>
                            <li>
                                     
                            </li>
                            <hr />
                            <li className="d-flex justify-content-between mb-2">
                                Total
                                <span className="fw-medium">
                                    ${cart.totalPrice}
                                </span>
                            </li>
                            <li className='small mb-4'>
                                <p className='small'>GST included in Total: ${cart.taxPrice}</p>
                            </li>
                        </ul>
                        <button type='button' className='btn-block btn btn-grad' disabled={ cartServices.length === 0} onClick={ checkoutHandler }>Proceed to checkout</button>
                        
                    </div>
                </div>
        </div>
    </div>
    <Footer />                                          
    </>
  )
}

export default CartScreen