import { useParams, useNavigate } from 'react-router-dom';
import { useGetOrderDetailsQuery, useSubmittingOrderMutation, useProcessingOrderMutation, useCompleteOrderMutation, useDeleteOrderMutation } from '../slices/ordersApiSlice.js';
import { toast } from 'react-toastify';
import Header from '../components/Header.jsx';
import OrderBanner from '../components/OrderBanner.jsx';
import Message from '../components/Message';
import Loader from '../components/Loader.jsx';
import Footer from '../components/Footer.jsx';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useOrderProcessingEmailMutation } from '../slices/mailApiSlice.js';

const OrderScreen = () => {

    const navigate = useNavigate();

    const { id: orderId } = useParams();

    const { data: order, refetch, isLoading, error } = useGetOrderDetailsQuery(orderId);

    const { userInfo } = useSelector((state) => state.auth);

    const [submittingOrder, { isLoading: loadingSubmit }] = useSubmittingOrderMutation();
    const [processingOrder, { isLoading: loadingProcessing }] = useProcessingOrderMutation();
    const [completeOrder, { isLoading: loadingComplete }] = useCompleteOrderMutation();
    const [orderProcessingEmail, { isLoading: loadingProcessingEmail }] = useOrderProcessingEmailMutation();
    const [deleteOrder, { isLoading: loadingDelete }] = useDeleteOrderMutation();

    const submittedOrderHandler = async () => {
        try {
            await submittingOrder(orderId);
            refetch();
            toast.success('Order status changed');
        } catch (error) {
            toast.error(error?.data?.message || error.message);
        }
    }

    const processOrderHandler = async () => {
        try {

            const updatingOrder = await processingOrder(orderId);
            if (updatingOrder.error) {
                toast.error(updatingOrder.error);
            } else {
                refetch();
                toast.success('Order status changed');
            }

            const data = order;

            const sendCustomerUpdateEmail = await orderProcessingEmail(data);
            if (sendCustomerUpdateEmail.error) {
                toast.error(sendCustomerUpdateEmail.error);
            } else {
                console.log('Email sent successfully');
            };

        } catch (error) {
            toast.error(error?.data?.message || error.message);
        }
    }

    const completeOrderHandler = async () => {
        try {
            await completeOrder(orderId);
            refetch();
            toast.success('Order completed');
        } catch (error) {
            toast.error(error?.data?.message || error.message);
        }
    }

    const deleteHandler = async (id) => {
        if (window.confirm('Do you want to delete this order?')) {
            try {
                await deleteOrder(id);
                toast.success('Order was successfully deleted');
                refetch();
                navigate(`/admin/orderlist`);
            } catch (error) {
                toast.error(error?.data?.message || error.error);
            };
        };
    };

  return isLoading ? <Loader /> : error ? <Message variant="danger" /> : (
    <>

    <Header />
    <OrderBanner />

    <Container>
            <Row className='justify-content-center my-5'> 
                <Col lg='8'>
                    {userInfo && !userInfo.isAdmin && (
                        <div>
                            <h2 className='text-dark text-center'>Thank you for your order!</h2>
                            <p className='text-center'>We can’t wait to help you get connected with Excite. One of our team members will review your order within 1-2 business days and get in contact with you to confirm your requirements and connection details. Standard connections typically take 1-3 business days once approved.</p>
                        </div>
                    )}
                    

                    <div className='bg-transparent rounded border-primary p-4'>

                        <h3 className="text-dark mb-0">Order No. {order._id}</h3>
                        <p className='m-0 mb-3'>{order.createdAt.substring(0,10)}</p>

                        <hr />

                        <Row className='mb-0'>
                            <Col className='col-12 col-sm-6'>
                                <h4 className="text-dark">Billing Details</h4>
                                <p className='m-0'>{order.user.firstName} {order.user.lastName}</p>
                                <p className='m-0'>{order.user.email}</p>
                                <p className='fw-bold text-dark mb-0 mt-3'>Service Address:</p>
                                <p className='m-0'>{order.serviceAddress.address}</p>
                                <p className='m-0'>{order.serviceAddress.city} {order.serviceAddress.postalCode}</p>
                                <p className="m-0">{order.serviceAddress.region}, NZ</p>
                            </Col>
                            <hr className='d-block d-sm-none mt-3'/>
                            <Col className='col-12 col-sm-6'>
                                <h4 className="text-dark">Order Status</h4>
                                {order.isProcessing === 'false' ? (
                                    <p>Submitted</p>
                                ) : order.isProcessing === 'true' && order.isCompleted === 'false' ? (
                                    <p>Processing</p>
                                ) : order.isProcessing === 'true' && order.isCompleted === 'true' (
                                    <p>Completed</p>
                                )}

                                {order.isCompleted ? (
                                <p>Completed</p>
                                ) : (
                                    <>
                                    {order.isProcessing ? (
                                        <p>Processing</p>
                                    ) : (
                                        <p>Submitted</p>
                                    )}
                                    </>
                                )}

                                <p className='text-muted italic m-0'>Last updated: {order.updatedAt.substring(0,10)}</p>

                            </Col>
                        </Row>

                        

                        <hr />

                        <h4 className="text-dark">Your Order</h4>

                        {order.orderServices.length === 0 ? (
                                <p>No services</p>
                        ) : (
                            <ListGroup variant='flush' className='m-0'>
                                { order.orderServices.map((item, index) => (
                                    <ListGroup.Item key={index} className='py-0 border-0'>
                                        <Row>
                                            <Col className='p-0 col-12 col-sm-6'>
                                                {item.name} (x{item.qty})
                                            </Col>
                                            <Col className='col-12 col-sm-6 p-0'>
                                                ${item.price * item.qty}
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        ) }

                        <hr />

                        <h5 className="text-dark">Add-Ons</h5>

                        {order.orderHardwares.length === 0 ? (
                                <p>No add-ons</p>
                        ) : (
                            <ListGroup className='m-0 unstyled-list p-0'>
                                { order.orderHardwares.map((item, index) => (
                                    <ListGroup.Item key={index} className='py-0 border-0'>
                                        <Row className='m-0'>
                                            <Col className='p-0 m-0 col-12 col-sm-6'>
                                                {item.name} (x{item.qty})
                                            </Col>
                                            <Col className='col-12 col-sm-6 p-0'>
                                                ${item.price * item.qty}
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        ) }

                        <hr />

                        <h5 className="text-dark">Shipping</h5>

                        {order.shippingPrice === 0 ? (
                                <p>Not applicable</p>
                        ) : (
                            <ListGroup className='m-0'>
                                    <ListGroup.Item className='py-0 border-0'>
                                        <Row>

                                            <Col className='p-0 col-12 col-sm-6'>
                                                Standard Shipping (NZ-Wide)
                                            </Col>
                                            <Col className='p-0 col-12 col-sm-6'>
                                                ${order.shippingPrice}
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                            </ListGroup>
                        ) }

                        <hr />

                        <h5 className="text-dark">Subtotal</h5>

                        <ListGroup variant='flush' className='m-0'>
                            <ListGroup.Item className='py-0 border-0'>
                                <Row>
                                    <Col className='p-0 col-12 col-sm-6'>
                                        Item subtotal (inc. shipping)
                                    </Col>
                                    <Col className='col-12 col-sm-6 p-0'>
                                        ${order.totalPrice}
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item className='py-0 border-0'>
                                <Row className='mt-2 mt-sm-0'>
                                    <Col className='p-0 col-12 col-sm-6'>
                                        Includes GST of
                                    </Col>
                                    <Col className='col-12 col-sm-6 p-0'>
                                        ${order.taxPrice}
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item className='py-0 border-0'>
                                <Row>
                                    <Col className='p-0 fw-bold text-dark mt-2'>
                                        Total
                                    </Col>
                                    <Col className='fw-bold text-dark mt-2'>
                                        ${order.totalPrice}
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        </ListGroup>
            
                    </div>

                    {loadingProcessingEmail && <Loader />}           

                    {userInfo && userInfo.isAdmin && (
                        <div className='text-center mt-3 d-flex flex-column flex-md-row justify-content-center'>

                            <button className='btn btn-primary btn-sm me-md-2 mb-1 mb-md-0' onClick={() => navigate(-1)}>Dashboard</button>

                            {loadingProcessing && <Loader />}

                            {!order.isProcessing ? (
                                <button className='btn btn-primary btn-sm me-md-2 mb-1 mb-md-0' onClick={processOrderHandler}>Process Order</button>
                            ) : (
                                <button className='btn btn-dark btn-sm me-md-2 mb-1 mb-md-0' disabled>Processing</button>
                            )}

                            {loadingComplete && <Loader />}

                            {!order.isCompleted ? (
                                <button className='btn btn-primary btn-sm mb-1 mb-md-0' onClick={completeOrderHandler}>Complete Order</button>
                            ) : (
                                <button className='btn btn-primary btn-sm mb-1 mb-md-0' disabled>Completed</button>
                            )}

                            {loadingSubmit && <Loader />}

                            <button className='btn btn-primary btn-sm ms-md-2 mb-1 mb-md-0' onClick={submittedOrderHandler}>Reset Status</button>

                            {loadingDelete && <Loader />}

                            <button className='btn btn-primary btn-sm ms-md-2' onClick={() => deleteHandler(order._id)}>Delete Order</button>

                        </div>
                    )}

                </Col>  
            </Row>
    </Container>

    <Footer />
    
    </>
  )
}

export default OrderScreen