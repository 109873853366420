import React from 'react'
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addHardwaresToCart } from '../slices/cartSlice.js';
import Header from '../components/Header.jsx'
import Footer from '../components/Footer.jsx';
import HardwareDetailsBanner from '../components/HardwareDetailsBanner.jsx';
import { Container } from 'react-bootstrap';
import Meta from '../components/Meta.jsx';
import { useGetHardwareDetailsQuery } from '../slices/hardwaresApiSlice.js';
import Loader from '../components/Loader.jsx';
import Message from '../components/Message.jsx';

const HardwareDetailsScreen = () => {

    const { id: hardwareId } = useParams();

    const { data: hardware, isLoading, error } = useGetHardwareDetailsQuery(hardwareId);

    const [qty, setQty] = useState(1);

    const dispatch = useDispatch();

    const AddQty = () => {
        if (qty < hardware.countInStock) setQty(qty => qty + 1);
    }
    const DecreaseQty = () => {
        if(qty > 1) setQty(qty => qty - 1);
    }

    const addHardwaresToCartHandler = () => {
        dispatch(addHardwaresToCart({ ...hardware, qty }));
    }

  return (
    <>
    <Header />
    { isLoading ? ( <Loader /> ) : error ? ( <Message variant='danger'>{error?.data?.message || error.error} </Message>) : (
    <div>
            
            <HardwareDetailsBanner />
            <Meta title={hardware.name} />
            <section>
                <Container className='py-5'>
                    
                    <h2 className='text-dark mt-3'>{hardware.name}</h2>
                    <p className="text-muted">{hardware.shortDescription}</p>

                    <div className="row">
                        <div className="col-sm-12 col-lg-12">
                            <ul className="nav nav-tabs" id="hardwareTabs" role="tablist">
                                <li className="nav-item" role='presentation'>
                                    <button className="nav-link active" id="overviewTab" data-bs-toggle="pill" data-bs-target="#pillsOverview" type="button" role='tab' aria-controls='pillsOverview' aria-selected="true">Overview</button>
                                </li>
                                <li className="nav-item" role='presentation'>
                                    <button className="nav-link" id="detailsTab" data-bs-toggle="pill" data-bs-target="#pillsDetails" type='button' role='tab' aria-controls='pillsDetails' aria-selected="false">Tech Specs</button>
                                </li>
                            </ul>
                            <div className="tab-content" id="hardware-tabContent">

                                <div className="tab-pane fade show active" id="pillsOverview" role='tabpanel' aria-labelledby='overviewTab'>

                                    <section className='container pb-5 mb-1 mb-sm-2 mb-md-3 mb-lg-4 mb-xl-5 pt-5'>
                                        <div className="row">
                                            
                                            <div className="col-md-6">
                                                <div className="ps-md-4 ps-xl-5">
                                                    <h1 className="h3 text-dark">{hardware.tagline}</h1>
                                                    <p className='fs-sm mb-3'>{hardware.shortDescription}</p>
                                                    <h6 className='mb-2 text-dark'>Key features include: </h6>
                                                    <ul className="list-group list-group-borderless">
                                                        <li className="list-group-item">
                                                            <i className="fa fa-check"></i>
                                                            {hardware.hardwareFeatureInfo1}
                                                        </li>
                                                        <li className="list-group-item">
                                                            <i className="fa fa-check"></i>
                                                            {hardware.hardwareFeatureInfo2}
                                                        </li>
                                                        <li className="list-group-item">
                                                            <i className="fa fa-check"></i>
                                                            {hardware.hardwareFeatureInfo3}
                                                        </li>
                                                        <li className="list-group-item">
                                                            <i className="fa fa-check"></i>
                                                            {hardware.hardwareFeatureInfo4}
                                                        </li>
                                                    </ul>
                                                    <a className='d-inline-block fs-sm fw-medium text-muted mt-1 collapsed' href="#moreDescription" data-bs-toggle="collapse" aria-expanded='true' aria-controls='moreDescription' data-label-collapsed="Read More" data-label-expanded="Show Less" aria-label="Show / hide "></a>
                                                    <div className='collapse' id="moreDescription">
                                                        <p className="fs-sm mt-2 mb-2">
                                                            {hardware.introductionInfo}
                                                        </p>
                                                        <p className="fs-sm mt-2 mb-2">
                                                            {hardware.moreInfo}
                                                        </p>

                                                        
                                                    </div>
                                                    <h4 className='my-4 text-dark'>${hardware.price}</h4>

                                                    {hardware.countInStock > 0 && (

                                                        <div className="row">
                                                        <div className="col-4">
                                                            <div className="input-group counter-group d-flex align-items-center">
                                                            
                                                                <span className="input-group-btn counter">
                                                                    
                                                                        <button className="quantity-left-minus btn btn-number py-1 px-3" datatype='minus' data-field='' onClick={DecreaseQty}>
                                                                            <i className="fa fa-minus"></i>
                                                                        </button>
                                                                    
                                                                </span>
                                                                
                                                                <input type="text" id='quantity' name='quantity' className='form-control input-number text-center counter-input' value={qty} min="1" max={hardware.countInStock} />

                                                                <span className="input-group-btn counter">
                                                                    <button className="quantity-right-plus btn btn-number py-1 px-3" datatype='plus' data-field='' onClick={AddQty}>
                                                                        <i className="fa fa-plus"></i>
                                                                    </button>
                                                                </span>

                                                            </div>
                                                        </div>
                                                        <div className="col-8 d-flex align-items-center">
                                                            <button className="counter btn btn-grad w-100" onClick={addHardwaresToCartHandler}>Add to Cart</button>
                                                        </div>
                                                        </div>

                                                    )}

                                                    {hardware.countInStock === 0 && (
                                                        <div className="row">
                                                            <div className="btn btn-dark w-100 disabled">Out Of Stock</div>
                                                            <p className='text-center'>Contact us for more information about stock levels - some products need to be ordered in advance</p>
                                                        </div>
                                                    )}
                                                    

                                                    <div className="d-flex gap-2 pb-3 pb-lg-4 mb-3">
                                                        
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xl-5 offset-xl-1 pt-4">
                                                <div className="bg-image rounded">
                                                    <img src={hardware.imagePath} alt={hardware.name} className='xs-img center-block'/>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                </div>

                                <div className="tab-pane fade" id="pillsDetails" role='tabpanel' aria-labelledby='detailsTab'>
                                    <section className='container pb-5 mb-1 mb-sm-2 mb-md-3 mb-lg-4 mb-xl-5 pt-5'>
    
                                        <a href={require(`../assets${hardware.factsheetPath}`)}>{hardware.name} Factsheet</a>

                                    </section>
                                </div>

                            </div>

                        </div>
                    </div>
                
                </Container>
            </section>

    </div>
    )}
    <Footer />
    </>
  )
}

export default HardwareDetailsScreen