import { apiSlice } from "./apiSlice.js";
import { ORDERS_URL } from '../constants.js'

export const ordersApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createOrder: builder.mutation({
            query: (order) => ({
                url: ORDERS_URL,
                method: 'POST',
                body: {...order}
            })
        }),
        getOrderDetails: builder.query({
            query: (orderId) => ({
                url: `${ORDERS_URL}/${orderId}`
            }),
            keepUnusedDataFor: 5
        }),
        getMyOrders: builder.query({
            query: () => ({
                url: `${ORDERS_URL}/myorders`,
            }),
            keepUnusedDataFor: 5
        }),
        getOrders: builder.query({
            query: () =>  ({
                url: ORDERS_URL,
            }),
            keepUnusedDataFor: 5,
        }),
        submittingOrder: builder.mutation({
            query: (orderId) => ({
                url: `${ORDERS_URL}/${orderId}/submitted`,
                method: 'PUT',
            }),
        }),
        processingOrder: builder.mutation({
            query: (orderId) => ({
                url: `${ORDERS_URL}/${orderId}/processing`,
                method: 'PUT',
            }),
        }),
        completeOrder: builder.mutation({
            query: (orderId) => ({
                url: `${ORDERS_URL}/${orderId}/complete`,
                method: 'PUT',
            }),
        }),
        deleteOrder: builder.mutation({
            query: (orderId) => ({
                url: `${ORDERS_URL}/${orderId}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const { useCreateOrderMutation, useGetOrderDetailsQuery, useGetMyOrdersQuery, useGetOrdersQuery, useSubmittingOrderMutation, useProcessingOrderMutation, useCompleteOrderMutation, useDeleteOrderMutation }= ordersApiSlice;