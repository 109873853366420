import React, { useEffect, useState } from 'react'
import Header from '../../components/Header.jsx'
import AdminBanner from '../../components/AdminBanner'
import Footer from '../../components/Footer'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetProductDetailsQuery, useUpdateProductMutation } from '../../slices/productsApiSlice.js';
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import Loader from '../../components/Loader.jsx'
import Message from '../../components/Message.jsx'
import { toast } from 'react-toastify'
import AdminNav from '../../components/AdminNav.jsx'
import { LinkContainer } from 'react-router-bootstrap'

const ServiceEditScreen = () => {

    const { id: serviceId } = useParams();

    const [name, setName] = useState('');
    const [category, setCategory] = useState('');
    const [download, setDownload] = useState('');
    const [upload, setUpload] = useState('');
    const [dataCap, setDataCap] = useState('');
    const [term, setTerm] = useState('');
    const [equipment, setEquipment] = useState('');
    const [price, setPrice] = useState(0);
    const [description, setDescription] = useState('');
    const [productOverview, setProductOverview] = useState('');
    const [feature1, setFeature1] = useState('');
    const [feature2, setFeature2] = useState('');
    const [feature3, setFeature3] = useState('');
    const [modemReqs, setModemReqs] = useState('');
    const [offerSummaryPath, setOfferSummaryPath] = useState('');

    const { data: service, isLoading, error } = useGetProductDetailsQuery(serviceId);

    const [updateProduct, { isLoading: loadingUpdate }] = useUpdateProductMutation();

    const navigate = useNavigate();

    useEffect(() => {
        if (service) {
            setName(service.name);
            setCategory(service.category);
            setDownload(service.download);
            setUpload(service.upload);
            setDataCap(service.dataCap);
            setTerm(service.term);
            setEquipment(service.equipment);
            setPrice(service.price);
            setDescription(service.description);
            setProductOverview(service.productOverview);
            setFeature1(service.feature1);
            setFeature2(service.feature2);
            setFeature3(service.feature3);
            setModemReqs(service.modemReqs);
            setOfferSummaryPath(service.offerSummaryPath);
        }
    }, [service]);

    const submitHandler = async (e) => {
        e.preventDefault();
        const updatedProduct = {
            serviceId,
            name,
            category,
            download,
            upload,
            dataCap,
            term,
            equipment,
            price,
            description,
            productOverview,
            feature1,
            feature2,
            feature3,
            modemReqs,
            offerSummaryPath,
        };

        const result = await updateProduct(updatedProduct);
        if (result.error) {
            toast.error(result.error);
        } else {
            toast.success('Service updated');
            navigate(`/admin/service/${serviceId}/info`);
        }
    };

  return (
    <>

    <Header />
    <AdminBanner />

    <Container fluid>
        <Row className='justify-content-center my-5'> 
            <Col lg='10'>
                <div className="bg-transparent rounded border-primary p-4">

                <Row>

                    <AdminNav />

                    <Col lg='9' className='ps-4'>

                        {loadingUpdate && <Loader />}

                        { isLoading ? ( <Loader /> ) : error ? ( <Message variant='danger'>{error?.data?.message || error.error}</Message>) : (
                            <>

                            <Row>
                                <Col>
                                    <h2 className="text-dark mb-0">{service.name}</h2>
                                        <p className='fs-xs italic m-0'>Last Updated: {service.updatedAt.substring(0,10)}</p>
                                </Col>
                                <Col className='text-end'>
                                    <LinkContainer to={`/admin/service/${service._id}/info`}>
                                        <Button className='btn btn-primary btn-sm'>
                                            Back
                                        </Button>
                                    </LinkContainer>
                                </Col>
                            </Row>

                            <hr />

                            <Form onSubmit={submitHandler}>
                                <Form.Group controlId='name'>
                                    <Form.Label className='m-0 mt-3 text-dark ps-1'>Service Name:</Form.Label>
                                    <Form.Control type='name' placeholder='Enter Name' value={name} onChange={(e) => setName(e.target.value)}></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='category'>
                                    <Form.Label className='m-0 mt-1 text-dark ps-1'>Category</Form.Label>
                                    <Form.Control type='text' placeholder='Enter category' value={category} onChange={(e) => setCategory(e.target.value)}></Form.Control>
                                </Form.Group>

                                <Row className='mt-0'>
                                    <Col className='mt-0'>
                                        <Form.Group controlId='download'>
                                            <Form.Label className='m-0 mt-2 text-dark ps-1'>Download speed (Mbps):</Form.Label>
                                            <Form.Control type='text' placeholder='Enter download speed' value={download} onChange={(e) => setDownload(e.target.value)}></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group controlId='upload'>
                                            <Form.Label className='m-0 mt-2 text-dark ps-1'>Upload speed (Mbps):</Form.Label>
                                            <Form.Control type='text' value={upload} onChange={(e) => setUpload(e.target.value)}></Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className='mt-0'>
                                    <Col className='mt-0'>
                                        <Form.Group controlId='dataCap'>
                                            <Form.Label className='m-0 mt-2 text-dark ps-1'>Data Cap:</Form.Label>
                                            <Form.Control type='text' placeholder='Enter data cap' value={dataCap} onChange={(e) => setDataCap(e.target.value)}></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group controlId='term'>
                                            <Form.Label className='m-0 mt-2 text-dark ps-1'>Contract term (months):</Form.Label>
                                            <Form.Control type='text' placeholder='Enter contract term' value={term} onChange={(e) => setTerm(e.target.value)}></Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Form.Group controlId='equipment'>
                                    <Form.Label className='m-0 mt-2 text-dark ps-1'>Equipment required:</Form.Label>
                                    <Form.Control type='text' placeholder='Enter equipment' value={equipment} onChange={(e) => setEquipment(e.target.value)}></Form.Control>
                                </Form.Group>
                                
                                <Form.Group controlId='description'>
                                    <Form.Label className='m-0 mt-2 text-dark ps-1'>Description:</Form.Label>
                                    <Form.Control type='text' placeholder='Enter short description' value={description} onChange={(e) => setDescription(e.target.value)}></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='productOverview'>
                                    <Form.Label className='m-0 mt-2 text-dark ps-1'>Service Overview:</Form.Label>
                                    <Form.Control type='text' as="textarea" value={productOverview} onChange={(e) => setProductOverview(e.target.value)}></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='feature1'>
                                    <Form.Label className='m-0 mt-2 text-dark ps-1'>Feature 1:</Form.Label>
                                    <Form.Control type='text' placeholder='Enter feature' value={feature1} onChange={(e) => setFeature1(e.target.value)}></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='feature2'>
                                    <Form.Label className='m-0 mt-2 text-dark ps-1'>Feature 2:</Form.Label>
                                    <Form.Control type='text' placeholder='Enter feature' value={feature2} onChange={(e) => setFeature2(e.target.value)}></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='feature3'>
                                    <Form.Label className='m-0 mt-2 text-dark ps-1'>Feature 3:</Form.Label>
                                    <Form.Control type='text' placeholder='Enter feature' value={feature3} onChange={(e) => setFeature3(e.target.value)}></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='modemReqs'>
                                    <Form.Label className='m-0 mt-2 text-dark ps-1'>Modem Requirements:</Form.Label>
                                    <Form.Control type='text' placeholder='Enter modem requirements' value={modemReqs} onChange={(e) => setModemReqs(e.target.value)}></Form.Control>
                                </Form.Group>
                                
                                <Form.Group controlId='price'>
                                    <Form.Label className='m-0 mt-2 text-dark ps-1'>Price (per month)</Form.Label>
                                    <Form.Control type='number' placeholder='Enter price here' value={price} onChange={(e) => setPrice(e.target.value)}></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='offerSummaryPath'>
                                    <Form.Label className='m-0 mt-2 text-dark ps-1'>Offer Summary Path:</Form.Label>
                                    <Form.Control type='text' placeholder='Enter path' value={offerSummaryPath} onChange={(e) => setOfferSummaryPath(e.target.value)}></Form.Control>
                                </Form.Group>  

                                <Button type='submit' className='btn btn-sm mt-3'>Update Service</Button>
                            </Form>

                            </>
                        )}
     
                    </Col>

                </Row>

                </div>

            </Col>
        </Row>
    </Container>


    <Footer />

    </>
  )
}

export default ServiceEditScreen