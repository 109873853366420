import React from 'react'
import { useState } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Form, Button } from 'react-bootstrap'
import logo from '../assets/images/excite-white-logo-png.png'

const PasswordResetScreen = () => {

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

  return (
    
    <div className="container-fluid p-0 m-0 w-100">
        <section className="p-0 d-flex align-items-center full-page w-100">
                <div className="row h-100 w-100">
                    <div className="col-12 col-md-5 col-lg-4 d-md-flex align-items-center bg-grad">
                        <div className="w-100 p-3 p-lg-5 text-white">
                            <LinkContainer to={'/'}>
                            <div className="justify-content-center align-self-center navbar-brand cursor-pointer">
                                <img src={logo} alt="Logo" className="navbar-brand-item" />
                                excite
                            </div>
                            </LinkContainer>
                            
                            <h3 className="fw-light my-3">Lorem ipsum dolor sit amet. elit. Cum, maxime?</h3>
                            
                        </div>
                    </div>
                    <div className="col-12 col-md-7 col-xl-8 mx-auto my-5">
                        <div className="row h-100">
                            <div className="col-12 col-md-10 col-lg-5 text-start text-dark mx-auto d-flex align-items-center">
                                <div className="w-100">

                                    <h2>Reset your password</h2>
                                    <h5 className="fw-light">Info Here</h5>
                                    
                                    <Form>
                                    <Form.Group controlId='password' className='mb-2'>
                                                <Form.Label className='m-0'><p className='text-start m-0'>Password</p></Form.Label>
                                                    <Form.Control className='form-control'
                                                        type='password'
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                    ></Form.Control>
                                            </Form.Group>

                                            <Form.Group controlId='confirmPassword'>
                                                <Form.Label className='m-0'><p className='text-start m-0'>Confirm Password</p></Form.Label>
                                                    <Form.Control className='form-control'
                                                        type='password'
                                                        value={confirmPassword}
                                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                                    ></Form.Control>
                                            </Form.Group>

                                            <Button type='submit' className='mt-3 btn btn-grad'>
                                            
                                            Reset Password
                                            </Button>
                                    </Form>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </section>
    </div>
  )
}

export default PasswordResetScreen