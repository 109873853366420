import React, { useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { useSendEnquiryConfirmEmailMutation, useSendEnquiryDetailsEmailMutation } from '../slices/mailApiSlice'
import { toast } from 'react-toastify'
import Loader from '../components/Loader'

const ContactScreen = () => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [enquirySubject, setEnquirySubject] = useState('General Enquiry');
    const [enquiryMessage, setEnquiryMessage] = useState('');

    const [submitted, setSubmitted] = useState(false);

    const [confirmEnquiry, { isLoading: loadingSubmit }] = useSendEnquiryConfirmEmailMutation();
    const [sendEnquiryDetails, { isLoading: sendingEnquiryDetails }] = useSendEnquiryDetailsEmailMutation();



    const submitHandler = async (e) => {
        e.preventDefault();

        if (firstName.length >= 3) {
            if (lastName.length >= 3) {
                if (email.length > 5 && email.includes('@') && email.includes('.')) {
                    if (enquiryMessage.length > 5) {

                        const data = {
                            firstName,
                            lastName,
                            email,
                            phone,
                            enquirySubject,
                            enquiryMessage,
                        };
                
                        const response = await confirmEnquiry(data);
                        if (response.error) {
                            toast.error(response.error);
                        } else {
                            toast.success('Enquiry successfully submitted.');
                            console.log(`Form Submitted. Sent confirmation to: ${email}`);
                        };
                
                        const result = await sendEnquiryDetails(data);
                        if (result.error) {
                            toast.error(result.error);
                        } else {
                            setSubmitted(true);
                            console.log(`Details successfully sent to Excite Team`);
                        };

                    } else {
                        toast.error('Message is invalid');
                    }
                } else {
                    toast.error('Email is invalid');
                }
            } else {
                toast.error('Last name is invalid');
            }
        } else {
            toast.error('First name is invalid');
        }

    };

  return (
    <>
        <Header />
        <section className="p-0 position-relative bg-grad pattern-overlay-1 overflow-hidden">
            <div className="container d-flex h-100 justify-content-center">
                <div className="row align-self-center w-100 text-center mt-4">

                    <div className="mt-md-0 text-white z-index-100">

                        <Row className='mt-5 my-2'>
                            <h2 className="display-4 fw-bold mt-5">
                            Contact Us
                            </h2>
                            <p className='h5 fw-normal'>Get in touch with the Excite Team</p>
                        </Row>

                       
                                
                       <Row className='my-4 d-flex align-items-center justify-content-center'>
                            <Col lg={8} className='bg-white rounded p-5'>

                            {!submitted ? (
                            <Row>
                            <p className="text-muted mt-2">
                                Fill out the form below to get in contact with the Excite Team.
                                You may also directly email as any enquiries at info@excite.net.nz
                            </p>
                            <Col>
                                <Form onSubmit={submitHandler}>
                                    <Row>
                                        <Col className='col-12 col-md-6 text-start'>
                                            <Form.Group controlId='firstName'>
                                                <Form.Label className='m-0 mt-3 text-dark ps-1'>First Name:</Form.Label>
                                                <Form.Control type='name' placeholder='Enter First Name' onChange={(e) => setFirstName(e.target.value)}></Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-12 col-md-6 text-start'>
                                            <Form.Group controlId='lastName'>
                                                <Form.Label className='m-0 mt-3 text-dark ps-1'>Last Name:</Form.Label>
                                                <Form.Control type='name' placeholder='Enter Last Name' onChange={(e) => setLastName(e.target.value)}></Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='col-12 col-md-6 text-start'>
                                            <Form.Group controlId='email'>
                                                <Form.Label className='m-0 mt-3 text-dark ps-1'>Email:</Form.Label>
                                                <Form.Control type='email' placeholder='Enter Email' onChange={(e) => setEmail(e.target.value)}></Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-12 col-md-6 text-start'>
                                            <Form.Group controlId='phone'>
                                                <Form.Label className='m-0 mt-3 text-dark ps-1'>Phone:</Form.Label>
                                                <Form.Control type='phone' placeholder='Enter Phone' onChange={(e) => setPhone(e.target.value)}></Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='text-start'>
                                            <Form.Group controlId='subject'>
                                                <Form.Label className='m-0 mt-3 text-dark ps-1'>Subject:</Form.Label>
                                                <Form.Select type='select' onChange={(e) => setEnquirySubject(e.target.value)}>
                                                    <option value='General Enquiry'>General Enquiry</option>
                                                    <option value='Order Status'>Order Status</option>
                                                    <option value='Technical Support'>Tech Support</option>
                                                    <option value='Account Assistance'>Account Assistance</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='text-start'>
                                            <Form.Group controldId='message'>
                                                <Form.Label className='m-0 mt-3 text-dark ps-1'>Message:</Form.Label>
                                                <Form.Control type='text' as='textarea' onChange={(e) => setEnquiryMessage(e.target.value)}></Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                        <Button type='submit' className='btn mt-3 btn-primary'>Submit Enquiry</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            
                            </Col>
                            </Row>
                            ) : (
                            <>
                            <h4 className='text-dark'>Thank you for your enquiry.</h4>
                            <h6 className='text-dark fw-normal mt-3'> We aim to respond to web enquiries within 24 hours. We look forward to answering your questions</h6>
                            </>
                            )}
                            
                            {loadingSubmit && <Loader />}
                            {sendingEnquiryDetails && <Loader />}

                               


                            </Col>
                       </Row>

                    </div>

                </div>
            </div>
        </section> 

        <Footer />
    </>
  )
}

export default ContactScreen