import { useGetHardwaresQuery } from '../slices/hardwaresApiSlice.js'
import Header from '../components/Header.jsx'
import Footer from '../components/Footer.jsx';
import heroImg from '../assets/images/hardware-hero-img.png';
import img2 from '../assets/images/hardware-img1.png';
import HardwareCard from '../components/HardwareCard.jsx';
import Loader from '../components/Loader.jsx';
import Message from '../components/Message.jsx';
import Meta from '../components/Meta.jsx';

const HardwareScreen = () => {

  const { data: hardwares, isLoading, error } = useGetHardwaresQuery();

  return (
    <>
    <Meta title={'Excite Broadband Hardware'} />
    <Header />
<section className="p-0 position-relative h-500 h-md-400 h-xl-500 bg-grad pattern-overlay-1 overflow-hidden">
      <div className="container d-flex h-100">
        <div className="row align-self-center w-100">
          <div className="col-sm-12 col-lg-6 mt-md-0 text-white position-relative text-end text-lg-start">
            <h2 className="display-4 fw-bold">
            Connect. Expand. Mesh.
            </h2>
            <p className="h5 mb-4 fw-normal d-sm-block">
            Seamless Wi-Fi coverage for every corner of your space.
            </p>
          </div>
          <div className="col-sm-12 col-md-6 d-none d-lg-block p-0 position-absolute top-0 end-0 align-top align-text-top ">
            <img className='w-100' src={heroImg} alt="" />
          </div>
        </div>
      </div>
      <div className="wave wave1"></div>
      <div className="wave wave2"></div>
      <div className="wave wave3"></div>
      <div className="wave wave4"></div>
    </section> 


    <section className="pt-5 pb-3 mb-3 service-desc">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-lg-6 align-self-center">
            <div className="title text-start pb-4 p-lg-0">
              <h2 className='text-dark fw-bold'>Experience <span className='text-grad'>Uninterrupted</span> and <span className='text-grad'>Seamless</span> WiFi Coverage Throughout Your Space</h2>
              <p className='text-muted'>Mesh WiFi systems are the ultimate solution for eliminating dead zones and ensuring seamless internet connectivity across your entire home or office. Unlike traditional routers, mesh systems use multiple interconnected nodes to create a unified network that blankets your space with strong, reliable WiFi signals.
              </p>
              <div className="row">
                <div className="col-6">
                  <ul className="list-group list-group-borderless list-group-icon-primary-bg">
                    <li className="list-group-item">
                      <i className="fa fa-circle-check"></i>
                      <p className='text-muted'>Seamless Coverage: Enjoy consistent coverage, eliminating dead spots</p>
                    </li>
                    <li className="list-group-item">
                      <i className="fa fa-circle-check"></i>
                      <p className='text-muted'>Easy setup: Simple app-based installation and network management</p>
                    </li>
                  </ul>
                </div>
                <div className="col-6">
                  <ul className="list-group list-group-borderless list-group-icon-primary-bg">
                      <li className="list-group-item">
                        <i className="fa fa-circle-check"></i>
                        <p className='text-muted'>Smart Routing: Ensures your devices always connect to the strongest signal</p>
                      </li>
                      <li className="list-group-item">
                        <i className="fa fa-circle-check"></i>
                        <p className='text-muted'>Compatibility: Works seamlessly with various smart home and personal devices</p>
                      </li>
                    </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-10 col-lg-6 mx-md-auto text-center align-self-center">
            <img className='small-img' src={img2} alt="" />
          </div>
        </div>
      </div>
    </section>

    <div className="container">
        <hr className='mt-5' />
    </div>

    <section className="pt-4">
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-8 mx-auto">
                    <div className="title text-center">
                        <h2 className='text-dark mb-5'>Explore our range of hardware</h2>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className='pb-3'>

    { isLoading ? (
      <Loader />
    ) : error ? (
      <Message variant='danger'>{error?.data?.message || error.error}</Message>
    ) : (
      <div className='container'>
        <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 gy-4">
            {hardwares.map( hardware => {
              return <HardwareCard hardware={ hardware } />
            })}
        </div>
      </div>
    ) }

    </section>
    <Footer />
    </>
  )
}

export default HardwareScreen