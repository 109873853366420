import React from 'react'
import { Link } from 'react-router-dom';
import {FaArrowDown, FaDownload, FaServer, FaArrowUp} from 'react-icons/fa'
import { MdOutlineSupportAgent } from "react-icons/md";
import { RxLockOpen1 } from "react-icons/rx";


const Product = ({ product }) => {
  return (
    <section className='planCard'>

                <div className="my-3 py-4 px-5 bg-grad-basic rounded d-md-flex align-items-center justify-content-between pattern-overlay-4">
                    <div className="text-center d-flex flex-column justify-content-center">
                        <div>
                            <h5 className='text-white text-uppercase fw-bold'>{product.name}</h5>
                        </div>
                        <div className=''>
                            <h1 className="fw-bold text-white m-0">${product.price}</h1>
                            <p className="text-uppercase text-white m-0">/Month</p>
                        </div>
                    </div>
                    <div className="text-center text-white d-flex flex-column justify-content-between">
                        <p className="m-0">
                            <i className='text-white me-2 h6'><FaArrowDown /></i>
                            {product.download} Download Speed
                        </p>
                        <p className="my-2">
                            <i className='text-white me-2 h6'><FaDownload /></i>
                            {product.dataCap} Data
                        </p>
                        <p className="m-0">
                            <i className='text-white me-2 h6'><FaServer /></i>
                            {product.equipment}
                        </p>
                    </div>
                    <div className="text-center text-white d-flex flex-column mt-2 mt-lg-0">
                        <p className="m-0">
                            <i className='text-white me-2 h6'><FaArrowUp /></i>
                            {product.upload} Upload Speed
                        </p>
                        <p className="my-2">
                            <i className="text-white me-2 h6"><RxLockOpen1 /></i>
                            {product.term}
                        </p>
                        <p className="m-0">
                            <i className="text-white me-2 h6"><MdOutlineSupportAgent /></i>
                            NZ-based Support Team
                        </p>
                    </div>
                    <div className="d-flex flex-row justify-content-center flex-md-column">
                        <Link to={`/product/${product._id}/order`} className=''>
                            <button className="btn btn-white text-white px-4 py-2 main-btn text-decoration-none me-1 me-md-0 mb-md-2">Get Started</button>
                        </Link>
                        <Link to={`/product/${product._id}`} className=''>
                            <button className="btn btn-dark-opaque text-white px-4 py-2 main-btn ms-1 ms-md-0">Learn More</button>
                        </Link>
                    </div>
                </div>

    </section>
  )
}

export default Product