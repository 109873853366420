import React from 'react'
import Header from '../../components/Header'
import AdminBanner from '../../components/AdminBanner'
import AdminNav from '../../components/AdminNav';
import Footer from '../../components/Footer'
import { Col, Container, Row, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeleteHardwareMutation, useGetHardwareDetailsQuery } from '../../slices/hardwaresApiSlice';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import { toast } from 'react-toastify';

const HardwareInfoScreen = () => {

    const { id: hardwareId } = useParams();

    const navigate = useNavigate();

    const { data: hardware, isLoading, error, refetch } = useGetHardwareDetailsQuery(hardwareId);

    const [deleteHardware, { isLoading: loadingDelete }] = useDeleteHardwareMutation();

    const deleteHandler = async (id) => {
        if (window.confirm('Do you want to delete this service?')) {
            try {
                await deleteHardware(id);
                toast.success('Hardware was successfully deleted');
                refetch();
                navigate(`/admin/hardwarelist`)
            } catch (error) {
                toast.error(error?.data?.message || error.error);
            }
        }
    }

  return (
    <>
    
    <Header />
    <AdminBanner />

        <Container fluid>
            <Row className='justify-content-center my-5'>
                <Col lg='10'>
                    <div className="bg-transparent rounded border-primary p-4">

                        {loadingDelete && <Loader />}
                        
                        { isLoading ? ( <Loader /> ) : error ? ( <Message variant='danger'>{error?.data?.message || error.error} </Message>) : (

                            <>
                            <Row>
                                <AdminNav />
                                <Col lg='9' className='ps-4'>

                                    <Row>
                                        <Col>
                                            <h2 className="text-dark mb-0">{hardware.name}</h2>
                                            <p className='fs-xs italic m-0'>Last Updated: {hardware.updatedAt.substring(0,10)}</p>
                                        </Col>
                                        <Col className='text-end'>
                                            <LinkContainer to={`/admin/hardware/${hardware._id}/edit`}>
                                                <Button className='btn btn-primary btn-sm'>
                                                    Edit
                                                </Button>
                                            </LinkContainer>
                                            <Button className='btn btn-primary btn-sm ms-1' onClick={() => deleteHandler(hardware._id)}>
                                                Delete
                                            </Button>
                                        </Col>
                                    </Row>

                                    <hr />

                                    <Row>
                                        <Col lg='8'>

                                            <h4 className='text-dark'>
                                                General Info
                                            </h4>
                                            <p className='text-capitalize m-0'>
                                                <span className='fw-bold text-dark'>Category: </span>
                                                {hardware.category}
                                            </p>
                                            <p className='text-capitalize m-0'>
                                                <span className='fw-bold text-dark'>Tagline: </span>
                                                {hardware.tagline}
                                            </p>
                                            <p className='m-0'>
                                                <span className='fw-bold text-dark'>Description: </span>
                                                {hardware.shortDescription}
                                            </p>
                                            <p className='text-capitalize m-0'>
                                                <span className='fw-bold text-dark'>Count In Stock: </span>
                                                {hardware.countInStock}
                                            </p>

                                            <hr />

                                            <h4 className='text-dark'>
                                                Price
                                            </h4>
                                            <h6 className='text-dark'>${hardware.price}</h6>

                                            <hr />

                                            <h4 className='text-dark'>
                                                More Info
                                            </h4>
                                            <p className='m-0'>
                                                {hardware.introductionInfo}
                                            </p>
                                            <p className='m-0 mt-2'>
                                                {hardware.moreInfo}
                                            </p>

                                            <hr />

                                            <h4 className='text-dark'>
                                                Features
                                            </h4>
                                            <p className='m-0 mb-2'>
                                                <span className='fw-bold text-dark'>{hardware.hardwareFeatureName1}: </span>
                                                {hardware.hardwareFeatureInfo1}
                                            </p>
                                            <p className='m-0 mb-2'>
                                                <span className='fw-bold text-dark'>{hardware.hardwareFeatureName2}: </span>
                                                {hardware.hardwareFeatureInfo2}
                                            </p>
                                            <p className='m-0 mb-2'>
                                                <span className='fw-bold text-dark'>{hardware.hardwareFeatureName3}: </span>
                                                {hardware.hardwareFeatureInfo3}
                                            </p>
                                            <p className='m-0 mb-2'>
                                                <span className='fw-bold text-dark'>{hardware.hardwareFeatureName4}: </span>
                                                {hardware.hardwareFeatureInfo4}
                                            </p>
                                            <hr />

                                            <h4 className='text-dark'>
                                                Documentation
                                            </h4>
                                            <a href={require(`../../assets${hardware.factsheetPath}`)}>{hardware.name} Factsheet</a>

                                        </Col>
                                        <Col>
                                            <div className="bg-image rounded text-center">
                                                <img src={hardware.imagePath} alt={hardware.name} className='xs-img center-block'/>
                                            </div>  
                                        </Col>
                                    </Row>
                                

                                </Col>
                            </Row>
                            </>

                        )}

                    </div>
                </Col>
            </Row>
        </Container>

      <Footer />
    
    </>
  )
}

export default HardwareInfoScreen