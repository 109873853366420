import { useState } from 'react'
import { useDispatch } from 'react-redux';
import { addHardwaresToCart, removeHardwaresFromCart } from '../slices/cartSlice.js';

const AddHardwareCard = ( {hardware} ) => {

const dispatch = useDispatch();

const [qty, setQty] = useState(1);

const AddQty = () => {
    if (qty < hardware.countInStock) setQty(qty => qty + 1);
}
const DecreaseQty = () => {
    if(qty > 1) setQty(qty => qty - 1);
}  

const addHardwaresToCartHandler = () => {
    dispatch(addHardwaresToCart({ ...hardware, qty}));
}

const removeHardwaresFromCartHandler = async (id) => {
    dispatch(removeHardwaresFromCart(id));
}

  return (
    <>

    <div className="container pt-4">
        <div className="row justify-content-center mb-3">
            <div className="col">
                <div className="card shadow-0 border-muted rounded">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12 col-lg-3 col-xl-3 mb-4 mb-lg-0">
                                <div className="bg-image rounded">
                                    <img src={hardware.imagePath} className="small-img center-block" alt={hardware.name} />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-xl-6">
                                <h3 className="text-dark">{hardware.name}</h3>
                                <div className="mt-1 mb-0 text-muted small">
                                    <span className="h6">{hardware.hardwareFeatureName1}</span>
                                    <span className='text-primary h6'> • </span>
                                    <span className="h6">{hardware.hardwareFeatureName2}</span>
                                    <span className='text-primary h6'> • </span>
                                    <span className="h6">{hardware.hardwareFeatureName3}</span>
                                    <span className='text-primary h6'> • </span>
                                    <span className="h6">{hardware.hardwareFeatureName4}</span>
                                </div>
                                <p className="mt-3 mb-md-0">
                                    {hardware.shortDescription}
                                </p>
                            </div>
                            <div className="col-md-6 col-lg-3 col-xl-3 border-sm-start-none border-start">
                                <div className="d-flex flex-row align-items-center mb-1 text-center">
                                    <h3 className="mb-1 text-dark">${hardware.price}</h3>
                                </div>
                                <h6 className="text-muted small">
                                    + Postage $9.99
                                </h6>
                                <div className="d-flex flex-column mt-4">
                                    {hardware.countInStock > 0 &&
                                    <>
                                    <div className="row">
                                        <div className='mb-2'>
                                            <div className="input-group counter-group d-flex align-items-center">
                                                <span className="input-group-btn counter">
                                                    <button className="quantity-left-minus btn btn-number py-1 px-3" datatype='minus' data-field='' onClick={DecreaseQty}>
                                                        <i className="fa fa-minus"></i>
                                                    </button>
                                                </span>
                                                <input type="text" id='quantity' name='quantity' className='form-control input-number text-center counter-input' value={qty} onChange={(e) => setQty(e.target.value)} max={hardware.countInStock} />
                                                <span className="input-group-btn counter">
                                                    <button className="quantity-right-plus btn btn-number py-1 px-3" datatype='plus' data-field='' onClick={AddQty}>
                                                        <i className="fa fa-plus"></i>
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <button className='btn btn-grad btn-sm' onClick={addHardwaresToCartHandler}>Add To Cart</button>
                                    <button className='btn btn-primary-outline btn-sm mt-1' onClick={ () => removeHardwaresFromCartHandler(hardware._id)}>Remove</button>

                                    </>
                                    }

                                    {hardware.countInStock === 0 &&
                                    <>
                                        <button className='btn btn-dark btn-sm disabled'>Out Of Stock</button>
                                    </>
                                    }
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    </>
  )
}

export default AddHardwareCard