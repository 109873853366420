import { useState } from 'react'
import { useParams, useNavigate, Link } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Button, Col } from 'react-bootstrap';
import Header from '../components/Header.jsx'
import OrderBanner from '../components/OrderBanner.jsx'
import { useGetProductDetailsQuery } from '../slices/productsApiSlice.js';
import Loader from '../components/Loader.jsx';
import Message from '../components/Message.jsx';
import Footer from '../components/Footer.jsx';
import { addServiceAddress } from '../slices/cartSlice.js';
import { addServicesToCart } from '../slices/cartSlice.js';
import Meta from '../components/Meta.jsx';

const OrderServiceScreen = () => {

    const { id: productId } = useParams();
    const { data: product, isLoading, error } = useGetProductDetailsQuery(productId);

    const qty = Number(1);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const cart = useSelector((state) => state.cart);
    const { serviceAddress, cartHardwares } = cart;

    const regionsList = ['Select', 'Northland', 'Auckland', 'Waikato', 'Bay of Plenty', 'Gisborne', `Hawke's Bay`, 'Taranaki', 'Whanganui-Manawatu', 'Wellington', 'Marlborough', 'Nelson-Tasman', 'West Coast', 'Cantebury', 'Otago', 'Southland' ];

    const [address, setAddress] = useState(serviceAddress?.address || '');
    const [city, setCity] = useState(serviceAddress?.city || '');
    const [region, setRegion] = useState(serviceAddress?.region || '');
    const [postalCode, setPostalCode] = useState(serviceAddress?.postalCode || '');
    const [country, setCountry] = useState(serviceAddress?.country || 'New Zealand');

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(addServiceAddress({ address, city, region, postalCode, country }));
    }

    const addServicesToCartHandler = () => {
        dispatch(addServicesToCart({ ...product, qty }));
    }

  return (
    <>
    <Meta title={'Order Broadband'} />
    <Header />
    <OrderBanner />

    { isLoading ? (
       <Loader />
    ) : error ? (
        <Message variant='danger'>{error?.data?.message || error.error}</Message>
    ) : (

        <div className="container">
            <div className="row justify-content-center my-5">
                <div className="col-md-7 bg-transparent rounded border-primary p-3 me-md-2">
                    <div className="p-3">
                        <h3 className='text-dark'>{product.name}</h3>
                        <h5>{product.description}</h5>
                        <hr />
                        <div className="container text-dark">
                            <div className="row">
                                <div className="col-6 fw-bold mt-2 mb-4">
                                    Term
                                </div>
                                <div className="col-6 text-end mt-2 mb-4">
                                    {product.term}
                                </div>
                                <div className="col-6 fw-bold mb-4">
                                    Data
                                </div>
                                <div className="col-6 mb-4 text-end">
                                    {product.dataCap}
                                </div>
                                <div className="col-6 fw-bold mb-4">
                                    Download speed
                                </div>
                                <div className="col-6 mb-4 text-end">
                                    {product.download}*
                                </div>
                                <div className="col-6 fw-bold mb-4">
                                    Upload speed
                                </div>
                                <div className="col-6 mb-4 text-end">
                                    {product.upload}*
                                </div>
                                <div className="col-12 text-muted">
                                    <p>* Estimated speed based on average speeds according to the Commerce Commission’s Measuring Broadband New Zealand (MBNZ) report.</p>
                                </div>
                            </div>
                        </div>
                        <hr />

                        <h3 className='text-dark mb-2'>Service Address</h3>

                        <Form onSubmit={submitHandler}>
                        <Row className='my-3 text-dark'>
                            <Form.Group controlId='address'>
                                <Form.Label className='text-dark mb-0 ms-1'>Street Address:</Form.Label>
                                <Form.Control type="text" value={address} onChange={(e) => setAddress(e.target.value)}></Form.Control>
                            </Form.Group>   
                            <Form.Group controlId='city'>
                                <Form.Label className='text-dark mt-2 mb-0 ms-1'>Suburb/City:</Form.Label>
                                <Form.Control type="text" value={city} onChange={(e) => setCity(e.target.value)}></Form.Control>
                            </Form.Group>
                            <Row className='m-0 p-0'>
                                <Col>
                                    <Form.Group controlId='postalCode'>
                                        <Form.Label className='text-dark mt-2 mb-0 ms-1'>Postcode:</Form.Label>
                                        <Form.Control type="text" value={postalCode} onChange={(e) => setPostalCode(e.target.value)}></Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId='region'>
                                        <Form.Label className='text-dark mt-2 mb-0 ms-1'>Region:</Form.Label>
                                        <Form.Select className='form-control text-dark' as='select' value={region} onChange={(e) => setRegion(e.target.value)}>
                                            {regionsList.map( (x, y) => <option key={y}>{x}</option>)}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Form.Group controlId='country'>
                                        <Form.Label className='text-dark mt-2 mb-0 ms-1'>Country:</Form.Label>
                                        <Form.Control type="text" value={country} onChange={(e) => setCountry(e.target.value)} disabled></Form.Control>
                                </Form.Group>
                            </Row>
                            
                        </Row>
                        <Button type='submit' className='btn btn-grad btn-sm px-3'>
                            Add Service Address
                        </Button>
                        </Form>

                    </div>
                </div>
    
                <div className="col-md-4 bg-transparent rounded border-primary p-3 ms-0 ms-md-2 mt-3 mt-lg-0">
                    <div className="p-3">
                       <h3 className="text-dark">Your Order</h3>
                       <p className="small mb-4">
                            Please note: This is only a request for services. A member of our team will finalise your order confirmation via phone or email.
                       </p>
                       <div className="mb-3">
                        
                        {serviceAddress.address ? (
                            <>
                                <h5 className="text-dark">Monthly:</h5>
                                <Row>
                                    <Col>{product.name}</Col>
                                    <Col className='text-end'>${product.price}</Col>
                                </Row>
                                <p className="mt-2">Service Address: <span className='italic'>{serviceAddress.address}, {serviceAddress.city} {serviceAddress.postalCode}<br />{serviceAddress.region}, {serviceAddress.country}</span></p>
                                <hr />
                                <h5 className="text-dark">Add-Ons</h5>
                                {cartHardwares.map( hardware => {
                                    return <Row>
                                        <Col>{hardware.name}</Col>
                                        <Col className='text-end'>${hardware.price}</Col>
                                    </Row>
                                })}
                                <hr />
                                <Link to={`/orderhardware`}>
                                    <Button className='btn btn-grad w-100' onClick={addServicesToCartHandler}>Next</Button>
                                </Link>
                                <Button className='btn btn-dark-opaque w-100 mt-2' onClick={() => navigate(-1)}>Go Back</Button>
                            </>
                            
                        ) : (
                            <>
                            <p className='fw-bold text-dark'>Cart is empty</p>
                            <button className='btn btn-grad w-100 mt-2' disabled>
                                Next
                            </button>
                            <button className="btn btn-dark-opaque w-100 mt-2" onClick={() => navigate(-1)}>
                                Go Back
                            </button>
                            </>
                        )
                        }
                       </div>
                    </div>
                </div>
            </div>

        </div>

    ) }

    
    <Footer />
    </>
  )
}

export default OrderServiceScreen