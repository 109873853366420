import { MAIL_URL } from "../constants.js";
import { apiSlice } from "./apiSlice.js";

export const mailApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        sendEnquiryConfirmEmail: builder.mutation({
            query: (data) => ({
                url: `${MAIL_URL}/confirm-enquiry`,
                method: 'POST',
                body: data,
            }),
        }),
        sendEnquiryDetailsEmail: builder.mutation({
            query: (data) => ({
                url: `${MAIL_URL}/send-enquiry`,
                method: 'POST',
                body: data,
            }),
        }),
        sendWelcomeEmail: builder.mutation({
            query: (data) => ({
                url: `${MAIL_URL}/welcome`,
                method: 'POST',
                body: data,
            }),
        }),
        sendNewUserRegistrationEmail: builder.mutation({
            query: (data) => ({
                url: `${MAIL_URL}/new-user`,
                method: 'POST',
                body: data,
            }),
        }),
        sendOrderConfirmationEmail: builder.mutation({
            query: (res) => ({
                url: `${MAIL_URL}/confirm-order`,
                method: 'POST',
                body: res,
            }),
        }),
        sendNewOrderEmail: builder.mutation({
            query: (res) => ({
                url: `${MAIL_URL}/order-submitted`,
                method: 'POST',
                body: res,
            }),
        }),
        orderProcessingEmail: builder.mutation({
            query: (data) => ({
                url: `/${MAIL_URL}/order-processing`,
                method: 'POST',
                body: data,
            }),
        }),
    }),
});

export const { useSendEnquiryConfirmEmailMutation, useSendEnquiryDetailsEmailMutation, useSendWelcomeEmailMutation, useSendNewUserRegistrationEmailMutation, useSendOrderConfirmationEmailMutation, useSendNewOrderEmailMutation, useOrderProcessingEmailMutation } = mailApiSlice;