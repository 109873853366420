import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useProfileMutation } from '../../slices/usersApiSlice.js';
import { setCredentials } from '../../slices/authSlice.js';
import Header from '../../components/Header.jsx'
import AdminBanner from '../../components/AdminBanner.jsx'
import Footer from '../../components/Footer.jsx'
import Loader from '../../components/Loader.jsx';
import AdminNav from '../../components/AdminNav.jsx';

const AdminDashboardScreen = () => {

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [editContact, setEditContact] = useState("false");
    const [editPassword, setEditPassword] = useState("false");

    const dispatch = useDispatch();

    const { userInfo } = useSelector((state) => state.auth);

    const [updateProfile, { isLoading:loadingUpdateProfile }] = useProfileMutation();

    useEffect(() => {
        if (userInfo) {
            setFirstName(userInfo.firstName);
            setLastName(userInfo.lastName);
            setEmail(userInfo.email);
            setPhone(userInfo.phone);
        }
    }, [userInfo, userInfo.firstName, userInfo.lastName, userInfo.email, userInfo.phone ]);

    const editContactHandler = () => {
        setEditContact('true');
    }

    const editPasswordHandler = () => {
        setEditPassword('true');
    }

    const updateHandler = async (e) => {
        e.preventDefault();
        setEditContact('false');
        setEditPassword('false');
        if (password !== confirmPassword) {
            toast.error('Passwords do not match');
        } else {
            try {
                const res = await updateProfile({ _id:userInfo._id, firstName, lastName, email, phone, password }).unwrap();
                dispatch(setCredentials(res));
                toast.success('Profile updated successfully');
            } catch (error) {
                toast.error(error?.data?.message || error.error);
            }
        }
    }


  return (
    <>
    
    <Header />
    <AdminBanner />

    <Container fluid>
        <Row className='justify-content-center my-5'>
            <Col lg="10">
                <div className="bg-transparent rounded border-primary p-4">

                    <Row>
                        
                        <AdminNav />

                        <Col lg="9" className='ps-4'>

                        <h2 className="text-dark">Personal Info</h2>
                        
                        <hr />

                        {editContact === 'false' && 
                            <Row>
                                <Col>
                                    <h6 className="text-dark mb-3">Contact Info</h6>
                                    <p className="m-0">{firstName} {lastName}</p>
                                    <p className="m-0">{email}</p>
                                    <p className="m-0">{phone}</p>
                                </Col>
                                <Col className='text-end pe-5'>
                                    <Link className='text-primary' onClick={editContactHandler}>Edit</Link>
                                </Col>
                            </Row>
                        }

                        {editContact === 'true' && 
                            <Form onSubmit={updateHandler}>
                                <Row>
                                    <Col>
                                        <Form.Group controlId='firstName' className='mb-2'>
                                        <Form.Label className='m-0'>First Name</Form.Label>
                                            <Form.Control type='name' value={firstName} onChange={(e) => setFirstName(e.target.value)}></Form.Control>
                                        </Form.Group>
                                        <Form.Group controlId='lastName' className='mb-2'>
                                            <Form.Label className='m-0'>Last Name</Form.Label>
                                            <Form.Control type='name' value={lastName} onChange={(e) => setLastName(e.target.value)}></Form.Control>
                                        </Form.Group>
                                        <Form.Group controlId='email' className='mb-2'>
                                            <Form.Label className='m-0'>Email</Form.Label>
                                            <Form.Control type='email' value={email} onChange={(e) => setEmail(e.target.value)}></Form.Control>
                                        </Form.Group>
                                        <Form.Group controlId='phone' className='mb-2'>
                                            <Form.Label className='m-0'>Phone</Form.Label>
                                            <Form.Control type='phone' value={phone} onChange={(e) => setPhone(e.target.value)}></Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Button type='submit' className='btn-sm'>Update</Button> 
                                { loadingUpdateProfile && <Loader />}
                            </Form>
                        }

                        <hr />

                        {editPassword === 'false' &&                     
                            <Row>
                                <Col>
                                    <h6 className="text-dark mb-3">
                                        Password
                                    </h6>
                                    <p className='m-0'>************</p>
                                </Col>
                                <Col className='text-end pe-5'>
                                    <Link className='text-primary' onClick={editPasswordHandler}>Edit</Link>
                                </Col>
                            </Row>
                        }

                        {editPassword === 'true' &&
                            <Form onSubmit={updateHandler}>
                                <Row>
                                    <Col>
                                        <Form.Group controlId='password' className='mb-2'>
                                            <Form.Label className='m-0'>Password</Form.Label>
                                            <Form.Control type='password' value={password} onChange={(e) => setPassword(e.target.value)}></Form.Control>
                                        </Form.Group>
                                        <Form.Group controlId='confirmPassword' className='mb-2'>
                                            <Form.Label className='m-0'>Confirm Password</Form.Label>
                                            <Form.Control type='password' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}></Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Button type='submit' className='btn-sm'>Update</Button>
                                { loadingUpdateProfile && <Loader />}
                            </Form>
                        }

                        </Col>
                    </Row>

                </div>
            </Col>
        </Row>
    </Container>
    
    <Footer />
    
    </>
  )
}

export default AdminDashboardScreen