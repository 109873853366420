import React from 'react'
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap'

const CheckoutSteps = ({ step1, step2, step3, step4 }) => {
  return (
    <>
    
    <Nav className='nav justify-content-center mb-4'>
        <div className="progress" style={{height: '32px'}}>
            <Nav.Item>
                { step1 ? (
                    <div className="progress-bar bg-primary" role='progressbar' >
                        <LinkContainer to='/cart'>
                            <Nav.Link className='text-white'>Cart</Nav.Link>
                        </LinkContainer>
                    </div>
                    
                    
                ) : (
                    <Nav.Link disabled>Cart</Nav.Link>
                ) }
            </Nav.Item>
            <Nav.Item>
                { step2 ? (
                    <div className="progress-bar bg-primary" role='progressbar'>

                    <LinkContainer to='/details'>
                        <Nav.Link className='text-white'>Sign In</Nav.Link>
                    </LinkContainer>
                    </div>
                    
                ) : (
                    <Nav.Link disabled>Sign In</Nav.Link>
                ) }
            </Nav.Item>
            <Nav.Item>
                { step3 ? (
                    <div className="progress-bar bg-primary" role='progressbar'>
                        <LinkContainer to='/placeorder'>
                            <Nav.Link className='text-white'>Shipping</Nav.Link>
                        </LinkContainer>
                    </div>
                ) : (
                    <Nav.Link disabled>Shipping</Nav.Link>
                ) }
            </Nav.Item>
            <Nav.Item>
                { step4 ? (
                    <div className="progress-bar bg-primary" role='progressbar'>
                        <LinkContainer to='/placeorder'>
                            <Nav.Link className='text-white'>Place Order</Nav.Link>
                        </LinkContainer>
                    </div>
                ) : (
                    <Nav.Link disabled>Place Order</Nav.Link>
                ) }
            </Nav.Item>
        </div>
    </Nav>

    </>
  )
}

export default CheckoutSteps