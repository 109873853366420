import { Helmet } from "react-helmet-async"

const Meta = ({ title, description, keywords }) => {
  return (
   <Helmet>
        <title>{title}</title>
        <meta name='description' content={description} />
        <meta name='keywords' content={keywords} />
   </Helmet>
  )
}

Meta.defaultProps = {
    title: 'Excite Broadband NZ',
    description: 'Excite Broadband is a cutting-edge residential internet service provider focused on delivering high-speed connectivity solutions to homes across New Zealand',
    keywords: 'broadband, internet, fibre, wireless'
}

export default Meta