import React, { useEffect, useState } from 'react'
import Header from '../../components/Header';
import AdminBanner from '../../components/AdminBanner';
import Footer from '../../components/Footer';
import { Col, Container, Form, Row, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetHardwareDetailsQuery, useUpdateHardwareMutation, useUploadHardwareImageMutation } from '../../slices/hardwaresApiSlice';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import { toast } from 'react-toastify';
import AdminNav from '../../components/AdminNav';
import { LinkContainer } from 'react-router-bootstrap';

const HardwareEditScreen = () => {

    const { id: hardwareId } = useParams();

    const [name, setName] = useState('');
    const [category, setCategory] = useState('');
    const [imagePath, setImagePath] = useState('');
    const [factsheetPath, setFactsheetPath] = useState('');
    const [price, setPrice] = useState(0);
    const [shortDescription, setShortDescription] = useState('');
    const [introductionInfo, setIntroductionInfo] = useState('');
    const [moreInfo, setMoreInfo] = useState('');
    const [tagline, setTagline] = useState('');
    const [hardwareFeatureName1, setHardwareFeatureName1] = useState('');
    const [hardwareFeatureName2, setHardwareFeatureName2] = useState('');
    const [hardwareFeatureName3, setHardwareFeatureName3] = useState('');
    const [hardwareFeatureName4, setHardwareFeatureName4] = useState('');
    const [hardwareFeatureInfo1, setHardwareFeatureInfo1] = useState('');
    const [hardwareFeatureInfo2, setHardwareFeatureInfo2] = useState('');
    const [hardwareFeatureInfo3, setHardwareFeatureInfo3] = useState('');
    const [hardwareFeatureInfo4, setHardwareFeatureInfo4] = useState('');
    const [countInStock, setCountInStock] = useState(0);

    const { data: hardware, isLoading, error } = useGetHardwareDetailsQuery(hardwareId);

    const [updateHardware, { isLoading: loadingUpdate }] = useUpdateHardwareMutation();

    const [uploadHardwareImage, { isLoading: loadingUpload }] = useUploadHardwareImageMutation();

    const navigate = useNavigate();

    useEffect(() => {
        if (hardware) {
            setName(hardware.name);
            setCategory(hardware.category);
            setImagePath(hardware.imagePath);
            setFactsheetPath(hardware.factsheetPath);
            setPrice(hardware.price);
            setShortDescription(hardware.shortDescription);
            setIntroductionInfo(hardware.introductionInfo);
            setMoreInfo(hardware.moreInfo);
            setTagline(hardware.tagline);
            setHardwareFeatureName1(hardware.hardwareFeatureName1);
            setHardwareFeatureInfo1(hardware.hardwareFeatureInfo1);
            setHardwareFeatureName2(hardware.hardwareFeatureName2);
            setHardwareFeatureInfo2(hardware.hardwareFeatureInfo2);
            setHardwareFeatureName3(hardware.hardwareFeatureName3);
            setHardwareFeatureInfo3(hardware.hardwareFeatureInfo3);
            setHardwareFeatureName4(hardware.hardwareFeatureName4);
            setHardwareFeatureInfo4(hardware.hardwareFeatureInfo4);
            setCountInStock(hardware.countInStock);
        }
    }, [hardware]);

    const submitHandler = async (e) => {
        e.preventDefault();
        const updatedHardware = {
            hardwareId,
            name,
            category,
            imagePath,
            factsheetPath,
            price,
            shortDescription,
            introductionInfo,
            moreInfo,
            tagline,
            hardwareFeatureName1,
            hardwareFeatureInfo1,
            hardwareFeatureName2,
            hardwareFeatureInfo2,
            hardwareFeatureName3,
            hardwareFeatureInfo3,
            hardwareFeatureName4,
            hardwareFeatureInfo4,
            countInStock,
        };

        const result = await updateHardware(updatedHardware);
        if (result.error) {
            toast.error(result.error);
        } else {
            toast.success('Hardware updated');
            navigate(`/admin/hardware/${hardwareId}/info`);
        }
    };

    const uploadFileHandler = async (e) => {
        const formData = new FormData();
        formData.append('imagePath', e.target.files[0]);
        console.log(e.target.files[0]);
        console.log(formData)
        try {
            const res = await uploadHardwareImage(formData).unwrap();
            toast.success(res.message);
            setImagePath(res.imagePath);
        } catch (error) {
            toast.error(error?.data?.message || error.error );
        }
    }

  return (
    <>

    <Header />
    <AdminBanner />

    <Container fluid>
        <Row className='justify-content-center my-5'>
            <Col lg='10'>
                <div className="bg-transparent rounded border-primary p-4">
                    <Row>
                        <AdminNav />
                        
                        <Col lg='9' className='ps-4'>

                            {loadingUpdate && <Loader />}
                            {loadingUpload && <Loader />}

                            { isLoading ? ( <Loader /> ) : error ? ( <Message variant='danger'>{error?.data?.message || error.error}</Message>) : (
                                <>
                                <Row>
                                    <Col>
                                        <h2 className="text-dark mb-0">{hardware.name}</h2>
                                        <p className='fs-xs italic m-0'>Last Updated: {hardware.updatedAt.substring(0,10)}</p>
                                    </Col>
                                    <Col className='text-end'>
                                    <LinkContainer to={`/admin/hardware/${hardware._id}/info`}>
                                        <Button className='btn btn-primary btn-sm'>
                                            Back
                                        </Button>
                                    </LinkContainer>
                                </Col>
                                </Row>

                                <hr />

                                <Form onSubmit={submitHandler}>

                                    <Form.Group controlId='name'>
                                        <Form.Label className='m-0 mt-3 text-dark ps-1'>Hardware Name:</Form.Label>
                                        <Form.Control type='name' placeholder='Enter Name' value={name} onChange={(e) => setName(e.target.value)}></Form.Control>
                                    </Form.Group>

                                    <Row className='mt-0'>
                                            <Col className='mt-0'>
                                                <Form.Group controlId='category'>
                                                    <Form.Label className='m-0 mt-2 text-dark ps-1'>Category:</Form.Label>
                                                    <Form.Control type='text' placeholder='Enter category' value={category} onChange={(e) => setCategory(e.target.value)}></Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col className='mt-0'>
                                                <Form.Group controlId='price'>
                                                    <Form.Label className='m-0 mt-2 text-dark ps-1'>Price</Form.Label>
                                                    <Form.Control type='number' placeholder='Enter price here' value={price} onChange={(e) => setPrice(e.target.value)}></Form.Control>
                                                </Form.Group> 
                                            </Col>
                                    </Row>

                                    <Form.Group controlId='shortDescription'>
                                        <Form.Label className='m-0 mt-2 text-dark ps-1'>Short Description:</Form.Label>
                                        <Form.Control type='text' as="textarea" value={shortDescription} onChange={(e) => setShortDescription(e.target.value)}></Form.Control>
                                    </Form.Group>

                                    <Form.Group controlId='tagline'>
                                        <Form.Label className='m-0 mt-2 text-dark ps-1'>Tagline:</Form.Label>
                                        <Form.Control type='text' value={tagline} onChange={(e) => setTagline(e.target.value)}></Form.Control>
                                    </Form.Group> 

                                    <Form.Group controlId='countInStock'>
                                        <Form.Label className='m-0 mt-2 text-dark ps-1'>Count In Stock</Form.Label>
                                        <Form.Control type='number' placeholder='Enter count in stock' value={countInStock} onChange={(e) => setCountInStock(e.target.value)}></Form.Control>
                                    </Form.Group>  

                                    <Form.Group controlId='factsheetPath'>
                                        <Form.Label className='m-0 mt-2 text-dark ps-1'>Factsheet Path</Form.Label>
                                        <Form.Control type='text' placeholder='Enter path' value={factsheetPath} onChange={(e) => setFactsheetPath(e.target.value)}></Form.Control>
                                    </Form.Group>

                                    <hr className='my-4' />

                                    <Row className='mb-1'>
                                        <Col>
                                        <h5 className="text-dark">Hardware Description</h5>
                                        </Col>
                                    </Row>

                                    <Form.Group controlId='introductionInfo'>
                                        <Form.Label className='m-0 mt-2 text-dark ps-1'>Hardware Information:</Form.Label>
                                        <Form.Control type='text' as="textarea" value={introductionInfo} onChange={(e) => setIntroductionInfo(e.target.value)}></Form.Control>
                                    </Form.Group>

                                    <Form.Group controlId='moreInfo'>
                                        <Form.Label className='m-0 mt-2 text-dark ps-1'>Extra Information:</Form.Label>
                                        <Form.Control type='text' as="textarea" value={moreInfo} onChange={(e) => setMoreInfo(e.target.value)}></Form.Control>
                                    </Form.Group>

                                    <hr className='my-4' />

                                    <Row className='mb-1'>
                                        <Col>
                                        <h5 className="text-dark">Hardware Features</h5>
                                        </Col>
                                    </Row>

                                    <Row className='mb-2'>
                                        <Col>
                                            <Row>
                                                <Form.Group controlId='hardwareFeatureName1'>
                                                    <Form.Label className='m-0 mt-2 text-dark ps-1'>Feature:</Form.Label>
                                                    <Form.Control type='text' value={hardwareFeatureName1} onChange={(e) => setHardwareFeatureName1(e.target.value)}></Form.Control>
                                                </Form.Group>
                                                <Form.Group controlId='hardwareFeatureInfo1'>
                                                    <Form.Label className='m-0 mt-2 text-dark ps-1'>Description:</Form.Label>
                                                    <Form.Control type='text' as="textarea" value={hardwareFeatureInfo1} onChange={(e) => setHardwareFeatureInfo1(e.target.value)}></Form.Control>
                                                </Form.Group>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Row>
                                                <Form.Group controlId='hardwareFeatureName2'>
                                                    <Form.Label className='m-0 mt-2 text-dark ps-1'>Feature:</Form.Label>
                                                    <Form.Control type='text' value={hardwareFeatureName2} onChange={(e) => setHardwareFeatureName2(e.target.value)}></Form.Control>
                                                </Form.Group>
                                                <Form.Group controlId='hardwareFeatureInfo2'>
                                                    <Form.Label className='m-0 mt-2 text-dark ps-1'>Description:</Form.Label>
                                                    <Form.Control type='text' as="textarea" value={hardwareFeatureInfo2} onChange={(e) => setHardwareFeatureInfo2(e.target.value)}></Form.Control>
                                                </Form.Group>
                                            </Row>
                                        </Col>
                                    </Row>

                                    <Row className='mb-1'>
                                        <Col>
                                            <Row>
                                                <Form.Group controlId='hardwareFeatureName3'>
                                                    <Form.Label className='m-0 mt-2 text-dark ps-1'>Feature:</Form.Label>
                                                    <Form.Control type='text' value={hardwareFeatureName3} onChange={(e) => setHardwareFeatureName3(e.target.value)}></Form.Control>
                                                </Form.Group>
                                                <Form.Group controlId='hardwareFeatureInfo3'>
                                                    <Form.Label className='m-0 mt-2 text-dark ps-1'>Description:</Form.Label>
                                                    <Form.Control type='text' as="textarea" value={hardwareFeatureInfo3} onChange={(e) => setHardwareFeatureInfo3(e.target.value)}></Form.Control>
                                                </Form.Group>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Row>
                                                <Form.Group controlId='hardwareFeatureName4'>
                                                    <Form.Label className='m-0 mt-2 text-dark ps-1'>Feature:</Form.Label>
                                                    <Form.Control type='text' value={hardwareFeatureName4} onChange={(e) => setHardwareFeatureName4(e.target.value)}></Form.Control>
                                                </Form.Group>
                                                <Form.Group controlId='hardwareFeatureInfo4'>
                                                    <Form.Label className='m-0 mt-2 text-dark ps-1'>Description:</Form.Label>
                                                    <Form.Control type='text' as="textarea" value={hardwareFeatureInfo4} onChange={(e) => setHardwareFeatureInfo4(e.target.value)}></Form.Control>
                                                </Form.Group>
                                            </Row>
                                        </Col>
                                    </Row>

                                    <hr className='my-4' />

                                    <Row className='mb-1'>
                                        <Col>
                                        <h5 className="text-dark">Upload Hardware Image</h5>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Form.Group controlId='image'>
                                            <Form.Label className='m-0 mt-2 text-dark ps-1'>Image Name:</Form.Label>
                                            <Form.Control type="text" placeholder="Enter image URL" value={imagePath} onChange={(e) => setImagePath}></Form.Control>
                                            <Form.Label className='m-0 mt-2 text-dark ps-1'>Upload a new image:</Form.Label>
                                            <Form.Control type="file" onChange={uploadFileHandler}></Form.Control>
                                        </Form.Group>
                                    </Row>

                                    <Button type='submit' className='btn btn-sm mt-3'>Update Hardware</Button>           

                                </Form>
                            </>
                            )}


                        </Col>

                    </Row>
                </div>
            </Col>
        </Row>
    </Container>

    <Footer />
    
    </>
  )
}

export default HardwareEditScreen