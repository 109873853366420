import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux';
import store from './store.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/index.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga4';
import HomeScreen from './screens/HomeScreen.jsx';
import FibreScreen from './screens/FibreScreen.jsx';
import WirelessScreen from './screens/WirelessScreen.jsx';
import HyperfibreScreen from './screens/HyperfibreScreen.jsx';
import PlansScreen from './screens/PlansScreen.jsx';
import HardwareScreen from './screens/HardwareScreen.jsx';
import HardwareDetailsScreen from './screens/HardwareDetailsScreen.jsx';
import AddHardwareScreen from './screens/AddHardwareScreen.jsx';
import CartScreen from './screens/CartScreen.jsx';
import LoginScreen from './screens/LoginScreen.jsx';
import RegisterScreen from './screens/RegisterScreen';
import ShippingScreen from './screens/ShippingScreen';
import PrivateRoute from './components/PrivateRoute.jsx';
import PlaceOrderScreen from './screens/PlaceOrderScreen.jsx';
import OrderScreen from './screens/OrderScreen.jsx';
import ProfileScreen from './screens/ProfileScreen.jsx';
import AdminRoute from './components/AdminRoute.jsx';
import AdminDashboardScreen from './screens/admin/AdminDashboardScreen.jsx';
import ServiceEditScreen from './screens/admin/ServiceEditScreen.jsx';
import HardwareEditScreen from './screens/admin/HardwareEditScreen.jsx';
import UserEditScreen from './screens/admin/UserEditScreen.jsx';
import UserInfoScreen from './screens/admin/UserInfoScreen.jsx';
import ServiceInfoScreen from './screens/admin/ServiceInfoScreen.jsx';
import HardwareInfoScreen from './screens/admin/HardwareInfoScreen.jsx';
import ServiceListScreen from './screens/admin/ServicesListScreen.jsx';
import HardwareListScreen from './screens/admin/HardwareListScreen.jsx';
import OrderListScreen from './screens/admin/OrderListScreen.jsx';
import UserListScreen from './screens/admin/UserListScreen.jsx';
import OrderServiceScreen from './screens/OrderServiceScreen.jsx';
import ServiceDetailsScreen from './screens/ServiceDetailsScreen.jsx';
import AboutScreen from './screens/AboutScreen.jsx';
import ContactScreen from './screens/ContactScreen.jsx';
import PasswordResetScreen from './screens/PasswordResetScreen.jsx';
import ForgotPasswordScreen from './screens/ForgotPasswordScreen.jsx';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index={true} path="/" element={<HomeScreen />} />
      <Route path="/fibre" element={<FibreScreen />} />
      <Route path="/wireless" element={<WirelessScreen />} />
      <Route path="/hyperfibre" element={<HyperfibreScreen />} />
      <Route path="/fibre/plans" element={<PlansScreen />} />
      <Route path="/wireless/plans" element={<PlansScreen />} />
      <Route path="/hyperfibre/plans" element={<PlansScreen />} />
      <Route path="/product/:id" element={<ServiceDetailsScreen />} />
      <Route path="/hardware" element={<HardwareScreen />} />
      <Route path="/hardware/:id" element={<HardwareDetailsScreen />} />
      <Route path="/product/:id/order" element={<OrderServiceScreen />} />
      <Route path="/orderhardware" element={<AddHardwareScreen />} />
      <Route path="/cart" element={<CartScreen />} />
      <Route path="/login" element={<LoginScreen />} />
      <Route path="/register" element={<RegisterScreen />} />
      <Route path="/about" element={<AboutScreen />} />
      <Route path="/contact" element={<ContactScreen />} />
      <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
      <Route path="/reset-pw" element={<PasswordResetScreen />} />

      <Route path="" element={<PrivateRoute />}>
        <Route path="/details" element={<ShippingScreen />} />
        <Route path="/placeorder" element={<PlaceOrderScreen />} />
        <Route path="/order/:id" element={<OrderScreen />} />
        <Route path="/profile" element={<ProfileScreen />} />
      </Route>

      <Route path="" element={<AdminRoute />}>
        <Route path="/admin" element={<AdminDashboardScreen />} />
        <Route path="/admin/orderlist" element={<OrderListScreen />} />
        <Route path="/admin/servicelist" element={<ServiceListScreen />} />
        <Route path="/admin/service/:id/info" element={<ServiceInfoScreen />} />
        <Route path="/admin/service/:id/edit" element={<ServiceEditScreen />} />
        <Route path="/admin/hardwarelist" element={<HardwareListScreen />} />
        <Route path="/admin/hardware/:id/info" element={<HardwareInfoScreen />} />
        <Route path="/admin/hardware/:id/edit" element={<HardwareEditScreen />} />
        <Route path="/admin/userlist" element={<UserListScreen />} />
        <Route path="/admin/user/:id/info" element={<UserInfoScreen />} />
        <Route path="/admin/user/:id/edit" element={<UserEditScreen />} />
      </Route>

    </Route>
  )
)

ReactGA.initialize("G-916099MCNC");

ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </HelmetProvider>
  </React.StrictMode>
);

reportWebVitals();
