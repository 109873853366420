import React from 'react'
import Header from '../../components/Header'
import AdminBanner from '../../components/AdminBanner'
import Footer from '../../components/Footer'
import { Button, Col, Container, Row } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import AdminNav from '../../components/AdminNav';
import { useCreateHardwareMutation, useDeleteHardwareMutation, useGetHardwaresQuery } from '../../slices/hardwaresApiSlice';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";

const HardwareListScreen = () => {

    const { data: hardwares, isLoading, error, refetch } = useGetHardwaresQuery();

    const [createHardware, { isLoading: loadingCreate }] = useCreateHardwareMutation();
    const [deleteHardware, { isLoading: loadingDelete }] = useDeleteHardwareMutation();

    const createHardwareHandler = async () => {
        try {
            await createHardware();
            toast.success('Hardware created successfully');
            refetch();
        } catch (error) {
            toast.error(error?.data?.message || error.error);
        };
    };

    const deleteHandler = async (id) => {
        if (window.confirm('Are you sure you want to delete this hardware?')) {
            try {
                await deleteHardware(id);
                toast.success('Product sucessfully deleted');
                refetch();
            } catch (error) {
                toast.error(error?.data?.message || error.error)
            };
        } 
    };

  return (
    <>

    <Header />
    <AdminBanner />

    <Container fluid>
        <Row className='justify-content-center my-5'>
            <Col lg="10">
                <div className="bg-transparent rounded border-primary p-4">
                    <Row>
                        <AdminNav />
                        <Col lg='9' className='ps-4'>

                            <Row>
                                <Col>
                                    <h2 className="text-dark">All Hardwares</h2>
                                </Col>
                                <Col className='text-end'>
                                    <Button className='btn btn-primary btn-sm' onClick={ createHardwareHandler }>Add New Hardware</Button>
                                </Col>
                            </Row>

                            <hr />

                            {loadingCreate && <Loader />}
                            {loadingDelete && <Loader />}

                            { isLoading? ( <Loader /> ) : error ? ( <Message variant='danger'>{ error?.data?.message || error.error }</Message>) : (

                                <table>
                                <thead className='border-bottom text-dark'>
                                    <tr>
                                        <th scope='col' className='fs-sm fw-normal col-4'>
                                            Name
                                        </th>
                                        <th scope='col' className='fs-sm fw-normal col-2'>
                                            Category
                                        </th>
                                        <th scope='col' className='fs-sm fw-normal col-2'>
                                            Price
                                        </th>
                                        <th scope='col' className='fs-sm fw-normal col-3'>
                                            Stock
                                        </th>
                                        <th scope='col' className='fs-sm fw-normal col-1 pe-1'>
                                            Edit
                                        </th>
                                        <th scope='col' className='fs-sm fw-normal col-1 pe-1'>
                                            Delete
                                        </th>
                                    </tr>
                                </thead>

                                <tbody className='align-middle fs-xs'>

                                {hardwares.map((hardware) => (
                                    <tr key={hardware._id} className='border-bottom highlight'>
                                        <td className="py-2 ps-1">
                                            <LinkContainer to={`/admin/hardware/${hardware._id}/info`} className='cursor-pointer'>
                                                <span className="hover-underline">{hardware.name}</span>
                                            </LinkContainer>
                                        </td>
                                        <td className="text-capitalize ps-1">
                                            {hardware.category}
                                        </td>
                                        <td className='ps-1'>
                                            ${hardware.price}
                                        </td>
                                        <td className='ps-3'>{hardware.countInStock}</td>
                                        <td className="ps-2 text-primary">
                                            <LinkContainer to={`/admin/hardware/${hardware._id}/edit`} className='cursor-pointer'>
                                                <FiEdit />
                                            </LinkContainer>
                                        </td>
                                        <td>
                                            <Button className='btn btn-sm bg-transparent text-dark text-center ps-3' onClick={ () => deleteHandler(hardware._id)}>
                                                <MdDelete />
                                            </Button>
                                        </td>
                                    </tr>
                                ))}

                                </tbody>
                                </table>

                            )}

                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    </Container>

    <Footer />
    </>
  )
}

export default HardwareListScreen