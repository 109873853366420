import React from 'react'
import { useLocation } from 'react-router-dom';
import Header from '../components/Header.jsx'
import PlansBanner from '../components/PlansBanner.jsx'
import Product from '../components/Product.jsx';
import { useGetProductsQuery } from '../slices/productsApiSlice.js';
import Loader from '../components/Loader.jsx';
import Message from '../components/Message.jsx';
import Footer from '../components/Footer.jsx';
import Meta from '../components/Meta.jsx';

const PlansScreen = (  ) => {

    const location = useLocation();
    const { category } = location.state;

    const { data: products, isLoading, error } = useGetProductsQuery();

  return (
    <>
    <Header />
    <PlansBanner category={category} />

    <section className='my-2 py-4'>
        <div className="container text-center text-dark">
            <h2>Choose the perfect {category} plan for your needs</h2>
            <p className="text-muted">
                Our range of plans cater to all types of users, from casual browsers to power users. All plans come with unlimited data, no fixed term contracts, and a NZ-based customer support team.
            </p>
        </div>
    </section>

    { isLoading ? (
        <Loader />
    ) : error ? (
        <Message variant='danger'>{error?.data?.message || error.error}</Message>
    ) :     
            <>
            <Meta title={`Excite Plans`} />
            <section>
                    
            {category === 'fibre' &&
            
            <div className="container my-3">

                {products.map( product => {
                    if (product.category === 'fibre') {
                        return <Product product={product} />
                    } else {
                    return null
                    }

                })}

            </div>
            
            }

            {category === 'wireless' &&
            
            <div className="container my-3 mb-2">

                {products.map( product => {
                    if (product.category === 'wireless') {
                        return <Product product={product} />
                    } else {
                        return null
                    }

                })}

            </div>
            
            }   

            {category === 'hyperfibre' &&
            
            <div className="container my-3 mb-2">

                {products.map( product => {
                    if (product.category === 'hyperfibre') {
                        return <Product product={product} />
                    } else {
                        return null
                    }

                })}

            </div>
            
            }               

        </section>
        </>
    }
    <Footer />
    </>
  )
}

export default PlansScreen