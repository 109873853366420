import React from 'react'
import Loader from './Loader.jsx';
import Message from './Message.jsx';
import Product from './Product.jsx';
import { useGetProductsQuery } from '../slices/productsApiSlice.js';

const PlanMenu = () => {

    const { data: products, isLoading, error } = useGetProductsQuery();


  return (
    <>
    <section>
        <div className="container-lg py-3">
        
        <h2 className="text-dark text-center mt-3">Plans & Pricing</h2>

        <div className="row justify-content-center">
            <div className="col-sm-12 col-lg-12">
                <ul className="nav nav-tabs nav-justified" id="planTabs" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="fibreTab" data-bs-toggle="pill" data-bs-target="#pillsFibre" type="button" role="tab" aria-controls="pillsFibre" aria-selected="true">Fibre</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="wirelessTab" data-bs-toggle="pill" data-bs-target="#pillsWireless" type='button' role='tab' aria-controls='pillsWireless' aria-selected='false'>Wireless</button>
                    </li>
                </ul>

                { isLoading ? (
                    <Loader />
                ) : error ? (
                    <Message variant='danger'>{error?.data?.message || error.error}</Message>
                ) : (
                    <>
                    <div className="tab-content" id='plans-tabContent'>
                    <div className="tab-pane fade show active" id="pillsFibre" role='tabpanel' aria-labelledby='fibreTab'>
                        
                        <div className="container-lg my-5">

                            {products.map( product => {
                                if (product.category === 'fibre' || product.category === 'hyperfibre') {
                                    return <Product key={product._id} product={product} />
                                } else {
                                    return null
                                }
                            })}
                        </div>

                    </div>
                    <div className="tab-pane fade" id="pillsWireless" role='tabpanel' aria-labelledby='pillsWireless'>
                        
                        <div className="container my-5">
                                {products.map( product => {
                                    if (product.category === 'wireless') {
                                        return <Product key={product._id} product={product} />
                                    } else {
                                        return null
                                    }
                                })}
                        </div>

                    </div>
                </div>
                </>
                ) }

                
            </div>
        </div>

        </div>
    </section>
    </>
  )
}

export default PlanMenu