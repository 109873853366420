import { useParams } from "react-router-dom"
import { Row, Col, Container, Button } from 'react-bootstrap';
import Header from '../components/Header.jsx'
import { useGetProductDetailsQuery } from '../slices/productsApiSlice.js';
import Loader from '../components/Loader.jsx';
import Message from '../components/Message.jsx';
import Footer from '../components/Footer.jsx';
import ServiceDetailsBanner from '../components/ServiceDetailsBanner';
import { FaCheck } from "react-icons/fa";
import { LinkContainer } from "react-router-bootstrap";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { CiRouter } from "react-icons/ci";
import { TbMobiledata } from "react-icons/tb";
import Meta from "../components/Meta.jsx";

const ServiceDetailsScreen = () => {

    const { id: productId } = useParams();
    const { data: product, isLoading, error } = useGetProductDetailsQuery(productId);

  return (

    <>
    <Header />
    <ServiceDetailsBanner />

    { isLoading ? (
       <Loader />
    ) : error ? (
        <Message variant='danger'>{error?.data?.message || error.error}</Message>
    ) : (
        <>
        <Meta title={product.name} />
        <section>
            <Container className="py-5">
                <Row>
                    <Col lg='8'>
                    
                    <h2 className="text-dark mt-3">{product.name}</h2>
                    <ul className="nav nav-tabs" id="serviceTabs" role="tablist">
                        <li className="nav-item" role='presentation'>
                            <button className="nav-link active" id="overviewTab" data-bs-toggle="pill" data-bs-target="#pillsOverview" type="button" role='tab' aria-controls='pillsOverview' aria-selected="true">Overview</button>
                        </li>
                        <li className="nav-item" role='presentation'>
                            <button className="nav-link" id="offerSummaryTab" data-bs-toggle="pill" data-bs-target="#pillsOfferSummary" type='button' role='tab' aria-controls='pillsOfferSummary' aria-selected="false">Offer Summary</button>
                        </li>
                    </ul>

                    <div className="tab-content" id="service-tabContent">
                        <div className="tab-pane fade show active" id="pillsOverview" role="tabpanel" aria-labelledby="overviewTab">
                            <section className="container pb-5 mb-1 mb-sm-2 mb-md-3 mb-lg-4 mb-xl-5 pt-4 text-dark">
                                <Row>
                                    <Col>
                                        <p className='text-capitalize m-0'>
                                        <span className='fw-bold text-dark'>Download speed: </span>
                                        {product.download}
                                        </p>
                                        <p className='text-capitalize m-0'>
                                            <span className='fw-bold text-dark'>Upload speed: </span>
                                            {product.upload}
                                        </p>
                                        <p className='text-capitalize m-0'>
                                            <span className='fw-bold text-dark'>Data Cap: </span>
                                            {product.dataCap}
                                        </p>
                                        <p className='text-capitalize m-0 mb-3'>
                                            <span className='fw-bold text-dark'>Term: </span>
                                            {product.term}
                                        </p>
                                        <p className="mb-3">
                                            {product.productOverview}
                                        </p>

                                        <li className='list-unstyled'>
                                            <p className='m-0 mt-1 d-flex'>
                                                <span className='fw-bold text-dark pe-2'><FaCheck /></span>
                                                {product.feature1}
                                            </p>
                                        </li>
                                        <li className='list-unstyled'>
                                            <p className='m-0 mt-1 d-flex'>
                                                <span className='fw-bold text-dark pe-2'><FaCheck /></span>
                                                {product.feature2}
                                            </p>
                                        </li>
                                        <li className='list-unstyled'>
                                            <p className='m-0 mt-1 d-flex'>
                                                <span className='fw-bold text-dark pe-2'><FaCheck /></span>
                                                {product.feature3}
                                            </p>
                                        </li>
                                        <p className='m-0 mt-3'>
                                            <span className='fw-bold text-dark'>Modem Requirements <br /> </span>
                                            {product.modemReqs}
                                        </p>
                                        
                                    </Col> 
                                </Row>
                            </section>
                        </div>
                        <div className="tab-pane fade" id='pillsOfferSummary' role="tabpanel" aria-labelledby="offerSummaryTab">
                            <section className="container pb-5 mb-1 mb-sm-2 mb-md-3 mb-lg-4 mb-xl-5 pt-4 text-dark">
                            <a href={require(`../assets${product.offerSummaryPath}`)}>{product.name} Offer Summary</a>
                            </section>
                        </div>
                    </div>
                    
                    </Col>
                    <Col lg='4'>
                        <div className="bg-transparent rounded border-primary p-4">
                            <h5 className="text-dark">{product.name}</h5>
                            <hr />
                            <p className="text-muted">{product.description}</p>
                            <li className="list-unstyled">
                                <h5 className="m-0 mt-1 d-flex text-dark align-items-center">
                                    <span className="fw-bold text-primary pe-2 h3"><FaArrowDown /></span>
                                    {product.download} download speed
                                </h5>
                            </li>
                            <li className="list-unstyled">
                                <h5 className="m-0 mt-1 d-flex text-dark align-items-center">
                                    <span className="fw-bold text-primary pe-2 h3"><FaArrowUp /></span>
                                    {product.upload} upload speed
                                </h5>
                            </li>
                            <hr />
                            <li className="list-unstyled">
                                <h5 className="m-0 mt-1 d-flex text-dark align-items-center">
                                    <span className="fw-bold text-primary pe-2 h3"><CiRouter /></span>
                                    {product.equipment}
                                </h5>
                            </li>
                            <li className="list-unstyled">
                                <h5 className="m-0 mt-1 d-flex text-dark align-items-center">
                                    <span className="fw-bold text-primary pe-2 h3"><TbMobiledata /></span>
                                    {product.dataCap} Data
                                </h5>
                            </li>
                            <hr />
                            <div className="text-center">
                                <h3 className="m-0 mt-1 text-dark text-center">
                                    ${product.price} per month
                                </h3>
                                <LinkContainer to={`/product/${product._id}/order`}>
                                    <Button className="btn btn-grad mt-4 ">Order Now</Button>
                                </LinkContainer> 
                            </div>
                               
                        </div>
                    </Col>
                </Row>
                

            </Container>
        </section>
        </>
    ) }

    
    <Footer />
    </>
  )
}

export default ServiceDetailsScreen