import React from 'react'
import stepsImg from '../assets/images/steps.png';

const ConnectionSteps = () => {
  return (
    <>

    <section className="mt-5 pb-3">
        <div className="container my-2">
            <div className="row g-0 justify-content-between align-items-center">
                <div className="col-md-4">
                    <h1 className="fw-bold text-dark">
                        Connecting with Excite is a <span className='italic text-grad'>breeze</span> - Follow the simple steps to get connected
                    </h1>
                </div>
                <div className="col-md-8">
                    <img className='std-img' src={stepsImg} alt="" />
                </div>
            </div>
        </div>


        <div className="container pt-4 mt-5 process-advance">
            <div className="row">
                <div className="col-sm-6 col-lg-3 text-center mb-4">
                    <div className="process-border">
                        <span className="process-number bg-grad border-end">
                            01
                        </span>
                    </div>
                    <h5 className="my-3 text-dark">
                        Choose A Plan
                    </h5>
                    <p className='text-muted'>Browse our range of broadband plans tailored to suit various needs and budgets. Consider factors like speed, data allowance, and any additional features to find your ideal match. </p>
                </div>
                <div className="col-sm-6 col-lg-3 text-center mb-4">
                    <div className="process-border">
                        <span className="process-number bg-grad border-start border-end">
                            02
                        </span>
                    </div>
                    <h5 className="my-3 text-dark">
                        Place Your Order
                    </h5>
                    <p className='text-muted'>Our user-friendly customer interface makes order a connection  quick and hassle-free, ensuring you can complete your order in just a few minutes.</p>
                </div>
                <div className="col-sm-6 col-lg-3 text-center mb-4 t">
                    <div className="process-border">
                        <span className="process-number bg-grad border-start border-end">
                            03
                        </span>
                    </div>
                    <h5 className="my-3 text-dark">
                        Confirm & Finalise
                    </h5>
                    <p className='text-muted'>After placing your order, a member of our customer service team will review the information, send you a confirmation email and prepare your connection and equipment.</p>
                </div>
                <div className="col-sm-6 col-lg-3 text-center mb-4">
                    <div className="process-border">
                        <span className="process-number bg-grad border-start">
                            04
                        </span>
                    </div>
                    <h5 className="my-3 text-dark">
                        Plug In & Connect
                    </h5>
                    <p className='text-muted'>On your connection day, simply plug in your equipment and you'll be enjoying Excite Broadband in minutes!</p>
                </div>
            </div>
        </div>
    </section>
        
    </>
  )
}

export default ConnectionSteps