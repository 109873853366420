import React from 'react'
import { useNavigate, Link } from 'react-router-dom';
import { Container, Nav, Navbar, NavLink, Button, Dropdown, NavItem } from 'react-bootstrap';
import { FiShoppingCart } from "react-icons/fi";
import { FaUser } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { useLogoutMutation } from '../slices/usersApiSlice.js';
import { logout } from '../slices/authSlice.js';
import logo from '../assets/images/excite-white-logo-png.png';
import { resetCart } from '../slices/cartSlice.js';

const Header = () => {

  const { cartServices, cartHardwares } = useSelector((state) => state.cart);
  const { userInfo } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [logoutApiCall] = useLogoutMutation();

  const logoutHandler = async () => {
    try {
      await logoutApiCall().unwrap();
      dispatch(logout());
      dispatch(resetCart());
      navigate('/');
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <Navbar collapseOnSelect expand="lg" className='bg-body-tertiary navbar-transparent'>
        <Container fluid>
          <Navbar.Brand className='text-white navbar-brand'>
            <Nav.Link as={Link} to="/">
            <img src={logo} alt="Logo" className="navbar-brand-item" />
            excite
            </Nav.Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" className='text-white'/>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className='me-auto pt-1'>
            <Nav.Link as={Link} to="/fibre" className='header-link'>
                Fibre
            </Nav.Link>
            <Nav.Link as={Link} to="/wireless" className='header-link'>
                Wireless
            </Nav.Link>
            <Nav.Link as={Link} to="/hyperfibre" className='header-link'>
                HyperFibre
            </Nav.Link>
            <Nav.Link as={Link} to="/hardware" className='header-link'>
                Hardware
            </Nav.Link>
            <Nav.Link as={Link} to="/about" className='header-link'>
                About
            </Nav.Link>
            <Nav.Link as={Link} to="/contact" className='header-link'>
                Contact
            </Nav.Link>
            </Nav>
            <Nav id='userDropdown'>
              {userInfo ? (
                <Dropdown as={NavItem} className='dropdown mt-2'>
                  <Dropdown.Toggle as={NavLink} className='dropdown-toggle'>{userInfo.firstName} {userInfo.lastName}</Dropdown.Toggle>
                  {userInfo.isAdmin ? (
                    <Dropdown.Menu className='dropdown-menu'>
                    <Dropdown.Item className='dropdown-item' as={Link} to="/admin">
                      Dashboard
                    </Dropdown.Item>
                    <Dropdown.Item className='dropdown-item' as={Link} to="/admin/orderlist">
                      Orders
                    </Dropdown.Item>
                    <Dropdown.Item className='dropdown-item' as={Link} to="/admin/servicelist">
                      Services
                    </Dropdown.Item>
                    <Dropdown.Item className='dropdown-item' as={Link} to="/admin/hardwarelist">
                      Hardwares
                    </Dropdown.Item>
                    <Dropdown.Item className='dropdown-item' as={Link} to="/admin/userlist">
                      Users
                    </Dropdown.Item>
                    <Dropdown.Item className='dropdown-item' onClick={logoutHandler}>
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                  ) : (
                    <Dropdown.Menu className='dropdown-menu'>
                    <Dropdown.Item className='dropdown-item' as={Link} to="/profile">
                      Profile
                    </Dropdown.Item>
                    <Dropdown.Item className='dropdown-item' onClick={logoutHandler}>
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                  )}
                </Dropdown>
              ) : (
                <Nav.Link as={Link} to="/login" className='header-link'>
                  <Button className='btn btn-white-outline btn-sm mb-1'>
                    <FaUser className='me-2' />
                    Sign In
                  </Button>
                </Nav.Link>
              ) }
              
              <Nav.Link as={Link} to="/cart" className='header-link'>
                <Button className='btn btn-white-outline btn-sm mb-1'>
                  <FiShoppingCart className='me-2' />
                  Cart
                  {
                  cartServices.length > 0 && (
                    <span className='ms-1'>
                       ({ cartServices.reduce((a, c) => a + c.qty, 0) + cartHardwares.reduce((a, c) => a + c.qty, 0)})
                    </span>
                  )
                  }
                </Button>
              </Nav.Link>
              
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>


    </>
        
  )
}

export default Header