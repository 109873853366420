import React from 'react'
import { } from "react-bootstrap"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { Outlet } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop.jsx';

const App = () => {



  return (
    <>
    
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.6.0/css/all.min.css" integrity="sha512-Kc323vGBEqzTmouAECnVceyQqyqdsSiqLQISBL29aUW4U/M7pSPA/gEUZQqv1cwx4OnYxTxve5UMg5GT6L4JJg==" crossOrigin="anonymous" referrerPolicy="no-referrer" />

    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap" rel="stylesheet" />

    <link rel="icon" href='https://images.excite.net.nz/favicon.ico' />
    <link rel="apple-touch-icon" href="https://images.excite.net.nz/favicon.ico" />

        <ScrollToTop />
        <Outlet />
        <ToastContainer />


   </>
  )
};

export default App