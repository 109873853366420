import React from 'react'
import { FaMapPin, FaPhone, FaEnvelope } from 'react-icons/fa'
import Logo from '../assets/images/excite-color-logo-png.png'

const Footer = () => {

    const currentYear = new Date().getFullYear()

  return (
    <>
    <footer className='footer bg-light pt-6'>
        <div className="footer-content pb-3">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="widget">
                            <a href="/" className="footer-logo text-dark">
                                <img src={Logo} alt="" className="footer-logo-item" />
                                excite
                            </a>
                            <p className="mt-3">
                                Experience the thrill of discovery with Excite - where every click sparks curiosity, every search reveals possibilities, and every visit brings you closer to what matters most.
                            </p>
                            <p>
                                Follow and connect through our Facebook page <a href='https://facebook.com/excitenz'>here</a> to stay updated on the latest features and news.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="widget address">
                            <ul className="list-group list-group-borderless">
                                <li className="d-flex mb-3">
                                    <i className="me-3 display-8">
                                        <FaMapPin />
                                    </i>
                                    Unit 13930<br />17B Farnham Street, Parnell<br />Auckland 1052<br />New Zealand
                                </li>
                                <li className="d-flex mb-3">
                                    <i className="me-3 display-8"
                                    >
                                        <FaPhone />
                                    </i>
                                    0800 796 519
                                </li>
                                <li className="d-flex mb-3">
                                    <i className="me-3 display-8">
                                        <FaEnvelope />
                                    </i>
                                    info@excite.net.nz
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6 text-sm-end">
                        <div className="widget">
                            <h6 className='text-dark'>Quick Links</h6>
                            <ul className="nav flex-column primary-hover">
                            <li className="nav-item">
                                    <a href="/" className="nav-link">Home</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/fibre" className="nav-link cursor-pointer">Fibre</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/wireless" className="nav-link">Wireless</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/hyperfibre" className="nav-link">HyperFibre</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/hardware" className="nav-link">Hardware</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/about" className="nav-link">About</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/contact" className="nav-link">Contact</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="divider mt-3">
                <hr />
            </div>
            <div className="footer-copyright py-1">
                <div className="container">
                    <div className="d-md-flex justify-content-between align-items-center py-3 text-center text-md-start">
                        <div className="copyright-text">
                            Excite &copy; {currentYear}
                        </div>
                        <div className="copyright-links primary-hover mt-3 mt-md-0">
                            <ul className="list-inline">
                                <li className="list-inline-item ps-2">
                                    <a href="/" className="list-group-item-action">
                                        Privacy Policy
                                    </a>
                                </li>
                                <li className="list-inline-item ps-2">
                                    <a href="/" className="list-group-item-action">
                                        Terms of Use
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    </>
  )
}

export default Footer