import React from 'react'
import Header from '../components/Header.jsx'
import Footer from '../components/Footer.jsx';
import { Link } from 'react-router-dom'
import img2 from '../assets/images/fibre-img2.png';
import img3 from '../assets/images/fibre-img3.png';
import heroImg from '../assets/images/fibre-hero-img.png';
import Meta from '../components/Meta.jsx';

const FibreScreen = () => {

  return (
    <>
    <Meta title={'Excite Fibre'} />
    <Header />
    <section className="p-0 position-relative h-500 h-md-400 h-xl-500 bg-grad pattern-overlay-1 overflow-hidden">
      <div className="container d-flex h-100 justify-content-center">
        <div className="row align-self-center w-100">
          <div className="col-sm-12 col-lg-6 mt-md-0 text-white position-relative text-center text-lg-start">
            <h2 className="display-4 fw-bold">
              Surf, stream, connect
            </h2>
            <p className="h5 mb-4 fw-normal d-sm-block">
              Experience lightning-fast internet with Excite Fibre Broadband
            </p>
          </div>
          <div className="col-sm-12 col-md-6 d-none d-lg-block p-0 position-absolute top-0 end-0 align-top align-text-top ">
            <img className='w-100' src={heroImg} alt="" />
          </div>
        </div>
      </div>
      <div className="wave wave1"></div>
      <div className="wave wave2"></div>
      <div className="wave wave3"></div>
      <div className="wave wave4"></div>
    </section> 

    <section className="pt-2 pt-lg-5 pb-2 pb-lg-5 mb-3 mb-lg-3 service-desc">
      <div className="container">
        <div className="row">
          <h1 className='text-dark fw-bold text-center mb-4 mb-lg-5'>
            Excite Fibre Broadband delivers unparalleled <span className='text-grad'>speed</span> and <span className='text-grad'>reliability</span> for all your online needs.
          </h1>
          <div className="col-lg-6 align-self-start">
            <div className="title text-start pb-lg-4 p-lg-0">
              <p className='text-muted fs-6 fw-normal text-center text-lg-start'>With Excite Fibre Broadband, buffering becomes a thing of the past, and slow loading times are banished to history. From busy households juggling multiple devices to tech-savvy professionals requiring lightning-fast speeds, Excite Fibre Broadband is your gateway to a world of unlimited digital possibilities.
              </p>
            </div>
          </div>
          <div className="d-none d-lg-block col-lg-6 mt-2 mt-md-0">
                  <div className="row">
                    <div className="col-lg-6 d-flex flex-row">
                      <i className="fa fa-circle-check text-pink h4 me-2"></i>
                      <p className='text-muted'>Blazing Fast Speeds: Enjoy download speeds up to 1 Gbps</p>
                    </div>
                    <div className="col-lg-6 d-flex flex-row">
                      <i className="fa fa-circle-check text-pink h4 me-2"></i>
                      <p className='text-muted'>Unmatched Reliability: Our state-of-the-art network ensures minimal downtime</p>
                    </div>
                    <div className="col-lg-6 d-flex flex-row mt-lg-3">
                      <i className="fa fa-circle-check text-pink h4 me-2"></i>
                      <p className='text-muted'>Future-Proof Technology: Stay ahead with our constantly upgraded infrastructure</p>
                    </div>
                    <div className="col-lg-6 d-flex flex-row mt-lg-3">
                      <i className="fa fa-circle-check text-pink h4 me-2"></i>
                      <p className='text-muted'>Flexible Plans: Choose from a range of options to suit your needs and budget</p>
                    </div>
                  </div>
          </div>
          <div className='text-center mt-2'>
          <Link to='/fibre/plans' state={{ category: "fibre" }}>
            <button className='btn btn-grad mt-lg-3'>Shop Fibre Plans</button>
          </Link>
          </div>
        </div>
      </div>
    </section>

    <section className='fibre-bg'>
      <div className="container pt-5 pb-5">
        <div className="d-block d-md-flex py-4 py-sm-5">
          <div className="align-self-center text-center text-md-start">
            <h3 className="display-5 fw-bold text-white">
              Unlimited Data, Unlimited Possibilities
            </h3>
            <p className="m-0 lead text-white fw-normal">
              All our Fibre plans come with unlimited data, no fixed term contracts, and NZ-based customer support.
            </p>
          </div>
        </div>
      </div>
    </section>
    
  <section className='pt-5 pb-1 pb-md-3'>
      <div className="container">
          <div className="row">
              <div className="col-md-12 align-self-center text-center text-lg-start mb-2 mb-md-0">
                  <h2 className="h1 text-dark">Lightning-Fast Speeds for the Modern World</h2>
                  <h6 className="mb-md-4 text-muted fw-normal mt-4 mt-md-4">Fibre broadband is revolutionizing the way we connect to the internet. With download speeds up to 1 Gbps and upload speeds reaching 500 Mbps, fibre optic technology delivers unparalleled performance for streaming, gaming, and large file transfers.</h6>
              </div>
          </div>
          <div className="row mt-3 mt-md-3">
              <div className="d-none col-md-4 col-lg-3 d-md-flex">
                  <div>
                    <img className='std-img rounded' src={img2} alt="" />
                  </div>
              </div>
              <div className="d-none col-lg-3 d-lg-flex">
                  <div>
                      <img className='std-img rounded' src={img3} alt="" />
                  </div>
              </div>
              <div className="col-md-8 col-lg-6">
                  <div className="bg-grad h-100 w-100 d-table text-white px-4 position-relative rounded">
                      <h4 className="font-weight-700 m-9 d-table-cell align-middle text-center text-md-start py-4 py-md-0">
                          <span className="d-block h1">
                          Ready to experience the Excite difference?
                          </span>
                      </h4>
                  </div>
              </div>
          </div>
      </div>
  </section>

    <section className='pt-5 pb-3'>
      <div className="container">
        <div className="row">
          <div className="col-md-8 text-center text-dark mx-auto">
            <h2 className="mb-2">Your Journey to Lightning-Fast Internet</h2>
            <p className="mb-5">Connecting to Excite Fibre Broadband is a breeze! Follow the timeline to see how simple it is to get started. Our streamlined process ensures you'll be up and running in no time.</p>
          </div>
        </div>
        <div className="row g-0">
          <div className="col-md"></div>
          <div className="col-md-1 text-center timeline-line flex-column d-none d-md-flex position-relative">
            <div className="timeline-dot"></div>
          </div>
          <div className="col-md py-2">
            <div className="feature-box f-style-3">
                <div className="row icon-grad">
                  <div className="col-3 col-md-4 col-lg-3 feature-box-icon">
                    <i class="fa-regular fa-hand-pointer"></i>
                  </div>
                  <div className="col-9 col-md-8 col-lg-9">
                    <h4 className="mb-2 feature-box-title">1. Select A Plan</h4>
                    <ul>
                      <li>Browse our range of plans</li>
                      <li>Compare speeds and features</li>
                      <li>Choose the plan that matches your needs</li>
                    </ul>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <div className="row g-0">
        <div className="col-md py-2">
            <div className="feature-box f-style-3">
                <div className="row icon-grad">
                  <div className="col-3 col-md-4 col-lg-3 feature-box-icon">
                    <i class="fa-solid fa-cart-shopping"></i>
                  </div>
                  <div className="col-9 col-md-8 col-lg-9">
                    <h4 className="mb-2 feature-box-title">2. Place Your Order</h4>
                    <ul>
                      <li>Fill in your details online or speak with a member of our team</li>
                      <li>Confirm your installation address</li>
                      <li>Choose any equipment to complement your plan</li>
                    </ul>
                  </div>
                </div>
            </div>
          </div>
          <div className="col-md-1 text-center timeline-line flex-column d-none d-md-flex position-relative">
            <div className="timeline-dot"></div>
          </div>
          <div className="col-md"></div>
        </div>
        <div className="row g-0">
          <div className="col-md"></div>
          <div className="col-md-1 text-center timeline-line flex-column d-none d-md-flex position-relative">
            <div className="timeline-dot"></div>
          </div>
          <div className="col-md py-2">
            <div className="feature-box f-style-3">
                <div className="row icon-grad">
                  <div className="col-3 col-md-4 col-lg-3 feature-box-icon">
                    <i class="fa-solid fa-square-check"></i>
                  </div>
                  <div className="col-9 col-md-8 col-lg-9">
                    <h4 className="mb-2 feature-box-title">3. Confirm & Finalise</h4>
                    <ul>
                      <li>Recieve your order confirmation via email</li>
                      <li>Our team will review your order regarding availability and installation</li>
                      <li>Confirm your date of installation and billing details</li>
                    </ul>
                  </div>
                </div>
            </div>
          </div>
        </div>
        
        <div className="row g-0">
          <div className="col-md py-2">
            <div className="feature-box f-style-3">
                <div className="row icon-grad">
                  <div className="col-3 col-md-4 col-lg-3 feature-box-icon">
                    <i class="fa-solid fa-house-signal"></i>
                  </div>
                  <div className="col-9 col-md-8 col-lg-9">
                    <h4 className="mb-2 feature-box-title">4. Plug in & Connect</h4>
                    <ul>
                      <li>We will send any equipment you have ordered before installation date</li>
                      <li>On installation day, set up your equipment with the information we provide you</li>
                      <li>Connect your devices and enjoy high-speed internet with Excite</li>
                    </ul>
                  </div>
                </div>
            </div>
          </div>
          <div className="col-md-1 text-center timeline-line flex-column d-none d-md-flex position-relative">
            <div className="timeline-dot"></div>
          </div>
          <div className="col-md"></div>
        </div>

      </div>
    </section>

    <section className="pt-5 pb-3">
      <div className="container-lg">
        <div className="my-md-3 p-2 bg-grad-basic rounded d-flex flex-column flex-sm-row d-sm-flex justify-content-between align-items-center justify-content-between pattern-overlay-4 p-5">
            <div className="align-self-center text-white text-center text-sm-start me-sm-3">
              <h3>Surf, stream, connect</h3>
              <p className="m-0">
                Connect to Excite Fibre today from $64.99 per month
              </p>
            </div>
            <div className="mt-3 mt-sm-0 text-center text-md-end ms-md-auto align-self-center">
              <Link to='/fibre/plans' state={{ category: "fibre" }}>
                <button className="btn btn-lg btn-white-outline mb-0 text-white text-decoration-none">Shop Fibre Plans</button>
              </Link>
            </div>
        </div>
      </div>
    </section>

    <div className="container">
          <hr className='mt-5' />
     </div>

    <section className='pt-5 pb-3'>
      <div className="container">
        <div className="row justify-content-lg-center">
          <div className="col-md-12">
            <h3 className="mb-4 text-dark text-center">Frequently Asked Questions</h3>
            <div className="accordion accordion-line toggle-icon-left" id="faqs-accordion">
              <div className="accordion-item">
                <h4 className="accordion-header" id="faq1">
                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="false" aria-controls="collapse1">What is fibre broadband?
                  </button>
                </h4>
                <div id="collapse1" className='accordion-collapse collapse show' aria-labelledby='faq1' data-bs-parent='#faqs-accordion'>
                  <div className="accordion-body">
                    Fibre broadband is a high-speed internet connection that uses fibre optic cables to transmit data. It offers faster speeds and more reliable connections compared to traditional copper-based broadband.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h4 className="accordion-header" id="faq2">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">What speeds can I expect with fibre broadband?
                  </button>
                </h4>
                <div id="collapse2" className='accordion-collapse collapse ' aria-labelledby='faq2' data-bs-parent='#faqs-accordion'>
                  <div className="accordion-body">
                    Fibre broadband speeds can vary depending on the package you choose. Typically, speeds range from 50 Mbps to 1 Gbps or even higher in some areas. Always check with us for the exact speeds available at your location.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h4 className="accordion-header" id="faq3">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">How long does fibre installation take?
                  </button>
                </h4>
                <div id="collapse3" className='accordion-collapse collapse ' aria-labelledby='faq3' data-bs-parent='#faqs-accordion'>
                  <div className="accordion-body">
                  The installation process usually takes 2-4 hours. However, this can vary depending on your property's setup and any additional work required. We'll provide you with a more accurate timeframe when you book your installation.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h4 className="accordion-header" id="faq4">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">Do I need a special router for fibre broadband?
                  </button>
                </h4>
                <div id="collapse4" className='accordion-collapse collapse ' aria-labelledby='faq4' data-bs-parent='#faqs-accordion'>
                  <div className="accordion-body">
                  Yes, you'll need a router that's compatible with fibre broadband. We offer fibre-compatible routers, or you can use your own if it meets our specifications (contact us for more information on our BYO modem policy).
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>

    <Footer />
    </>
  )
}

export default FibreScreen