import { useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Header from '../components/Header.jsx';
import Footer from '../components/Footer.jsx';
import { Link, useNavigate } from 'react-router-dom';
import ProfileBanner from '../components/ProfileBanner.jsx';
import Message from '../components/Message.jsx';
import Loader from '../components/Loader.jsx';
import { useProfileMutation } from '../slices/usersApiSlice.js';
import { setCredentials } from '../slices/authSlice.js';
import { useLogoutMutation } from '../slices/usersApiSlice.js';
import { logout } from '../slices/authSlice.js';
import { useGetMyOrdersQuery } from '../slices/ordersApiSlice.js';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { FaUserCircle } from "react-icons/fa";

const ProfileScreen = () => {

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [editContact, setEditContact] = useState("false");
    const [editPassword, setEditPassword] = useState("false");

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { userInfo } = useSelector((state) => state.auth);

    const [updateProfile, { isLoading:loadingUpdateProfile }] = useProfileMutation();

    const { data: orders, isLoading, error } = useGetMyOrdersQuery();

    useEffect(() => {
        if (userInfo) {
            setFirstName(userInfo.firstName);
            setLastName(userInfo.lastName);
            setEmail(userInfo.email);
            setPhone(userInfo.phone);
        }
    }, [userInfo, userInfo.firstName, userInfo.lastName, userInfo.email, userInfo.phone ]);

    const [logoutApiCall] = useLogoutMutation();

    const logoutHandler = async () => {
        try {
            await logoutApiCall().unwrap();
            dispatch(logout());
            navigate('/');
        } catch (error) {
            console.log(error);
        }
    }
    
    const editContactHandler = () => {
        setEditContact('true');
    }

    const editPasswordHandler = () => {
        setEditPassword('true');
    }

    const updateHandler = async (e) => {
        e.preventDefault();
        setEditContact('false');
        setEditPassword('false');
        if (password !== confirmPassword) {
            toast.error('Passwords do not match');
        } else {
            try {
                const res = await updateProfile({ _id:userInfo._id, firstName, lastName, email, phone, password }).unwrap();
                dispatch(setCredentials(res));
                toast.success('Profile updated successfully');
            } catch (error) {
                toast.error(error?.data?.message || error.error);
            }
        }
    }


  return (
    <>
    <Header />
    <ProfileBanner />

    <Container fluid>
        <Row className='justify-content-center my-5'>
            <Col lg="10">
            <div className="bg-transparent rounded border-primary p-4">
                <Row>
                    <Col lg="3" className='border-right'>

                        <div className="d-flex align-items-center">
                            <div className="h1 d-flex justify-content-center align-items-center flex-shrink-0 text-primary">
                                <FaUserCircle />
                            </div>
                            <div className="ps-3">
                                <h6 className="text-dark">
                                    {firstName} {lastName}
                                </h6>
                            </div>
                        </div>
                        
                        <div className="d-flex justify-content-center align-items-center mt-3">
                            <div className="nav justify-content-center flex-lg-column nav-pills me-3 w-100" id="profile-pills-tab" role='tablist' aria-orientation='vertical'>
                                <button className="nav-link active mb-2" id="profile-pills-info-tab" data-bs-toggle='pill' data-bs-target='#profile-pills-info' type='button' role='tab' aria-controls='profile-pills-info' aria-selected='true'>
                                    Personal Info
                                </button>
                                <button className="nav-link mb-2" id="profile-pills-orders-tab" data-bs-toggle='pill' data-bs-target='#profile-pills-orders' type='button' role='tab' aria-controls='profile-pills-orders' aria-selected='true'>
                                    Orders
                                </button>
                                <button className='nav-link mb-2' onClick={logoutHandler}>
                                    Logout
                                </button>
                                
                            </div>
                        </div>
                        <hr className='d-lg-none mt-0' />
                    </Col>
                    <Col lg="9">
                        
                        <div className="tab-content" id="profile-pills-tabContent">
                            <div className="tab-pane fade ps-3 show active" id="profile-pills-info" role='tabpanel' aria-labelledby='profile-pills-info-tab'>
                                <h2 className='text-dark'>Personal Info</h2>
                                <hr />
                                {editContact === 'false' && 
                                    <Row>
                                        <Col>
                                            <h6 className='text-dark mb-3'>Contact Info</h6>
                                            <p className='m-0'>{firstName} {lastName}</p>
                                            <p className='m-0'>{email}</p>
                                            <p className='m-0'>{phone}</p>
                                        </Col>
                                        <Col className='text-end pe-5'>
                                            <Link className='text-primary' onClick={editContactHandler}>Edit</Link>
                                        </Col>
                                    </Row>
                                }

                                {editContact === 'true' && 
                                        <Form onSubmit={updateHandler}>
                                            <Row>
                                                <Col>
                                                    <Form.Group controlId='firstName' className='mb-2'>
                                                        <Form.Label className='m-0'>First Name</Form.Label>
                                                        <Form.Control type='name' value={firstName} onChange={(e) => setFirstName(e.target.value)}></Form.Control>
                                                    </Form.Group>
                                                    <Form.Group controlId='lastName' className='mb-2'>
                                                        <Form.Label className='m-0'>Last Name</Form.Label>
                                                        <Form.Control type='name' value={lastName} onChange={(e) => setLastName(e.target.value)}></Form.Control>
                                                    </Form.Group>
                                                    <Form.Group controlId='email' className='mb-2'>
                                                        <Form.Label className='m-0'>Email</Form.Label>
                                                        <Form.Control type='email' value={email} onChange={(e) => setEmail(e.target.value)}></Form.Control>
                                                    </Form.Group>
                                                    <Form.Group controlId='phone' className='mb-2'>
                                                        <Form.Label className='m-0'>Phone</Form.Label>
                                                        <Form.Control type='phone' value={phone} onChange={(e) => setPhone(e.target.value)}></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Button type='submit' className='btn-sm'>Update</Button> 

                                            { loadingUpdateProfile && <Loader />}
                                        </Form>
                                }
                                
                                <hr />

                                {editPassword === 'false' &&
                                    
                                    <Row>
                                        <Col>
                                            <h6 className="text-dark mb-3">
                                                Password
                                            </h6>
                                            <p className='m-0'>************</p>
                                        </Col>
                                        <Col className='text-end pe-5'>
                                            <Link className='text-primary' onClick={editPasswordHandler}>Edit</Link>
                                        </Col>
                                    </Row>
                        
                                }

                                {editPassword === 'true' &&
                                
                                <Form onSubmit={updateHandler}>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId='password' className='mb-2'>
                                                <Form.Label className='m-0'>Password</Form.Label>
                                                <Form.Control type='password' value={password} onChange={(e) => setPassword(e.target.value)}></Form.Control>
                                            </Form.Group>
                                            <Form.Group controlId='confirmPassword' className='mb-2'>
                                                <Form.Label className='m-0'>Confirm Password</Form.Label>
                                                <Form.Control type='password' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}></Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Button type='submit' className='btn-sm'>Update</Button>

                                    { loadingUpdateProfile && <Loader />}
                                </Form>
                                
                                }

                            </div>
                            <div className="tab-pane fade ps-3" id="profile-pills-orders" role='tabpanel' aria-labelledby='profile-pills-orders-tab'>
                                <h2 className='text-dark'>Your Orders</h2>
                                <hr />

                                { isLoading ? (
                                    <Loader />
                                ) : error ? (
                                    <Message variant='danger'>
                                        { error?.data?.message || error.error }
                                    </Message>) : (
                                    
                                    <table>
                                    <thead className='border-bottom text-dark'>
                                        <th scope='col' className="fs-sm fw-normal col-4">
                                            Order #
                                        </th>
                                        <th scope='col' className="fs-sm fw-normal col-3">
                                            Date
                                        </th>
                                        <th scope='col' className="fs-sm fw-normal col-3">
                                            Status
                                        </th>
                                        <th scope='col' className="fs-sm fw-normal col-3">
                                            Total
                                        </th>
                                        <th scope='col' className="fs-sm fw-normal col-2">
                                        
                                        </th>
                                    </thead>
                                    
                                    <tbody className="align-middle fs-xs">

                                        {orders.map((order) => (
                                            <tr key={order._id}>
                                                <td className='py-2'>
                                                Order {order._id}
                                                </td>
                                                <td>
                                                {(order.createdAt.substring(0, 10))}
                                                </td>
                                                
                                                {!order.isProcessing ? (
                                                    <td>Submitted</td>
                                                ) : order.isProcessing ? (
                                                    <td>Processing</td>
                                                ) : (
                                                    <td>Completed</td>
                                                )}
                                                
                                                <td>
                                                ${order.totalPrice}
                                                </td>
                                                <td>
                                                    <Link to={`/order/${order._id}`} className='text-primary'>
                                                        Details
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}

                                    </tbody>

                                   </table>
                                )}


                            </div>
                        </div>

                    </Col>
                </Row>
            </div>
            
            </Col>
        </Row>
    </Container>
        
    <Footer />
    </>
  )
}

export default ProfileScreen