import React from 'react'

const HardwareDetailsBanner = () => {
  return (
    <>
    
    <section className="p-0 position-relative h-300 h-md-400 h-xl-500 bg-grad pattern-overlay-1 overflow-hidden pt-5">
            <div className="container d-flex h-100">
                <div className="row align-self-center w-100 text-center mt-4">

                    <div className="mt-md-0 text-white position-relative ">
                                
                        <h2 className="display-4 fw-bold py-5 pt-5 mt-5">
                        Shop Hardware
                        </h2>

                    </div>

                </div>
            </div>
        </section> 

    </>
  )
}

export default HardwareDetailsBanner