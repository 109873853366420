import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FaUserCircle } from "react-icons/fa";
import { useLogoutMutation } from '../slices/usersApiSlice';
import { logout } from '../slices/authSlice';
import { Button, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const AdminNav = () => {

    const { userInfo } = useSelector((state) => state.auth);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [logoutApiCall] = useLogoutMutation();
    const logoutHandler = async () => {
        try {
            await logoutApiCall().unwrap();
            dispatch(logout());
            navigate('/');
        } catch (error) {
            console.log(error);
        }
    };

  return (
    <Col lg="3" className='border-right'>
                            <div className="d-flex align-items-center">
                                <div className="h1 d-flex justify-content-center align-items-center flex-shrink-0 text-primary">
                                    <FaUserCircle />
                                </div>
                                <div className="ps-3">
                                    <h6 className="text-dark">
                                        {userInfo.firstName} {userInfo.lastName}
                                    </h6>
                                </div>
                            </div>

                            <div className="d-flex justify-content-center align-items-center mt-3">
                                <div className="nav flex-lg-column nav-pills mb-2 mb-lg-0 bg-white me-lg-3 w-100" aria-orientation='vertical'>
                                    <LinkContainer to='/admin'>
                                        <Button className='nav-link mb-2' type='button'>
                                            Admin Info
                                        </Button>
                                    </LinkContainer>
                                    <LinkContainer to='/admin/servicelist'>
                                        <Button className='nav-link mb-2' type='button'>
                                            Services
                                        </Button>
                                    </LinkContainer>
                                    <LinkContainer to='/admin/hardwarelist'>
                                        <Button className='nav-link mb-2' type='button'>
                                            Hardwares
                                        </Button>
                                    </LinkContainer>
                                    <LinkContainer to='/admin/orderlist'>
                                        <Button className='nav-link mb-2' type='button'>
                                            Orders
                                        </Button>
                                    </LinkContainer>
                                    <LinkContainer to='/admin/userlist'>
                                        <Button className='nav-link mb-2' type='button'>
                                            Users
                                        </Button>
                                    </LinkContainer>
                                    <button className="nav-link mb-2" type='button' onClick={logoutHandler}> 
                                        Logout
                                    </button>
                                </div>
                            </div>
                        </Col>
  )
}

export default AdminNav