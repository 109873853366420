import React from 'react'
import Header from '../components/Header'
import AboutBanner from '../components/AboutBanner';
import Footer from '../components/Footer';
import { Col, Container, Row, Button } from 'react-bootstrap'
import { FaCheck } from "react-icons/fa";
import { LinkContainer } from 'react-router-bootstrap';


const AboutScreen = () => {
  return (
    <>

    <Header />
    <AboutBanner />

    <section className="mt-5 pb-3">

        <Container className='my-3'>

        <h1 className="fw-bold text-dark text-center">
            Our clients trust us because we  <span className='italic text-grad'>deliver</span>
        </h1>
        <h4 className='text-center fw-normal text-dark'>We're committed to providing internet services that simply work - day in, day out. No excuses, no complications, just reliable broadband that keeps you connected when you need it most.</h4>

        </Container>

    </section>
    
    <Container>
    <hr className='mt-5' />
    </Container>
    


        <Container fluid className='p-0 mt-5'>
            <h1 className="display-3 fw-bold mb-5 text-center text-grad">
                        Fast, Reliable Broadband That Just Works
                    </h1>
            <Row>
                <Col className='internet-collage-bg d-none d-lg-flex align-items-center text-end'>
                   
                </Col>
                <Col className='bg-grad d-flex align-items-center pt-5 pb-3'>
                    <div className='py-5'>
                        <h2 className='text-white px-5'>Our mission is to deliver internet services that exceed expectations. We believe in:</h2>
                        <ul className='list-unstyled px-5'>
                            <li className='list-unstyled mt-4'>
                                <p className='m-0 mt-1 d-flex h5'>
                                    <span className='fw-bold text-white pe-2 mb-2'><FaCheck /></span>
                                    <span className='text-white fw-normal'>Simplicity - straightforward solutions without unnecessary complexity</span>
                                </p>
                            </li>
                            <li className='list-unstyled mt-3'>
                                <p className='m-0 mt-1 d-flex h5'>
                                    <span className='fw-bold text-white pe-2 mb-2'><FaCheck /></span>
                                    <span className='text-white fw-normal'>Reliability - consistent, stable connections you can depend on</span>
                                </p>
                            </li>
                            <li className='list-unstyled mt-3'>
                                <p className='m-0 mt-1 d-flex h5'>
                                    <span className='fw-bold text-white pe-2 mb-2'><FaCheck /></span>
                                    <span className='text-white fw-normal'>Performance - speed that meets your needs</span>
                                </p>
                            </li>
                            <li className='list-unstyled mt-3'>
                                <p className='m-0 mt-1 d-flex h5'>
                                    <span className='fw-bold text-white pe-2'><FaCheck /></span>
                                    <span className='text-white fw-normal'>Support - responsive NZ-based assistance when you need it</span>
                                </p>
                            </li>
                        </ul>
                        <div className='px-5 mt-4 mt-lg-5 d-flex flex-column align-items-start flex-lg-row'>
                            <Button className='btn btn-white-outline mb-2 mb-lg-0 me-lg-2'>Explore Fibre</Button>
                            <Button className='btn btn-white-outline'>Explore Wireless</Button>
                        </div>
                    </div>
                
                    
                </Col>
            </Row>
        </Container>
        
        <Container className='py-5 my-2'>
            <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between border-primary rounded p-4">
                <div className="mb-4"> 
                    <h1 className="text-primary fw-bold text-center text-lg-start">Looking for help?</h1>
                    <h4 className="mb-0 text-dark text-center text-lg-start">Jump over to our contact page and get in touch with one of our expert support team members</h4>
                </div>
                <div className='align-self-center align-self-lg-center'>
                    <LinkContainer to={`/contact`}>
                        <Button className='btn btn-grad'>
                            Contact Us
                        </Button>
                    </LinkContainer>
                </div>
                
            </div>
        </Container>


    <Footer />

    </>
  )
}

export default AboutScreen