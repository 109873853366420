import { HARDWARES_URL, UPLOAD_URL } from "../constants.js";
import { apiSlice } from "./apiSlice.js";

export const hardwaresApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getHardwares: builder.query({
            query: () => ({
                url: HARDWARES_URL,
            }),
            keepUnusedDataFor: 5,
        }),
        getHardwareDetails: builder.query({
            query: (hardwareId) => ({
                url: `${HARDWARES_URL}/${hardwareId}`,
            }),
            keepUnusedDataFor: 5,
        }),
        createHardware: builder.mutation({
            query: () => ({
                url: HARDWARES_URL, 
                method: 'POST',
            }),
        }),
        updateHardware: builder.mutation({
            query: (data) => ({
                url: `${HARDWARES_URL}/${data.hardwareId}`,
                method: 'PUT',
                body: data, 
            }),
        }),
        uploadHardwareImage: builder.mutation({
            query: (data) => ({
                url: UPLOAD_URL,
                method: 'POST',
                body: data,
            }),
        }),
        deleteHardware: builder.mutation({
            query: (hardwareId) => ({
                url: `${HARDWARES_URL}/${hardwareId}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const { useGetHardwaresQuery, useGetHardwareDetailsQuery, useCreateHardwareMutation, useUpdateHardwareMutation, useUploadHardwareImageMutation, useDeleteHardwareMutation } = hardwaresApiSlice;