import React from 'react'
import {} from 'react-bootstrap';
import heroImg from '../assets/images/excite-computer.png'

const MainBanner = () => {
  return (
    <>
    <section className="p-0 position-relative h-400 h-md-600 h-xl-900 bg-grad pattern-overlay-1 overflow-hidden">
      <div className="container d-flex h-100">
        <div className="row align-self-center w-100">
          <div className="col-sm-12 col-lg-6 mt-md-0 text-white position-relative text-end text-lg-start">
            <h2 className="display-4 fw-bold">
              Get broadband that just works
            </h2>
            <p className="h5 mb-4 fw-normal d-sm-block">
            Fast, reliable internet that just works! No fuss, no complications - just seamless connectivity for Kiwi homes
            </p>
          </div>
          <div className="col-sm-12 col-md-6 d-none d-lg-block p-0 position-absolute top-0 end-0 align-top align-text-top ">
            <img className='w-100' src={heroImg} alt="" />
          </div>
        </div>
      </div>
      <div className="wave wave1"></div>
      <div className="wave wave2"></div>
      <div className="wave wave3"></div>
      <div className="wave wave4"></div>
    </section> 
    </>
  )
}

export default MainBanner