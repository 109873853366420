import React from 'react'
import { useState, useEffect } from 'react'
import { Form, Button, Row, Col } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../assets/images/excite-white-logo-png.png'
import { useRegisterMutation } from '../slices/usersApiSlice.js';
import { setCredentials } from '../slices/authSlice.js'
import { toast } from 'react-toastify';
import { useSendNewUserRegistrationEmailMutation, useSendWelcomeEmailMutation } from '../slices/mailApiSlice';
import Loader from '../components/Loader';


const RegisterScreen = () => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [register, { isLoading }] = useRegisterMutation();
    const [sendWelcomeEmail, { isLoading: isSending }] = useSendWelcomeEmailMutation();
    const [sendNewUserRegistrationEmail, { isLoading: isDelivering }] = useSendNewUserRegistrationEmailMutation();

    const { userInfo } = useSelector((state) => state.auth);

    const { search } = useLocation();
    const sp = new URLSearchParams(search);
    const redirect = sp.get('redirect') || '/';

    useEffect(() => {
        if (userInfo) {
            navigate(redirect);
        }
    }, [userInfo, redirect, navigate]);

    const submitHandler = async (e) => {
        e.preventDefault();

        if (firstName.length >= 3) {
            if (lastName.length >= 2) {
                if (email.length > 5 && email.includes('@') && email.includes('.')) {
                    if (phone.length > 5) {
                        if (address.length > 8) {
                            if (password.length > 8) {

                                if (password !== confirmPassword) {
                                    toast.error('Passwords do not match');
                                    return;
                                } else {
                                    try {
                                        const res = await register({ firstName, lastName, email, phone, address, password }).unwrap();
                                        dispatch(setCredentials({...res}));
                        
                                        const data = {
                                            firstName,
                                            lastName,
                                            email,
                                            phone,
                                            address,
                                        };
                                        
                                        const sendWelcome = await sendWelcomeEmail(data);
                                        if (sendWelcome.error) {
                                            toast.error(sendWelcome.error);
                                        } else {
                                            toast.success('Registration successful');
                                        }
                        
                                        const sendNewUserDetails = await sendNewUserRegistrationEmail(data);
                                        if (sendNewUserDetails.error) {
                                            toast.error(sendNewUserDetails.error);
                                        } else {
                                            console.log('New User Registration Successful');
                                        }
                        
                                        navigate(redirect);
                                    } catch (err) {
                                        toast.error(err?.data?.message || err.error); 
                                    }
                                } 
                                
                            } else {
                                toast.error('Password needs to be at least 8 characters long.')
                            }
                        } else {
                            toast.error('Address is invalid');
                        }
                    } else {
                        toast.error('Phone number is invalid');
                    }
                } else {
                    toast.error('Email is invalid');
                }
            } else {
                toast.error('Last name is invalid');
            }
        } else {
            toast.error('First name is invalid');
        }

        
    }

  return (
    
    <div className="container-fluid p-0 m-0 w-100">
        <section className="p-0 d-flex align-items-center justify-content-center full-page w-100">
                <div className="row h-100 w-100">
                    <div className="col-12 col-md-5 col-lg-4 d-md-flex align-items-md-center bg-grad">
                        <div className="w-100 text-center text-md-start pt-5 pt-md-0 p-md-5 text-white">
                            <div className="justify-content-center align-self-center navbar-brand">
                                <img src={logo} alt="Logo" className="navbar-brand-item" />
                                excite
                            </div>
                            <h3 className="fw-light my-3">Broadband that just works - no fuss, no complications, just seamless connectivity for Kiwi homes</h3>
                            <Link className='text-white mt-4 d-none d-md-inline-block' to={`/`}>
                                Back to home
                            </Link>
                        </div>
                    </div>

                    { isSending && <Loader /> }
                    { isDelivering && <Loader /> }

                    <div className="col-12 col-md-7 col-xl-8 mx-auto my-5">
                        <div className="row h-100">
                            <div className="col-12 col-md-10 col-lg-5 text-start text-dark mx-auto d-flex align-items-center">
                                <div className="w-100">
                                    <h2>Sign Up</h2>
                                    <h5 className="fw-light">Enter your details to register with Excite Broadband</h5>
                                    <div className="mt-4">
                                        <Form onSubmit={submitHandler}>
                                            <Row className='mb-2'>
                                                <Col className='col-12 col-md-6'>
                                                    <Form.Group controlId='firstName'>
                                                        <Form.Label className='m-0'>
                                                            <p className="text-start m-0">First Name</p>
                                                        </Form.Label>
                                                        <Form.Control className='form-control' type='text' value={firstName} onChange={(e) => setFirstName(e.target.value)}></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-12 col-md-6'>
                                                    <Form.Group controlId='lastName'>
                                                        <Form.Label className='m-0'>
                                                            <p className="text-start m-0">Last Name</p>
                                                        </Form.Label>
                                                        <Form.Control className='form-control' type='text' value={lastName} onChange={(e) => setLastName(e.target.value)}></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            
                                            <Form.Group controlId='email' className='mb-2'>
                                                <Form.Label className='m-0'><p className='text-start m-0'>Email Address</p></Form.Label>
                                                <Form.Control className='form-control'
                                                    type='email'
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                ></Form.Control>
                                            </Form.Group>

                                            <Form.Group controlId='phone' className='mb-2'>
                                                <Form.Label className='m-0'><p className='text-start m-0'>Phone</p></Form.Label>
                                                <Form.Control className='form-control'
                                                    type='phone'
                                                    value={phone}
                                                    onChange={(e) => setPhone(e.target.value)}
                                                ></Form.Control>
                                            </Form.Group>

                                            <Form.Group controlId='address' className='mb-2'>
                                                <Form.Label className='m-0'><p className='text-start m-0'>Address</p></Form.Label>
                                                <Form.Control className='form-control'
                                                    type='address'
                                                    value={address}
                                                    onChange={(e) => setAddress(e.target.value)}
                                                ></Form.Control>
                                            </Form.Group>

                                            <Form.Group controlId='password' className='mb-2'>
                                                <Form.Label className='m-0'><p className='text-start m-0'>Password</p></Form.Label>
                                                    <Form.Control className='form-control'
                                                        type='password'
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                    ></Form.Control>
                                            </Form.Group>

                                            <Form.Group controlId='confirmPassword'>
                                                <Form.Label className='m-0'><p className='text-start m-0'>Confirm Password</p></Form.Label>
                                                    <Form.Control className='form-control'
                                                        type='password'
                                                        value={confirmPassword}
                                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                                    ></Form.Control>
                                            </Form.Group>

                                            <Button type='submit' className='mt-3 btn btn-grad' disabled={ isLoading }>
                                            
                                            Register
                                            </Button>

                                            { isLoading && 
                                             <p>Loading...</p>
                                            }
                                            

                                        </Form>

                                        <Row className='mt-2'>
                                            <Col>
                                                <span className="text-muted">
                                                    Already have an account?
                                                </span>
                                                <Link to={ redirect ? `/login?redirect=${redirect}` : '/login'} className='ms-1 text-primary'>Sign In</Link>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className='mt-4 text-center d-block d-md-none'>
                                        <Link className='text-muted' to={`/`}>
                                            Back to home
                                        </Link>
                                    </div>
                                    
                                </div>

                            </div>
                        </div>
                    </div>
            </div>
        </section>
    </div>
    


  )
}

export default RegisterScreen