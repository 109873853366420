import { Link } from 'react-router-dom';

const HardwareCard = ({ hardware }) => {

  return (
    <>
           <div className="card border-none">
                <div className="card-body d-flex flex-column justify-content-between">
                    <div className="row mb-4">
                        <div className="bg-image rounded text-center">
                            <img src={hardware.imagePath} alt={hardware.name} className='xs-img centerblock' />
                        </div>
                    </div>
                    <div className="row">
                        <h4 className="text-dark">{hardware.name}</h4>
                        <p>{hardware.shortDescription}</p>
                        <h3 className='text-dark mt-2'>${hardware.price}</h3>
                    </div>
                    <Link to={`/hardware/${hardware._id}`}>
                        <button className="btn btn-grad btn-sm btn-block mt-2">More Info</button>
                    </Link>
                </div>
            </div>
    </>
  )
}



export default HardwareCard