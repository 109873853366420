import React from 'react'
import { useState, useEffect } from 'react'
import { Form, Button, Row, Col } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../assets/images/excite-white-logo-png.png'
import { useLoginMutation } from '../slices/usersApiSlice.js';
import { setCredentials } from '../slices/authSlice.js'
import { toast } from 'react-toastify';
import { LinkContainer } from 'react-router-bootstrap';

const LoginScreen = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [login, { isLoading }] = useLoginMutation();

    const { userInfo } = useSelector((state) => state.auth);

    const { search } = useLocation();
    const sp = new URLSearchParams(search);
    const redirect = sp.get('redirect') || '/';

    useEffect(() => {
        if (userInfo) {
            navigate(redirect);
        }
    }, [userInfo, redirect, navigate]);

    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            const res = await login({ email, password }).unwrap();
            dispatch(setCredentials({...res}));
            navigate(redirect);
        } catch (err) {
            toast.error(err?.data?.message || err.error); 
        }
    }

  return (
    
    <div className="container-fluid p-0 m-0 w-100">
        <section className="p-0 d-flex align-items-center justify-content-center full-page w-100">
                <div className="row h-100 w-100">
                    <div className="col-12 col-md-5 col-lg-4 d-md-flex align-items-md-center bg-grad">
                        <div className="w-100 text-center text-md-start pt-5 pt-md-0 p-md-5 text-white">
                            <div className="justify-content-center align-self-center navbar-brand">
                                <img src={logo} alt="Logo" className="navbar-brand-item" />
                                excite
                            </div>
                            <h3 className="fw-light my-3">Broadband that just works - no fuss, no complications, just seamless connectivity for Kiwi homes</h3>
                            <Link className='text-white mt-4 d-none d-md-inline-block' to={`/`}>
                                Back to home
                            </Link>
                        </div>
                    </div>
                    <div className="col-12 col-md-7 col-xl-8 mx-auto my-md-5">
                        <div className="row h-100">
                            <div className="col-12 col-md-10 col-lg-5 text-center text-md-start text-dark mx-auto d-flex align-items-center">
                                <div className="w-100">
                                    <h2>Sign into your account</h2>
                                    <h5 className="fw-light">Please log in with your Excite account details</h5>
                                    <div className="mt-4">
                                        <Form onSubmit={submitHandler} className='text-start'>
                                            <Form.Group controlId='email' className='mb-2'>
                                                <Form.Label className='m-0'><p className='text-start m-0'>Email Address</p></Form.Label>
                                                <Form.Control className='form-control'
                                                    type='email'
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                ></Form.Control>
                                            </Form.Group>
                                            <Form.Group controlId='password'>
                                                <Form.Label className='m-0'><p className='text-start m-0'>Password</p></Form.Label>
                                                    <Form.Control className='form-control'
                                                        type='password'
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                    ></Form.Control>
                                            </Form.Group>
                                            
                                            <LinkContainer to={`/forgot-password`} className='cursor-pointer'>
                                            <p className='italic text-decoration-underline text-primary mt-1 mb-0 text-end'>Forgot password?</p>
                                            </LinkContainer>
                                            

                                            <Button type='submit' className='mt-1 btn btn-grad' disabled={ isLoading }>
                                            
                                            Sign In
                                            </Button>

                                            { isLoading && 
                                             <p>Loading...</p>
                                            }
                                            

                                        </Form>

                                        <Row className='mt-2 text-start'>
                                            <Col>
                                                <span className="text-muted">
                                                    New customer?
                                                </span>
                                                <Link to={ redirect ? `/register?redirect=${redirect}` : '/register'} className='ms-1 text-primary'>Register</Link>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className='mt-4 text-center d-block d-md-none'>
                            <Link className='text-muted' to={`/`}>
                                Back to home
                            </Link>
                        </div>
                    </div>
            </div>
        </section>
    </div>
    


  )
}

export default LoginScreen