import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useDeleteProductMutation, useGetProductDetailsQuery } from '../../slices/productsApiSlice';
import Header from '../../components/Header';
import AdminBanner from '../../components/AdminBanner';
import Footer from '../../components/Footer';
import { Col, Container, Row, Button } from 'react-bootstrap';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import { LinkContainer } from 'react-router-bootstrap';
import AdminNav from '../../components/AdminNav';
import { FaCheck } from "react-icons/fa";
import { toast } from 'react-toastify';

const ServiceInfoScreen = () => {

    const { id: serviceId } = useParams();

    const navigate = useNavigate();

    const { data: service, isLoading, error, refetch } = useGetProductDetailsQuery(serviceId);

    const [deleteProduct, { isLoading: loadingDelete }] = useDeleteProductMutation();

    const deleteHandler = async (id) => {
        if (window.confirm('Do you want to delete this service?')) {
            try {
                await deleteProduct(id);
                toast.success('Service was successfully deleted');
                refetch();
                navigate(`/admin/servicelist`);
            } catch (error) {
                toast.error(error?.data?.message || error.error);
            }
        };
    };

  return (
    <>

        <Header />
        <AdminBanner />

        <Container fluid>
            <Row className='justify-content-center my-5'>
                <Col lg='10'>
                    <div className="bg-transparent rounded border-primary p-4">

                        {loadingDelete && <Loader />}

                        { isLoading ? ( <Loader /> ) : error ? ( <Message variant='danger'>{error?.data?.message || error.error}</Message>) : (

                            <>
                            <Row>
                            <AdminNav />
                            <Col lg='9' className='ps-4'>

                                <Row>
                                    <Col>
                                        <h2 className="text-dark mb-0">{service.name}</h2>
                                        <p className='fs-xs italic m-0'>Last Updated: {service.updatedAt.substring(0,10)}</p>
                                    </Col>
                                    <Col className='text-end'>
                                        <LinkContainer to={`/admin/service/${service._id}/edit`}>
                                            <Button className='btn btn-primary btn-sm'>
                                                Edit
                                            </Button>
                                        </LinkContainer>
                                        <Button className='btn btn-primary btn-sm ms-1' onClick={() => deleteHandler(service._id)}>
                                            Delete
                                        </Button>
                                    </Col>
                                </Row>

                                <hr />

                                <h4 className='text-dark'>
                                    General Info
                                </h4>

                                <p className='text-capitalize m-0'>
                                    <span className='fw-bold text-dark'>Category: </span>
                                    {service.category}
                                </p>
                                <p className='text-capitalize m-0'>
                                    <span className='fw-bold text-dark'>Download speed: </span>
                                    {service.download}
                                </p>
                                <p className='text-capitalize m-0'>
                                    <span className='fw-bold text-dark'>Upload speed: </span>
                                    {service.upload}
                                </p>
                                <p className='text-capitalize m-0'>
                                    <span className='fw-bold text-dark'>Data Cap: </span>
                                    {service.dataCap}
                                </p>
                                <p className='text-capitalize m-0'>
                                    <span className='fw-bold text-dark'>Term: </span>
                                    {service.term}
                                </p>

                                <hr />

                                <h4 className='text-dark'>
                                    Price
                                </h4>

                                <h6 className='text-dark'>${service.price} per month</h6>

                                <hr />

                                <h4 className='text-dark'>
                                    More Info
                                </h4>

                                <p className='m-0'>
                                    <span className='fw-bold text-dark'>Service Description: </span>
                                    {service.description}
                                </p>

                                <p className='m-0 mt-3'>
                                    <span className='fw-bold text-dark'>Service Overview: </span>
                                    {service.productOverview}
                                </p>

                                <h6 className="text-dark mt-3">Features:</h6>

                                <li className='list-unstyled'>
                                    <p className='m-0 mt-1 d-flex'>
                                        <span className='fw-bold text-dark pe-2'><FaCheck /></span>
                                        {service.feature1}
                                    </p>
                                </li>
                                <li className='list-unstyled'>
                                    <p className='m-0 mt-1 d-flex'>
                                        <span className='fw-bold text-dark pe-2'><FaCheck /></span>
                                        {service.feature2}
                                    </p>
                                </li>
                                <li className='list-unstyled'>
                                    <p className='m-0 mt-1 d-flex'>
                                        <span className='fw-bold text-dark pe-2'><FaCheck /></span>
                                        {service.feature3}
                                    </p>
                                </li>

                                <p className='m-0 mt-3'>
                                    <span className='fw-bold text-dark'>Modem Requirements: </span>
                                    {service.modemReqs}
                                </p>

                                <hr />

                                <h4 className='text-dark'>
                                    Documentation
                                </h4>

                                <a href={require(`../../assets${service.offerSummaryPath}`)}>{service.name} Offer Summary</a>



                            </Col>
                            </Row>
                            </>

                        )}

                    </div>
                </Col>
            </Row>
        </Container>

        <Footer />
    
    </>
  )
}

export default ServiceInfoScreen