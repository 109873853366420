import React from 'react'
import { Link } from 'react-router-dom'

const ServicesMenu = () => {
  return (
    <>
    
        <section className="pt-4 pb-3">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-8 mx-auto">
                        <div className="title text-center">
                            <h2 className='text-dark'>Explore our range of services</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 mt-30">
                        <div className="feature-box f-style-2">
                            <div className="row icon-grad">
                                <div className="col-2 feature-box-icon">
                                    <i className="fa-solid fa-arrow-right"></i>
                                </div>
                                <div className="col-10">
                                    <h3 className='feature-box-title'>Fibre</h3>
                                    <p className='text-muted'>Experience blazing-fast internet with our fibre broadband service. Ideal for streaming, gaming, and large file transfers, fibre offers unparalleled speed and reliability for households and businesses alike.</p>
                                    <Link to={`/fibre`} className='text-primary'>Learn More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 mt-30">
                        <div className="feature-box f-style-2">
                            <div className="row icon-grad">
                                <div className="col-2 feature-box-icon">
                                    <i className="fa-solid fa-arrow-right"></i>
                                </div>
                                <div className="col-10">
                                    <h3 className='feature-box-title'>Wireless</h3>
                                    <p className='text-muted'>Stay connected wherever you are with our wireless broadband solution. Perfect for areas without fixed-line options or for those who need internet on the move, offering reliable speeds and the convenience of portability.</p>
                                    <Link to={`/wireless`} className='text-primary'>Learn More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 mt-30">
                        <div className="feature-box f-style-2">
                            <div className="row icon-grad">
                                <div className="col-2 feature-box-icon">
                                    <i className="fa-solid fa-arrow-right"></i>
                                </div>
                                <div className="col-10">
                                    <h3 className='feature-box-title'>HyperFibre</h3>
                                    <p className='text-muted'>Step into the future of internet with HyperFibre. Offering symmetrical speeds up to 4000Mbps, it's designed for extreme users, 8K streaming, professional e-sports, and households with multiple high-demand devices.</p>
                                    <Link to={`/hyperfibre`} className='text-primary'>
                                        Learn More
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </>
  )
}

export default ServicesMenu