import { useState } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { Button, Form, Row, Col } from 'react-bootstrap'
import logo from '../assets/images/excite-white-logo-png.png'
import { useResetPasswordMutation, useResetTokenMutation } from '../slices/usersApiSlice';
import { toast } from 'react-toastify';

const ForgotPasswordScreen = () => {

    const [resetStage, setResetStage] = useState('1')
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState();
    const [newPassword, setNewPassword] = useState();
    const [confirmNewPassword, setConfirmNewPassword] = useState();
 
    const [sendResetToken, { isLoading }] = useResetTokenMutation();
    const [resetPassword, { isLoadingReset }] = useResetPasswordMutation();

    const submitHandler = async (e) => {
        e.preventDefault();

        const userEmail = {email};
        console.log(userEmail);

        const result = await sendResetToken(userEmail);

        if (result.error) {
            toast.error(result.error);
        } else {
            console.log('Success!');
            setResetStage('2')
        };
        
    };

    const resetPasswordHandler = async (e) => {
        e.preventDefault();

        const data = {email, otp, newPassword, confirmNewPassword};
        const resetResult = await resetPassword(data);

        if (resetResult.error) {
            toast.error(resetResult.error);
        } else {
            console.log('Success');
            setResetStage('3');
        }
    }

  return (
    <div className="container-fluid p-0 m-0 w-100">
    <section className="p-0 d-flex align-items-center justify-content-center full-page w-100">
            <div className="row h-100 w-100">
                <div className="col-12 col-md-5 col-lg-4 d-md-flex align-items-center bg-grad">
                    <div className="w-100 text-center text-md-start pt-5 pt-md-0 p-3 p-md-5 text-white">
                        <LinkContainer to={'/'}>
                        <div className="justify-content-center align-self-center navbar-brand cursor-pointer">
                            <img src={logo} alt="Logo" className="navbar-brand-item" />
                            excite
                        </div>
                        </LinkContainer>
                        
                        <h3 className="fw-light my-3">Broadband that just works - no fuss, no complications, just seamless connectivity for Kiwi homes</h3>
                        <Link className='text-white mt-4 d-none d-md-inline-block' to={`/`}>
                                Back to home
                        </Link>
                    </div>
                </div>
                <div className="col-12 col-md-7 col-xl-8 mx-auto my-5">
                    <div className="row h-100">
                        <div className="col-12 col-md-10 col-lg-5 text-start text-dark mx-auto d-flex align-items-center">

                            {resetStage === '1' ? (
                                <div className="w-100">

                                    <h2>Forgot your password?</h2>
                                    <h5 className="fw-light">Enter the email address associated with this account to reset your password</h5>
                                    <div className="mt-4">
                                        <Form onSubmit={submitHandler}>
                                            <Form.Group controlId='email' className='mb-2'>
                                                <Form.Label className='m-0'><p className='text-start m-0'>Email Address</p></Form.Label>
                                                <Form.Control className='form-control' type='email' value={email} onChange={(e) => setEmail(e.target.value)} required></Form.Control>
                                            </Form.Group>
    
                                            <Button type='submit' className='mt-1 btn btn-grad'>Send Validation Code</Button>
    
                                            {isLoading}
                                        </Form>
                                    </div>
    
                                </div>
                            ) : resetStage === '2' ? (
                                <div className="w-100">

                                    <h2>Email Verification</h2>
                                    <h5 className="fw-light">We have sent a verification code to your email</h5>

                                    {isLoadingReset}

                                        <Row>
                                            <Col>
                                            <Form onSubmit={resetPasswordHandler}>
                                                <Form.Group  controlId='token' className='mb-2'>
                                                    <Form.Label className='m-0'><p className='text-start m-0'>Verification Code:</p></Form.Label>
                                                    <Form.Control className='form-control' type='code' value={otp} onChange={(e) => setOtp(e.target.value)} required></Form.Control>
                                                </Form.Group>

                                                <Form.Group controlId='newPassword'  className='mb-2'>
                                                    <Form.Label className='m-0'>
                                                        <p className="text-start m-0">New Password</p>
                                                    </Form.Label>
                                                    <Form.Control className='form-control' type='password' value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required></Form.Control>
                                                </Form.Group>

                                                <Form.Group controlId='confirmNewPassword'  className='mb-2'>
                                                    <Form.Label className='m-0'>
                                                        <p className="text-start m-0">Confirm New Password</p>
                                                    </Form.Label>
                                                    <Form.Control className='form-control' type='password' value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} required></Form.Control>
                                                </Form.Group>

                                                <Button type='submit' className='mt-1 btn btn-grad'>Reset Password</Button>

                                            </Form>
                                            </Col>
                                        </Row>
                        
                                    <Row className='my-3'>
                                        <Col>
                                        <p className='text-primary'><span className='text-muted me-1'>Didn't receive a code?</span><span className='text-decoration-underline cursor-pointer'>Resend Code</span></p>
                                        </Col>
                                    </Row>
                                </div>
                            ) : (
                                <div className='w-100'>
                                    <h2>Reset Successful</h2>
                                    <h5 className="fw-light">Your password has successfully been reset.<br/><br/> <Link to={`/login`} className='text-primary'>Click here</Link> to login to your account.</h5>
                                    <Link to={`/login`}>
                                        
                                    </Link>
                                </div>
                                
                            )}

                            
                        </div>
                    </div>
                    <div className='mt-4 text-center d-block d-md-none'>
                        <Link className='text-muted' to={`/`}>
                            Back to home
                        </Link>
                    </div>
                </div>
        </div>
    </section>
</div>

  )
}

export default ForgotPasswordScreen