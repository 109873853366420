import React from 'react'
import Header from '../components/Header.jsx'
import { Link } from 'react-router-dom'
import img1 from '../assets/images/hyperfibre-img1.png';
import img3 from '../assets/images/hyperfibre-img3.png';
import heroImg from '../assets/images/hyperfibre-hero-img.png';
import Footer from '../components/Footer.jsx';
import Meta from '../components/Meta.jsx';

const HyperfibreScreen = () => {
  return (
    <>
    <Meta title={'Excite HyperFibre'} />
    <Header />

    <section className="p-0 position-relative h-500 h-md-400 h-xl-500 bg-grad pattern-overlay-1 overflow-hidden">
      <div className="container d-flex h-100 justify-content-center">
        <div className="row align-self-center w-100">
          <div className="col-sm-12 col-lg-6 mt-md-0 text-white position-relative text-center text-lg-start">
            <h2 className="display-4 fw-bold">
              The Future of Internet Is Here
            </h2>
            <p className="h5 mb-4 fw-normal d-sm-block">
              Step into the future of connectivity. Excite HyperFibre isn't just fast - it's a revolution in home internet.
            </p>
          </div>
          <div className="col-sm-12 col-md-6 d-none d-lg-block p-0 position-absolute top-0 end-0 align-top align-text-top ">
            <img className='w-100' src={heroImg} alt="" />
          </div>
        </div>
      </div>
      <div className="wave wave1"></div>
      <div className="wave wave2"></div>
      <div className="wave wave3"></div>
      <div className="wave wave4"></div>
    </section> 

    <section className="pt-2 pt-lg-5 pb-2 pb-lg-5 mb-3 mb-lg-3 service-desc">
      <div className="container">
        <div className="row">
          <h1 className='text-dark fw-bold text-center mb-4 mb-lg-5'>
            Excite <span className='text-grad'>HyperFibre</span> Broadband is an advanced internet solution that delivers <span className='text-grad'>blazing</span> speeds and performance
          </h1>
          <div className="col-lg-4 align-self-start">
            <div className="title text-start pb-lg-4 p-lg-0">
              <p className='text-muted fs-6 fw-normal text-center text-lg-start'>HyperFibre broadband represents the pinnacle of internet connectivity, offering unparalleled speeds and reliability for the most demanding users. With symmetrical speeds of up to 4000Mbps for both downloads and uploads, HyperFibre delivers a truly transformative online experience.
              </p>
            </div>
          </div>
          <div className="d-none d-lg-block col-lg-8 mt-2 mt-md-0">
                  <div className="row">
                    <div className="col-lg-6 d-flex flex-row">
                      <i className="fa fa-circle-check text-pink h4 me-2"></i>
                      <p className='text-muted'>Blazing Fast Speeds: Enjoy download and upload speeds of up to 4000Mbps perfect for VR and 4K streaming.</p>
                    </div>
                    <div className="col-lg-6 d-flex flex-row">
                      <i className="fa fa-circle-check text-pink h4 me-2"></i>
                      <p className='text-muted'>Ultra-Low Latency: Experience near-instantaneous response times, ideal for competitive online gaming.</p>
                    </div>
                    <div className="col-lg-6 d-flex flex-row mt-lg-3">
                      <i className="fa fa-circle-check text-pink h4 me-2"></i>
                      <p className='text-muted'>Future-Proof Technology: Stay ahead of the curve with a connection that's ready for bandwidth-intensive applications.</p>
                    </div>
                    <div className="col-lg-6 d-flex flex-row mt-lg-3">
                      <i className="fa fa-circle-check text-pink h4 me-2"></i>
                      <p className='text-muted'>Symmetrical Speeds:  Upload large files as quickly as you download them, enhancing productivity.</p>
                    </div>
                  </div>
          </div>
          <div className='text-center mt-2'>
          <Link to='/hyperfibre/plans' state={{ category: "hyperfibre" }}>
            <button className='btn btn-grad mt-lg-3'>Shop HyperFibre Plans</button>
          </Link>
          </div>
        </div>
      </div>
    </section>

    <section className='hyperfibre-bg h-100'>
      <div className="container pt-5 pb-5">
        <div className="row">
          <div className='col-md-8'>
            <div className="d-flex justify-content-center py-4 py-sm-5">
              <div className="align-self-center text-center text-md-start">
                <h3 className="display-5 fw-bold text-white ">
                The Ultimate Internet Experience
                </h3>
                <p className="m-0 lead text-white fw-normal">
                Whether you're a tech enthusiast, a large household with multiple high-bandwidth users, or a professional working with data-intensive applications, HyperFibre broadband provides the ultimate solution for all your internet needs. Experience the future of connectivity today with HyperFibre.
                </p>
              </div>
            </div>
          </div>
          <div className='col-md-4'>

          </div>
        </div>
        
      </div>
    </section>

    <section className='pt-5 pb-1 pb-md-3'>
      <div className="container">
          <div className="row">
              <div className="col-md-12 align-self-center text-center text-lg-start mb-2 mb-md-0">
                  <h2 className="h1 text-dark">Next-gen Fibre Internet</h2>
                  <h6 className="mb-md-4 text-muted fw-normal mt-4 mt-md-4">HyperFibre broadband represents the cutting edge of internet technology, offering unprecedented speeds and reliability for the most demanding users. This advanced fiber-optic network delivers symmetrical speeds of up to 4000Mbps, transforming how we connect, work, and play online.</h6>
              </div>
          </div>
          <div className="row mt-3 mt-md-3">
              <div className="d-none col-md-4 col-lg-3 d-md-flex">
                  <div>
                    <img className='std-img rounded' src={img1} alt="" />
                  </div>
              </div>
              <div className="d-none col-lg-3 d-lg-flex">
                  <div>
                      <img className='std-img rounded' src={img3} alt="" />
                  </div>
              </div>
              <div className="col-md-8 col-lg-6">
                  <div className="bg-grad h-100 w-100 d-table text-white px-4 position-relative rounded">
                      <h4 className="font-weight-700 m-9 d-table-cell align-middle text-center text-md-start py-4 py-md-0">
                          <span className="d-block h1">
                          Ready to experience the Excite difference?
                          </span>
                      </h4>
                  </div>
              </div>
          </div>
      </div>
  </section>

  <section className='pt-5 pb-3'>
      <div className="container">
        <div className="row">
          <div className="col-md-8 text-center text-dark mx-auto">
            <h2 className="mb-2">Your Journey to Lightning-Fast Internet</h2>
            <p className="mb-5">Connecting to Excite Fibre Broadband is a breeze! Follow the timeline to see how simple it is to get started. Our streamlined process ensures you'll be up and running in no time.</p>
          </div>
        </div>
        <div className="row g-0">
          <div className="col-md"></div>
          <div className="col-md-1 text-center timeline-line flex-column d-none d-md-flex position-relative">
            <div className="timeline-dot"></div>
          </div>
          <div className="col-md py-2">
            <div className="feature-box f-style-3">
                <div className="row icon-grad">
                  <div className="col-3 col-md-4 col-lg-3 feature-box-icon">
                    <i class="fa-regular fa-hand-pointer"></i>
                  </div>
                  <div className="col-9 col-md-8 col-lg-9">
                    <h4 className="mb-2 feature-box-title">1. Select A Plan</h4>
                    <ul>
                      <li>Browse our range of plans</li>
                      <li>Compare speeds and features</li>
                      <li>Choose the plan that matches your needs</li>
                    </ul>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <div className="row g-0">
        <div className="col-md py-2">
            <div className="feature-box f-style-3">
                <div className="row icon-grad">
                  <div className="col-3 col-md-4 col-lg-3 feature-box-icon">
                    <i class="fa-solid fa-cart-shopping"></i>
                  </div>
                  <div className="col-9 col-md-8 col-lg-9">
                    <h4 className="mb-2 feature-box-title">2. Place Your Order</h4>
                    <ul>
                      <li>Fill in your details online or speak with a member of our team</li>
                      <li>Confirm your installation address</li>
                      <li>Choose any equipment to complement your plan</li>
                    </ul>
                  </div>
                </div>
            </div>
          </div>
          <div className="col-md-1 text-center timeline-line flex-column d-none d-md-flex position-relative">
            <div className="timeline-dot"></div>
          </div>
          <div className="col-md"></div>
        </div>
        <div className="row g-0">
          <div className="col-md"></div>
          <div className="col-md-1 text-center timeline-line flex-column d-none d-md-flex position-relative">
            <div className="timeline-dot"></div>
          </div>
          <div className="col-md py-2">
            <div className="feature-box f-style-3">
                <div className="row icon-grad">
                  <div className="col-3 col-md-4 col-lg-3 feature-box-icon">
                    <i class="fa-solid fa-square-check"></i>
                  </div>
                  <div className="col-9 col-md-8 col-lg-9">
                    <h4 className="mb-2 feature-box-title">3. Confirm & Finalise</h4>
                    <ul>
                      <li>Recieve your order confirmation via email</li>
                      <li>Our team will review your order regarding availability and installation</li>
                      <li>Confirm your date of installation and billing details</li>
                    </ul>
                  </div>
                </div>
            </div>
          </div>
        </div>
        
        <div className="row g-0">
          <div className="col-md py-2">
            <div className="feature-box f-style-3">
                <div className="row icon-grad">
                  <div className="col-3 col-md-4 col-lg-3 feature-box-icon">
                    <i class="fa-solid fa-house-signal"></i>
                  </div>
                  <div className="col-9 col-md-8 col-lg-9">
                    <h4 className="mb-2 feature-box-title">4. Plug in & Connect</h4>
                    <ul>
                      <li>We will send any equipment you have ordered before installation date</li>
                      <li>On installation day, set up your equipment with the information we provide you</li>
                      <li>Connect your devices and enjoy high-speed internet with Excite</li>
                    </ul>
                  </div>
                </div>
            </div>
          </div>
          <div className="col-md-1 text-center timeline-line flex-column d-none d-md-flex position-relative">
            <div className="timeline-dot"></div>
          </div>
          <div className="col-md"></div>
        </div>

      </div>
    </section>

    <section className="pt-5 pb-3">
      <div className="container-lg">
        <div className="my-md-3 p-2 bg-grad-basic rounded d-flex flex-column flex-sm-row d-sm-flex justify-content-between align-items-center justify-content-between pattern-overlay-4 p-5">
            <div className="align-self-center text-white text-center text-sm-start me-sm-3">
              <h3>The Future of the Internet Is Here</h3>
              <p className="m-0">
                Connect to Excite HyperFibre today from $139.99 per month
              </p>
            </div>
            <div className="mt-3 mt-sm-0 text-center text-md-end ms-md-auto align-self-center">
              <Link to='/hyperfibre/plans' state={{ category: "hyperfibre" }}>
                <button className="btn btn-lg btn-white-outline mb-0 text-white text-decoration-none">Shop HyperFibre Plans</button>
              </Link>
            </div>
        </div>
      </div>
    </section>

    <section className='pt-5 pb-3'>
      <div className="container">
        <div className="row justify-content-lg-center">
          <div className="col-md-12">
            <h3 className="mb-4 text-dark text-center">Frequently Asked Questions</h3>
            <div className="accordion accordion-line toggle-icon-left" id="faqs-accordion">
              <div className="accordion-item">
                <h4 className="accordion-header" id="faq1">
                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="false" aria-controls="collapse1">What speeds are available with HyperFibre?
                  </button>
                </h4>
                <div id="collapse1" className='accordion-collapse collapse show' aria-labelledby='faq1' data-bs-parent='#faqs-accordion'>
                  <div className="accordion-body">
                    HyperFibre offers symmetrical speeds of up to 4000Mbps for both downloads and uploads. This means you get the same blazing-fast speed whether you're downloading large files or uploading content to the cloud.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h4 className="accordion-header" id="faq2">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">Do I need special equipment for HyperFibre?
                  </button>
                </h4>
                <div id="collapse2" className='accordion-collapse collapse ' aria-labelledby='faq2' data-bs-parent='#faqs-accordion'>
                  <div className="accordion-body">
                  Yes, you'll need a compatible router that can handle these ultra-fast speeds. We provide a next-generation router for purchase designed specifically for HyperFibre connections.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h4 className="accordion-header" id="faq3">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">Is HyperFibre available in my area?
                  </button>
                </h4>
                <div id="collapse3" className='accordion-collapse collapse ' aria-labelledby='faq3' data-bs-parent='#faqs-accordion'>
                  <div className="accordion-body">
                  HyperFibre availability is expanding rapidly. Contact our team to check if your address is eligible for HyperFibre connection.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
    <Footer />
    </>
  )
}

export default HyperfibreScreen