import React, { useEffect, useState } from 'react'
import Header from '../../components/Header.jsx'
import AdminBanner from '../../components/AdminBanner.jsx'
import Footer from '../../components/Footer.jsx'
import { Col, Container, Row, Form, Button } from 'react-bootstrap';
import AdminNav from '../../components/AdminNav.jsx';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetUserDetailsQuery, useUpdateUserMutation } from '../../slices/usersApiSlice.js';
import Loader from '../../components/Loader.jsx';
import Message from '../../components/Message.jsx';
import { LinkContainer } from 'react-router-bootstrap';
import { toast } from 'react-toastify';

const UserEditScreen = () => {

    const { id: userId } = useParams();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [isAdmin, setIsAdmin] = useState('');

    const { data: user, isLoading, error, refetch } = useGetUserDetailsQuery(userId);
    const [updateUser, { isLoading: loadingUpdate }] = useUpdateUserMutation();
    
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            setFirstName(user.firstName);
            setLastName(user.lastName);
            setEmail(user.email);
            setAddress(user.address);
            setPhone(user.phone);
            setIsAdmin(user.isAdmin);
        }
    }, [user]);

    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            await updateUser({ userId, firstName, lastName, email, address, phone, isAdmin });
            toast.success('User successfully updated');
            refetch();
            navigate(`/admin/user/${userId}/info`);
        } catch (error) {
            toast.error(error.message || error.error);
        }
    };

  return (
   <>
   <Header />
   <AdminBanner />

   <Container fluid>
        <Row className='justify-content-center my-5'>
            <Col lg='10'>
                <div className="bg-transparent rounded border-primary p-4">
                    <Row>
                        <AdminNav />
                        <Col lg='9' className='ps-4'>
                            
                        {loadingUpdate && <Loader />}

                        { isLoading ? ( <Loader /> ) : error ? ( <Message variant='danger'>{error?.data?.message || error.error}</Message>) : (
                            <>
                            
                            <Row>
                                <Col>
                                    <h2 className="text-dark mb-0">{user.firstName} {user.lastName}</h2>
                                    <p className='fs-xs italic m-0'>Last Updated: {user.updatedAt.substring(0,10)}</p>
                                </Col>
                                <Col className='text-end'>
                                    <LinkContainer to={`/admin/user/${user._id}/info`}>
                                        <Button className='btn btn-primary btn-sm'>
                                            Back
                                        </Button>
                                    </LinkContainer>
                                </Col>
                            </Row>

                            <hr />

                            <Form onSubmit={submitHandler}>
                                <Row className='mt-0'>
                                    <Col className='mt-0'>
                                        <Form.Group controlId='firstName'>
                                        <Form.Label className='m-0 mt-3 text-dark ps-1'>First Name:</Form.Label>
                                        <Form.Control type='name' placeholder='Enter First Name' value={firstName} onChange={(e) => setFirstName(e.target.value)}></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col className='mt-0'>
                                        <Form.Group controlId='lastName'>
                                        <Form.Label className='m-0 mt-3 text-dark ps-1'>Last Name:</Form.Label>
                                        <Form.Control type='name' placeholder='Enter Last Name' value={lastName} onChange={(e) => setLastName(e.target.value)}></Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className='mt-0'>
                                    <Col className='mt-0'>
                                        <Form.Group controlId='email'>
                                        <Form.Label className='m-0 mt-3 text-dark ps-1'>Email:</Form.Label>
                                        <Form.Control type='email' placeholder='Enter email' value={email} onChange={(e) => setEmail(e.target.value)}></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col className='mt-0'>
                                        <Form.Group controlId='phone'>
                                        <Form.Label className='m-0 mt-3 text-dark ps-1'>Phone:</Form.Label>
                                        <Form.Control type='phone' placeholder='Enter Phone Number' value={phone} onChange={(e) => setPhone(e.target.value)}></Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className='mt-0'>
                                    <Form.Group controlId='address'>
                                        <Form.Label className='m-0 mt-3 text-dark ps-1'>Address:</Form.Label>
                                        <Form.Control type='address' placeholder='Enter Address' value={address} onChange={(e) => setAddress(e.target.value)}></Form.Control>
                                    </Form.Group>
                                </Row>
                                <Row className='mt-0'>
                                    <Form.Group controlId='isAdmin'>
                                        <Form.Label className='m-0 mt-3 text-dark ps-1'>Admin privileges?</Form.Label>
                                        <Form.Check type='checkbox' label='Yes, the user has admin privileges' className='ps-4' checked={isAdmin} onChange={(e) => setIsAdmin(e.target.checked)}></Form.Check>
                                    </Form.Group>
                                </Row>

                                <Button type='submit' className='btn btn-sm mt-3'>Update User</Button>
                            </Form>
                            
                            </>
                        )}

                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
   </Container>

   <Footer /> 

   </>
  )
}

export default UserEditScreen