import React from 'react'

const ServiceDetailsBanner = () => {
  return (
    <>

        <section className="p-0 position-relative h-200 h-md-400 h-xl-500 bg-grad pattern-overlay-1 overflow-hidden">
            <div className="container d-flex h-100">
                <div className="row align-self-center w-100 text-center mt-4">

                    <div className="mt-md-0 text-white position-relative mt-5">
                                
                        <h2 className="display-4 fw-bold mt-5">
                            Service Details
                        </h2>

                    </div>

                </div>
            </div>
        </section> 

    </>
  )
}

export default ServiceDetailsBanner