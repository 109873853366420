import React from 'react'
import { useGetOrdersQuery } from '../../slices/ordersApiSlice'
import Header from '../../components/Header';
import AdminBanner from '../../components/AdminBanner';
import { Col, Container, Row } from 'react-bootstrap';
import AdminNav from '../../components/AdminNav';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import { LinkContainer } from 'react-router-bootstrap';

const OrderListScreen = () => {

  const { data: orders, isLoading, error } = useGetOrdersQuery();

  return (
    <>

      <Header />
      <AdminBanner />

      <Container fluid>
        <Row className='justify-content-center my-5'>
          <Col lg="10">
            <div className="bg-transparent rounded border-primary p-4">
              <Row>
                <AdminNav />
                <Col lg='9' className='ps-4'>
                  <Row>
                    <h2 className="text-dark">All Orders</h2>
                    <hr />

                    { isLoading ? (
                        <Loader />
                    ) : error ? (
                        <Message variant='danger'>
                            { error?.data?.message || error.error }
                        </Message>) : (
                        
                        <table>
                        <thead className='border-bottom text-dark'>
                            <tr>
                                <th scope='col' className="fs-sm fw-normal col-4">
                                    Order #
                                </th>
                                <th scope='col' className="fs-sm fw-normal col-2">
                                    Order Date
                                </th>
                                <th scope='col' className="fs-sm fw-normal col-2">
                                    User
                                </th>
                                <th scope='col' className='fs-sm fw-normal col-2'>
                                  Updated
                                </th>
                                <th scope='col' className="fs-sm fw-normal col-3">
                                    Status
                                </th>
                                <th scope='col' className="fs-sm fw-normal col-1">
                                    Total
                                </th>
                               
                            </tr>
                        </thead>
                        
                        <tbody className="align-middle fs-xs">

                            {orders.map((order) => (

                                <tr key={order._id} className='border-bottom highlight'>
                                    <td className='py-2 ps-1'>
                                      <LinkContainer to={`/order/${order._id}`} className='cursor-pointer'>
                                        <span className="hover-underline">Order #{order._id}</span>
                                      </LinkContainer>
                                    </td>
                                    <td>
                                    {(order.createdAt.substring(0, 10))}
                                    </td>

                                    <td>
                                      <LinkContainer to={`/admin/user/${order.user._id}/info`} className='cursor-pointer'>
                                        <span className="hover-underline">
                                          {order.user.firstName} {order.user.lastName}
                                        </span>
                                      </LinkContainer>
                                    </td>

                                    <td>
                                      {order.updatedAt.substring(0,10)}
                                    </td>
                                    
                                    {!order.isProcessing ? (
                                        <td>Submitted</td>
                                    ) : order.isProcessing ? (
                                        <td>Processing</td>
                                    ) : (
                                        <td>Completed</td>
                                    )}
                                  
                                    <td className='pe-2'>
                                      ${order.totalPrice}
                                    </td>
                                </tr>
                            ))}

                        </tbody>

                        </table>
                    )}

                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>

    </>
  )
}

export default OrderListScreen