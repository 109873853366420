import React from 'react'
import Header from '../../components/Header'
import AdminBanner from '../../components/AdminBanner'
import Footer from '../../components/Footer'
import AdminNav from '../../components/AdminNav';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeleteUserMutation, useGetUserDetailsQuery } from '../../slices/usersApiSlice';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import { LinkContainer } from 'react-router-bootstrap';
import { toast } from 'react-toastify';

const UserInfoScreen = () => {

    const { id: userId } = useParams();

    const navigate = useNavigate();

    const { data: user, isLoading, error, refetch } = useGetUserDetailsQuery(userId);

    const [deleteUser, { isLoading: loadingDelete }] = useDeleteUserMutation();

    const deleteHandler = async (id) => {
        if (window.confirm('Do you want to delete this user?')) {
            try {
                await deleteUser(id);
                toast.success('User was successfully deleted');
                refetch();
                navigate(`/admin/userlist`);
            } catch (error) {
                toast.error(error?.data?.message || error.error);
            }
        };
    };

  return (
    <>
    
    <Header />
    <AdminBanner />

        <Container fluid>
            <Row className='justify-content-center my-5'>
                <Col lg='10'>
                    <div className="bg-transparent rounded border-primary p-4">

                    {loadingDelete && <Loader />}

                    { isLoading ? ( <Loader /> ) : error ? ( <Message variant='danger'>{error?.data?.message || error.error}</Message>) : (
                        <>
                        <Row>
                            <AdminNav />
                            <Col lg='9' className='ps-4'>

                                <Row>
                                    <Col>
                                        <h2 className="text-dark mb-0">{user.firstName} {user.lastName}</h2>
                                        <p className='fs-xs italic m-0'>Last Updated: {user.updatedAt.substring(0,10)}</p>
                                    </Col>
                                    <Col className='text-end'>
                                        <LinkContainer to={`/admin/user/${user._id}/edit`}>
                                            <Button className='btn btn-primary btn-sm'>
                                                Edit
                                            </Button>
                                        </LinkContainer>
                                        <Button className='btn btn-primary btn-sm ms-1' onClick={() => deleteHandler(user._id)}>
                                                Delete
                                        </Button>
                                    </Col>
                                </Row>

                                <hr />

                                <h4 className='text-dark'>
                                    User Information
                                </h4>
                                <p className='m-0'>
                                    <span className='fw-bold text-dark'>Email: </span>
                                    {user.email}
                                </p>
                                <p className='m-0'>
                                    <span className='fw-bold text-dark'>Phone: </span>
                                    {user.phone}
                                </p>
                                <p className='m-0'>
                                    <span className='fw-bold text-dark'>Address: </span>
                                    {user.address}
                                </p>
                                <p className='m-0'>
                                    <span className='fw-bold text-dark'>Admin: </span>
                                    {user.isAdmin ? (
                                        <span>Yes</span>
                                    ) : (
                                        <span>No</span>
                                    )}
                                </p>
                                <p className='m-0'>
                                    <span className='fw-bold text-dark'>Registration date: </span>
                                    {user.createdAt.substring(0,10)} 
                                </p>

                            </Col>
                        </Row>
                        </>
                    )}

                    </div>
                </Col>
            </Row>
        </Container>

      <Footer />
    
    </>
  )
}

export default UserInfoScreen

