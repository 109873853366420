import { createSlice } from "@reduxjs/toolkit";
import { updateCart } from "../utils/cartUtils.js";

const initialState = localStorage.getItem("cart")
 ? JSON.parse(localStorage.getItem("cart")) 
 : { cartServices: [], serviceAddress: {}, cartHardwares: []};

const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        addServicesToCart: (state, action) => {
            const service = action.payload;

            const existService = state.cartServices.find((x) => x._id === service._id);

            if (existService) {
                state.cartServices = state.cartServices.map((x) => x._id === existService._id ? service : x);
            } else {
                state.cartServices = [...state.cartServices, service];
            }

            return updateCart(state);
        },
        removeServicesFromCart: (state, action) => {
            state.cartServices = state.cartServices.filter((x) => x._id !== action.payload);

            return updateCart(state);
        },
        addServiceAddress: (state, action) => {
            state.serviceAddress = action.payload;
            return updateCart(state);
        },
        addHardwaresToCart: (state, action) => {
            const hardware = action.payload;

            const existHardware = state.cartHardwares.find((x) => x._id === hardware._id);

            if (existHardware) {
                state.cartHardwares = state.cartHardwares.map((x) => x._id === existHardware._id ? hardware : x);
            } else {
                state.cartHardwares = [...state.cartHardwares, hardware];
            }

            return updateCart(state);
        },
        removeHardwaresFromCart: (state, action) => {
            state.cartHardwares = state.cartHardwares.filter((x) => x._id !== action.payload);

            return updateCart(state);
        },
        clearCartItems: (state, action) => {
            state.cartServices = [];
            state.cartHardwares = [];
            state.cartItems = [];
            return updateCart(state);
        },
        clearServiceAddress: (state, action) => {
            state.serviceAddress = [];
            return updateCart(state);
        },
        resetCart: (state) => (state = initialState),
    },
});

export const { addServicesToCart, removeServicesFromCart, addServiceAddress, addHardwaresToCart, removeHardwaresFromCart, clearCartItems, clearServiceAddress, resetCart } = cartSlice.actions;

export default cartSlice.reducer;