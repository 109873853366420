import React from 'react'
import Header from '../../components/Header'
import AdminBanner from '../../components/AdminBanner'
import Footer from '../../components/Footer'
import { Col, Container, Row, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import AdminNav from '../../components/AdminNav'
import { useDeleteUserMutation, useGetUsersQuery } from '../../slices/usersApiSlice'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import { FiEdit } from 'react-icons/fi';
import { MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';
import { FaCheck } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";

const UserListScreen = () => {

  const { data: users, isLoading, error, refetch } = useGetUsersQuery();

  const [deleteUser, { isLoading: loadingDelete}] = useDeleteUserMutation();

  const deleteHandler = async (id) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await deleteUser(id);
        toast.success('User successfully deleted');
        refetch();
      } catch (error) {
        toast.error(error?.data?.message || error.error);
      }
    }
    
  }

  return (
    <>
    
    <Header />
    <AdminBanner />

    <Container fluid>
      <Row className='justify-content-center my-5'>
        <Col lg='10'>
          <div className="bg-transparent rounded border-primary p-4">
            <Row>
              <AdminNav />
              <Col lg='9' className='ps-4'>

                <Row>
                  <Col>
                    <h2 className="text-dark">All Users</h2>
                  </Col>
                </Row>

                <hr />

                {loadingDelete && <Loader />}

                { isLoading ? <Loader /> : error ? <Message variant='danger'>{error}</Message> : (
                      <table>
                          <thead className='border-bottom text-dark'>
                              <tr>
                                  <th scope='col' className='fs-sm fw-normal col-3'>
                                      Name
                                  </th>
                                  <th scope='col' className='fs-sm fw-normal col-4'>
                                      Email
                                  </th>
                                  <th scope='col' className='fs-sm fw-normal col-5'>
                                      Address
                                  </th>
                                  <th scope='col' className='fs-sm fw-normal col-1 pe-5'>
                                    Admin
                                  </th>
                                  <th scope='col' className='fs-sm fw-normal col-1 pe-1'>
                                      Edit
                                  </th>
                                  <th scope='col' className='fs-sm fw-normal col-1 pe-1'>
                                      Delete
                                  </th>
                              </tr>
                          </thead>
                          
                          <tbody className='align-middle fs-xs'>
                              {users.map((user) => (
                                  <tr key={user._id} className='border-bottom highlight'>
                                      <td className='py-2 ps-1'>
                                          <LinkContainer to={`/admin/user/${user._id}/info`} className='cursor-pointer'>
                                              <span className='hover-underline'>{user.firstName} {user.lastName}</span>
                                          </LinkContainer>    
                                      </td>
                                      <td>
                                          {user.email}
                                      </td>
                                      <td>
                                          {user.address}
                                      </td>
                                      <td className='ps-3'>
                                        {user.isAdmin ? (
                                            <FaCheck />
                                        ) : (
                                            <IoMdClose />
                                        )}
                                      </td>
                                      <td className="ps-2 text-primary">
                                          <LinkContainer to={`/admin/user/${user._id}/edit`} className='cursor-pointer'>
                                              <FiEdit />
                                          </LinkContainer>
                                      </td>
                                      <td>
                                          <Button className='btn btn-sm bg-transparent text-dark text-center ps-3' onClick={() => deleteHandler(user._id)}>
                                              <MdDelete />
                                          </Button>
                                      </td>
                                  </tr>
                              ))}
                          </tbody>
                      </table>
                  ) }

              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>

    <Footer />

    </>
  )
}

export default UserListScreen