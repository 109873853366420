export const updateCart = (state) => {
    //Calculate services price
    state.servicesPrice = state.cartServices.reduce((acc, item) => acc + item.price * item.qty, 0);

    //Calculate hardwares price
    state.hardwaresPrice = state.cartHardwares.reduce((acc, item) => acc + item.price * item.qty, 0);

    //Combine services & hardwares into checkout cart
    state.cartItems = state.cartServices.concat(state.cartHardwares);

    //Calculate items total price 
    state.unroundedItemsPrice = (state.servicesPrice - 0) + (state.hardwaresPrice - 0);
    state.itemsPrice = state.unroundedItemsPrice.toFixed(2);

    //Calcaulte shipping price (If order is over $100 then free, else $10)
    state.shippingPrice = state.itemsPrice > 175 ? 9.99 : 0;

    //Calculate total price
    state.totalPrice = (
        Number(state.itemsPrice) +
        Number(state.shippingPrice)
    ).toFixed(2);

    //Calculate tax price (15% tax)
    state.taxPrice = Number((state.totalPrice - (state.totalPrice / 1.15)).toFixed(2));

    localStorage.setItem('cart', JSON.stringify(state));

    return state
}
