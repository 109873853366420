import React from 'react'
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import Header from '../components/Header.jsx'
import Footer from '../components/Footer.jsx';
import OrderBanner from '../components/OrderBanner.jsx'
import { useGetHardwaresQuery } from '../slices/hardwaresApiSlice.js';
import AddHardwareCard from '../components/AddHardwareCard.jsx';

const AddHardwareScreen = () => {

    const navigate = useNavigate();

    const cart = useSelector((state) => state.cart);
    const { serviceAddress, cartServices, cartHardwares } = cart;

    const { data: hardwares, isLoading, error } = useGetHardwaresQuery();

    const [modem, setModem] = useState("Y")

    const onOptionChange = e => {
        setModem(e.target.value)
    }

  return (
    <>
    <Header />
    <OrderBanner />

    { isLoading ? (
        <h2>Loading...</h2>
    ) : error ? (
        <div>{ error?.data?.message || error.error }</div>
    ) : (
        
        <div className='container'>
            <div className='row justify-content-center my-5'>
                <div className="col-md-7 bg-transparent rounded border-primary p-3 me-0 me-md-2 mb-3 mb-md-0">
                    <div className="p-3">
                        <h3 className="text-dark mb-3">
                            Add-Ons
                        </h3>

                        <h5>Do you need a modem?</h5>
                        <div className="form-check">
                            <input type="radio"
                            className='form-check-input'
                            name='modem'
                            value='Y'
                            id='flexRadioDefault1'
                            checked={modem === "Y"}
                            onChange={onOptionChange} />
                            <label htmlFor="flexRadioDefault1" className='form-check-label'>
                                Yes, I need to add a modem to my order.
                            </label>
                        </div>
                        <div className="form-check">
                            <input type="radio"
                                className='form-check-input'
                                name='modem'
                                value='N'
                                id="flexRadioDefault1"
                                checked={modem === 'N'}
                                onChange={onOptionChange} />
                            <label htmlFor="flexRadioDefault1" className='form-check-label'>
                                <p>No, I already have a compatible modem. </p>
                            </label>
                        </div>

                        {modem === 'Y' &&

                            <div>

                            {cartServices.map( (service) => {
                                const hardware = hardwares.filter(hardware => hardware.category === service.category);
                                return <Col>
                                    {hardware.map ((item, i) => (
                                        <Row key={i}>
                                            <AddHardwareCard hardware={item} />
                                        </Row>
                                ))}
                                
                                </Col>


                            })}

                            </div>

                        }

                    </div>
                </div>
                <div className="col-md-4 bg-transparent rounded border-primary p-3 ms-0 ms-md-2">
                    <div className="p-3">

                        <h3 className="text-dark">Your Order</h3>
                        <p className="small mb-4">
                            Please note: This is only a request for services. A member of our team will finalise your order confirmation via phone or email.
                        </p>

                        <div className="mb-3">

                            <h5 className="text-dark">Monthly:</h5>
                                {cartServices.map ((service, i) => {
                                    return <Row key={i} className='mb-1'>
                                        <Col>{service.name}</Col>
                                        <Col className='text-end'>${service.price}</Col>
                                    </Row>
                                })}
                            <p className="mt-2">Service Address: <span className='italic'>{serviceAddress.address}, {serviceAddress.city} {serviceAddress.postalCode}<br />{serviceAddress.region}, {serviceAddress.country}</span></p>
                            <hr />
                            <h5 className="text-dark">Add-Ons</h5>
                            
                                {cartHardwares.map ((hardware, i) => {
                                    return <Row key={i}>
                                        <Col>{hardware.name} ({hardware.qty})</Col>
                                        <Col className='text-end'>${hardware.price}</Col>
                                    </Row>
                                })}
                            <hr />


                            <Link to={`/cart`}>
                                <Button className='btn btn-grad w-100'>Next</Button>
                                
                            </Link>
                            <Button className='btn btn-dark-opaque w-100 mt-2' onClick={() => navigate(-1)}>Go Back</Button>

                        </div>

                    </div>
                </div>
            </div>
        </div>

    ) }

    <Footer />
    </>
  )
}

export default AddHardwareScreen